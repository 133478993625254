import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router";
import { useDebounce } from "use-debounce/lib";
import { request } from "../api/requests";
import { useMounted } from "../hooks";

export const DataTableContext = createContext();

const DataTableProvider = ({
  children,
  apiUrl,
  hasRemoved = false,
  tableRef,
  defaultSortModel = { field: "name" },
}) => {
  // Location so we can get the search params.
  const { search } = useLocation();

  const isMounted = useMounted();

  // Create a URLSearchParams object and only create a new one if
  // the query changes.
  const params = useMemo(() => new URLSearchParams(search), [search]);

  // To check if table is loading.
  const [isLoading, setIsLoading] = useState(true);
  // To know the current page of the table.
  const [page, setPage] = useState(0);
  // The rows received from the server.
  const [rows, setRows] = useState([]);
  // The total rows received from the server.
  const [totalRows, setTotalRows] = useState(0);
  // To check if there is an error
  const [error, setError] = useState(null);
  // The pagesize to know the limit
  const [pageSize, setPageSize] = useState(100);
  //The search query
  const [query, setQuery] = useState(params.get("q") ?? "");
  // The search query with a debounced timeframe to wait before fetching on search.
  const [completeQuery] = useDebounce(query, 300);
  // Check if we can see removed entities.
  const [isRemoved, setIsRemoved] = useState(false);
  // Refetch counts up, so when it changes the component knows to refetch.
  const [refetch, setRefetch] = useState(0);
  // Sets the sorting model to use.
  const [sortModel, setSortModel] = useState([{ ...defaultSortModel }]);

  // Set the page, when the page switch buttons are clicked.
  const pageHandler = (page) => {
    setPage(page);
  };

  const sortModelHandler = useCallback((newModel) => {
    setSortModel(newModel);
  }, []);

  // Set a refresh click handler on the table.
  useEffect(() => {
    if (tableRef) {
      tableRef.current.refetch = () => {
        setRefetch(refetch + 1);
      };
    }
  }, [refetch, tableRef]);

  // Change the query based on the parameters in the search query.
  useEffect(() => {
    setQuery(params.get("q") ?? "");
    setPage(0);
  }, [params]);

  const fetch = useCallback(async () => {
    // Set loading active if component is mounted.
    if (isMounted.current) setIsLoading(true);

    let params = new URLSearchParams();
    params.append("page", page + 1);
    if (hasRemoved) params.append("removed", isRemoved);
    params.append("q", completeQuery ?? "");
    params.append("limit", pageSize);

    if (sortModel?.length > 0) {
      params.append("orderBy", sortModel[0].field);
      params.append("sort", sortModel[0].sort);
    }

    const [response, error] = await request({
      method: "GET",
      url: `${apiUrl}?${params}`,
    });

    // Set error if there is something wrong.
    if (error) {
      setError(true);
      return;
    }
    // Don't set the state if component is not mounted.
    if (!isMounted.current) return;

    // setPageSize(response?.data?.pageSize);
    setRows(response?.data?.items ?? []);
    setTotalRows(response?.data?.totalItems ?? 0);
    setIsLoading(false);
  }, [
    isMounted,
    apiUrl,
    hasRemoved,
    page,
    isRemoved,
    completeQuery,
    sortModel,
    pageSize,
  ]);

  // If apiUrl, hasRemoved, page, isRemoved or completeQuery changes
  // execute useEffect again.
  useEffect(() => {
    fetch();
  }, [fetch]);

  // State to pass to the tableContext.
  const state = {
    query,
    setQuery,
    isRemoved,
    setIsRemoved,
    setPage,
    hasRemoved,
    pageSize,
    setPageSize,
    totalRows,
    isLoading,
    error,
    pageHandler,
    rows,
    page,
    sortModel,
    sortModelHandler,
  };

  return (
    <DataTableContext.Provider value={state}>
      {children}
    </DataTableContext.Provider>
  );
};

export default DataTableProvider;
