import { createContext, useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { request } from "../api/requests";
import { patchStudent } from "../api/students";
import { deleteUser } from "../api/users";
import PageSuspense from "../components/ui/loaders/PageSuspense";
import useFetch from "../hooks/useFetch";
import useMounted from "../hooks/useMounted";

export const StudentContext = createContext();

const StudentProvider = ({ children }) => {
  /** Get the student id from the route. */
  const {
    params: { id },
  } = useRouteMatch();

  /** The api uri needed for all requests in context. */
  const apiUri = `/api/v1/students/${id}`;

  /** Use the useMounted hook to check if the component has been mounted */
  const isMounted = useMounted();

  /** Fetch the student by id. */
  const { response } = useFetch(apiUri);

  /** Initialize the state of the student. */
  const [student, setStudent] = useState(null);

  /** Global state for the global volunteer modal */
  const [isAssignVolunteerOpen, setIsAssignVolunteerOpen] = useState(false);

  /** Set the fetched student if isMounted or response changes. */
  useEffect(() => {
    if (isMounted.current) setStudent(response?.data?.student);
  }, [isMounted, response]);

  const assignVolunteer = (volunteer) => {
    setStudent({ ...student, volunteer });
  };

  const deleteHandler = async () =>
    await deleteUser(id, "Cursist verwijderd.", "/cursisten");

  const updateStudent = async (values) => {
    const errors = await patchStudent(id, values);

    if (!errors) {
      setStudent((student) => ({ ...student, ...values }));
    }
  };

  const state = {
    student,
    setStudent,
    updateStudent,
    isAssignVolunteerOpen,
    setIsAssignVolunteerOpen,
    deleteHandler,
    assignVolunteer,
  };

  return (
    <StudentContext.Provider value={state}>
      <PageSuspense isLoading={!student}>{children}</PageSuspense>
    </StudentContext.Provider>
  );
};

export default StudentProvider;
