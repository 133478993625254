import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { request } from "../../../../api/requests";
import useStudent from "../../../../hooks/useStudent";
import SectionTitle from "../../../Typography/SectionTitle";
import Modal from "../../../ui/modals/Modal";
import ProcedureForm from "./ProcedureForm";

const ProcedureCreateModal = ({ isOpen, onClose, onCreated }) => {
  const { student, setProcedure } = useStudent();
  const { push } = useHistory();

  const submitHandler = async (values, { setErrors }) => {
    const [response, error] = await request({
      method: "POST",
      url: `/api/v1/students/${student.id}/procedure`,
      data: values,
    });

    if (error) {
      setErrors(error?.response?.data?.errors);
    } else {
      toast.success("Traject aangemaakt.");
      push(`/cursisten/${student.id}/progressie/${response.data.procedure.id}`);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <SectionTitle>Traject starten</SectionTitle>

      <ProcedureForm onSubmit={submitHandler} onCancel={onClose} />
    </Modal>
  );
};

export default ProcedureCreateModal;
