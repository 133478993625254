import { createContext, useCallback, useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { request } from "../api/requests";
import useMounted from "../hooks/useMounted";

export const LogContext = createContext();

const LogsProvider = ({ children }) => {
  /** Retrieve the id of the user to fetch logs for. */
  const {
    params: { id },
  } = useRouteMatch();

  /** Initialize the logs state. */
  const [logs, setLogs] = useState(null);

  /**
   * Use the useMounted hook to check if component is mounted
   * before setting state in methods.
   */
  const isMounted = useMounted();

  /** Fetch the logs of the user found in the url. */
  const fetchLogs = useCallback(async () => {
    const [response, error] = await request({
      method: "GET",
      url: `/api/v1/users/${id}/logs`,
    });

    if (!error && isMounted.current) setLogs(response.data.logs);
  }, [id, isMounted, setLogs]);

  /** Fetch logs at mount and if the fetchLogs method changes. */
  useEffect(() => {
    fetchLogs();
  }, [fetchLogs]);

  /** Construct the state to pass to the context. */
  const state = {
    logs,
  };

  return <LogContext.Provider value={state}>{children}</LogContext.Provider>;
};

export default LogsProvider;
