import { Grid } from "@material-ui/core";
import { useMemo } from "react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import JwtService from "../../../application/jwtService";
import { getToken } from "../../../store/user/selectors";
import ActionCard from "../../ui/cards/ActionCard";

const actions = [
  {
    title: "Medewerkers",
    description:
      "Bekijk en beheer alle medewerkers.",
    url: "/medewerkers",
    roles: ["EmployeeAdmin"],
  },
  {
    title: "Vrijwilligers",
    description: "Bekijk en beheer alle taal vrijwilligers.",
    url: "/vrijwilligers",
    roles: ["Employee", "EmployeeAdmin"],
  },
  {
    title: "Taalvragers (Cursisten)",
    description: "Beheer en begeleid cursisten.",
    url: "/cursisten",
    roles: ["Employee", "EmployeeAdmin"],
  },
  {
    title: "Berichtgeving",
    description:
      "Beheer alle berichtgevingen die gebruikers te zien krijgen in het systeem.",
    url: "/updates",
    roles: ["Admin"],
  },
  {
    title: "AVG Beheer",
    description: "Verwijder persoongegevens van gebruikers.",
    url: "/gebruikers",
    roles: ["Admin", "EmployeeAdmin"],
  },
  {
    title: "Mijn profiel",
    description: "Pas uw profiel aan of wijzig uw wachtwoord snel en eenvoudig.",
    url: "/account",
  },
];

const DashboardActions = () => {
  const token = useSelector((state) => getToken(state));

  const jwtService = useMemo(() => new JwtService(token), [token]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {actions.map(({ title, description, url, roles }, index) => (
            <Fragment key={index}>
              {jwtService.isInRoles(roles) && (
                <Grid item xs={12} md={4} lg={2}>
                  <ActionCard
                    title={title}
                    description={description}
                    url={url}
                  />
                </Grid>
              )}
            </Fragment>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DashboardActions;
