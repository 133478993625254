import { Box, Button } from "@material-ui/core";
import SectionTitle from "../../../../components/Typography/SectionTitle";
import { Link, useRouteMatch } from "react-router-dom";
import DataGrid from "../../../../components/datagrid";

const StudentReportsPage = () => {
  const {
    url,
    params: { id: studentId },
  } = useRouteMatch();

  const columns = [
    { field: "title", headerName: "Titel", flex: 1 },
    { field: "created_at", headerName: "Aangemaakt op", flex: 1 },
    {
      field: "view",
      headerName: " ",
      renderCell: ({ row }) => (
        <Button component={Link} to={`${url}/${row.id}`} variant="outlined">
          Bekijken
        </Button>
      ),
    },
  ];

  return (
    <Box>
      <SectionTitle>Verslagen</SectionTitle>

      <DataGrid
        columns={columns}
        apiUrl={`/api/v1/students/${studentId}/reports`}
      />
    </Box>
  );
};

export default StudentReportsPage;
