import { makeStyles, Modal as MUIModal, Fade, Box } from "@material-ui/core";
import clsx from "clsx";
import Card from "../cards/Card";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Modal = ({ isOpen = true, onClose, children, className = "" }) => {
  const classes = useStyles();

  return (
    <MUIModal
      open={isOpen}
      onClose={onClose}
      className={`${clsx(classes.modal)} ${className}`}
      disableAutoFocus
      disableEnforceFocus
      closeAfterTransition
    >
      <Fade in={isOpen} timeout={200}>
        <Box
          maxWidth="600px"
          width="100%"
          maxHeight="800px"
          style={{ overflowY: "auto", overflowX: "hidden" }}
        >
          <Card style={{ height: "unset" }}>{children}</Card>
        </Box>
      </Fade>
    </MUIModal>
  );
};

export default Modal;
