import { toast } from "react-toastify";
import { history } from "../../router";
import { request } from "../requests";

export const deleteUser = async (
  id,
  message = "Persoon verwijderd.",
  redirect = "/"
) => {
  const [, error] = await request({
    method: "DELETE",
    url: `/api/v1/users/${id}`,
  });

  if (!error) {
    toast.success(message);
    history.push(redirect);
  }
};
