import { Grid, Button } from "@material-ui/core";
import { useFormik } from "formik";
import { Fragment } from "react";
import { useMemo } from "react";
import FormField from "../../forms/FormField";
import FormSectionTitle from "../../Typography/FormSectionTitle";
import SpaceBetweenBox from "../../ui/SpaceBetweenBox";
import LoadingButton from "../../ui/buttons/LoadingButton";
import * as yup from "yup";
import { MAX_ADDRESS_LENGTH, MAX_LENGTH } from "../../../application/constants";
import {
  emailValidationMessage,
  maxLengthValidationMessage,
  notRequiredValidationMessage,
  requiredValidationMessage,
  stringValidationMessage,
} from "../../../application/yup/validation";

const OrganisationForm = ({ organisation = null, onSubmit, onCancel }) => {
  const initialValues = useMemo(() => {
    if (!organisation)
      return {
        name: "",
        address: "",
        employee: {
          email: "",
          phoneNumber: "",
          name: "",
        },
      };
    else
      return {
        name: organisation.name,
        address: organisation.address,
      };
  }, [organisation]);

  const validationSchema = useMemo(() => {
    const organisationValidation = {
      name: yup
        .string(stringValidationMessage)
        .max(MAX_LENGTH, maxLengthValidationMessage)
        .required(requiredValidationMessage),
      address: yup
        .string(stringValidationMessage)
        .max(MAX_ADDRESS_LENGTH, maxLengthValidationMessage)
        .required(requiredValidationMessage),
    };

    if (organisation) {
      return yup.object().shape(organisationValidation);
    } else {
      return yup.object().shape({
        ...organisationValidation,
        employee: yup.object().shape({
          name: yup
            .string(stringValidationMessage)
            .max(MAX_LENGTH, maxLengthValidationMessage)
            .required(requiredValidationMessage),
          email: yup
            .string(stringValidationMessage)
            .email(emailValidationMessage)
            .max(MAX_LENGTH, maxLengthValidationMessage)
            .required(requiredValidationMessage),
          phoneNumber: yup
            .string(stringValidationMessage)
            .max(MAX_LENGTH, maxLengthValidationMessage)
            .notRequired(notRequiredValidationMessage),
        }),
      });
    }
  }, [organisation]);

  const {
    values,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormField
            label="Bedrijfsnaam"
            value={values.name}
            name="name"
            onInput={handleChange}
            required
            isDisabled={isSubmitting}
            error={errors.name}
          />
        </Grid>

        <Grid item xs={12}>
          <FormField
            label="Adres"
            value={values.address}
            name="address"
            onInput={handleChange}
            required
            isDisabled={isSubmitting}
            error={errors.address}
            multiline
          />
        </Grid>
      </Grid>

      {!organisation && (
        <Fragment>
          <br></br>
          <FormSectionTitle>Administrator account</FormSectionTitle>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField
                label="Volledige naam"
                name="employee.name"
                value={values.employee.name}
                error={errors?.employee?.name}
                onInput={handleChange}
                isDisabled={isSubmitting}
                required={true}
              />
            </Grid>

            <Grid item xs={12}>
              <FormField
                label="E-mail adres"
                name="employee.email"
                value={values.employee.email}
                error={errors?.employee?.email}
                onInput={handleChange}
                isDisabled={isSubmitting}
                required={true}
              />
            </Grid>

            <Grid item xs={12}>
              <FormField
                label="Telefoonnummer"
                name="employee.phoneNumber"
                value={values.employee.phoneNumber}
                error={errors?.employee?.phoneNumber}
                onInput={handleChange}
                isDisabled={isSubmitting}
              />
            </Grid>
          </Grid>
        </Fragment>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SpaceBetweenBox>
            <Button disabled={isSubmitting} onClick={onCancel}>
              Annuleren
            </Button>

            {!organisation ? (
              <div>
                <LoadingButton
                  type="submit"
                  onClick={() => {
                    setFieldValue("redirectToEntity", false);
                  }}
                  disabled={isSubmitting}
                >
                  Opslaan + venster sluiten
                </LoadingButton>

                <Button
                  type="submit"
                  onClick={() => {
                    setFieldValue("redirectToEntity", true);
                  }}
                  disabled={isSubmitting}
                >
                  Opslaan + klant openen
                </Button>
              </div>
            ) : (
              <LoadingButton type="submit" disabled={isSubmitting}>
                Opslaan
              </LoadingButton>
            )}
          </SpaceBetweenBox>
        </Grid>
      </Grid>
    </form>
  );
};

export default OrganisationForm;
