import { useEffect, useRef } from "react";

// UseMounted hook to check if a component is mounted
// before changing state.
const useMounted = () => {
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return mounted;
};

export default useMounted;
