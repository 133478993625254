import { Button, Typography } from "@material-ui/core";
import moment from "moment";
import useProcedure from "../../../../hooks/useProcedure";
import SpaceBetweenBox from "../../../ui/SpaceBetweenBox";

const ProcedureHeader = () => {
  const { procedure, setIsAppointmentsOpen } = useProcedure();

  return (
    <SpaceBetweenBox>
      <div>
        <Typography component="h2" variant="h5">
          {procedure?.title}
        </Typography>
        <Typography component="h3" variant="subtitle1">
          Type: {procedure?.level || "Niet opgegeven."}
        </Typography>
        <Typography component="h3" variant="subtitle1">
          Aangemaakt op: {moment(procedure?.createdAt).format("DD-MM-yyyy")}
        </Typography>
        <Typography component="h3" variant="subtitle1">
          Gestart op:{" "}
          {procedure?.startedAt
            ? moment(procedure?.startedAt).format("DD-MM-yyyy")
            : "Nog niet gestart"}
        </Typography>
        {procedure?.endedAt && (
          <Typography component="h3" variant="subtitle1" gutterBottom>
            Beëindigd op: {moment(procedure?.endedAt).format("DD-MM-yyyy")}
          </Typography>
        )}
        <br />
        <Typography component="p" variant="body1">
          {procedure?.description}
        </Typography>
      </div>

      <div>
        <Button onClick={() => setIsAppointmentsOpen(true)}>Afspraken</Button>
      </div>
    </SpaceBetweenBox>
  );
};

export default ProcedureHeader;
