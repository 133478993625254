import Home from "../pages";
import Account from "../pages/account";
import Employees from "../pages/employees";
import Organisations from "../pages/organisations";
import ShowOrganisation from "../pages/organisations/show";
import Students from "../pages/students";
import StudentFilesPage from "../pages/students/show/files";
import ShowStudent from "../pages/students/show/index.jsx";
import StudentNotesPage from "../pages/students/show/notes";
import StudentPersonalInfoPage from "../pages/students/show/personal-info";
import StudentProgressPage from "../pages/students/show/progress";
import StudentReportsPage from "../pages/students/show/reports";
import StudentReportPage from "../pages/students/show/reports/show";
import UpdatesPage from "../pages/updates";
import UsersPage from "../pages/users";
import ShowUserPage from "../pages/users/show";
import Volunteers from "../pages/volunteers";
import ShowVolunteer from "../pages/volunteers/show/index.jsx";
import NotesPage from "../pages/volunteers/show/notes";
import VolunteerFilesPage from "../pages/volunteers/show/files";
import PersonalInfoPage from "../pages/volunteers/show/personal-info";
import ShowUpdatePage from "../pages/updates/show";
import EmployeePersonalInfoPage from "../pages/employees/show/personal-info";
import EmployeeFilesPage from "../pages/employees/show/files";
import ShowEmployeePage from "../pages/employees/show";
import StudentSingleProgressPage from "../pages/students/show/progress/show";

const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/account",
    component: Account,
  },
  {
    path: "/organisaties",
    component: Organisations,
    roles: ["Admin"],
  },
  {
    path: "/organisaties/:id",
    component: ShowOrganisation,
    roles: ["Admin"],
  },
  {
    path: "/updates",
    component: UpdatesPage,
    roles: ["Admin"],
  },
  {
    path: "/updates/:id",
    component: ShowUpdatePage,
    roles: ["Admin"],
  },
  {
    path: "/gebruikers",
    component: UsersPage,
    roles: ["Admin", "EmployeeAdmin"],
  },
  {
    path: "/gebruikers/:id",
    component: ShowUserPage,
    roles: ["Admin", "EmployeeAdmin"],
  },
  {
    path: "/medewerkers",
    component: Employees,
    roles: ["EmployeeAdmin"],
  },
  {
    path: "/medewerkers/:id",
    component: ShowEmployeePage,
    roles: ["EmployeeAdmin"],
    children: [
      {
        path: "bestanden",
        component: EmployeeFilesPage,
      },
      {
        path: "",
        component: EmployeePersonalInfoPage,
      },
    ],
  },
  {
    path: "/vrijwilligers",
    component: Volunteers,
    roles: ["EmployeeAdmin", "Employee"],
  },
  {
    path: "/vrijwilligers/:id",
    component: ShowVolunteer,
    roles: ["EmployeeAdmin", "Employee"],
    children: [
      {
        path: "notities",
        component: NotesPage,
      },
      {
        path: "bestanden",
        component: VolunteerFilesPage,
      },
      {
        path: "",
        component: PersonalInfoPage,
      },
    ],
  },
  {
    path: "/cursisten",
    component: Students,
    roles: ["EmployeeAdmin", "Employee"],
  },
  {
    path: "/cursisten/:id",
    component: ShowStudent,
    roles: ["EmployeeAdmin", "Employee"],

    children: [
      {
        path: "bestanden",
        component: StudentFilesPage,
      },
      {
        path: "verslagen/:reportId",
        component: StudentReportPage,
      },
      {
        path: "verslagen",
        component: StudentReportsPage,
      },
      {
        path: "notities",
        component: StudentNotesPage,
      },
      {
        path: "progressie",
        component: StudentProgressPage,
      },
      {
        path: "progressie/:progressionId",
        component: StudentSingleProgressPage,
      },
      {
        path: "",
        component: StudentPersonalInfoPage,
      },
    ],
  },
];

export default routes;
