import { Tab } from "@material-ui/core";
import { useState } from "react";
import { TabContext, TabPanel, TabList } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";
import AccountInfoTab from "../../components/pages/account/AccountInfoTab";
import PasswordTab from "../../components/pages/account/PasswordTab";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  panel: {
    padding: "28px 0",
  },
}));

const Account = () => {
  const classes = useStyles();

  const [currentTab, setCurrentTab] = useState("0");

  const tabHandler = (_, newValue) => setCurrentTab(newValue);

  const tabs = [
    {
      label: "Persoonlijke gegevens",
      component: <AccountInfoTab />,
    },
    {
      label: "Wachtwoord",
      component: <PasswordTab />,
    },
  ];

  return (
    <TabContext value={currentTab}>
      <TabList
        value={currentTab}
        onChange={tabHandler}
        aria-label="Account tabs"
        indicatorColor="primary"
      >
        {tabs.map(({ label }, index) => (
          <Tab label={label} value={`${index}`} key={index} />
        ))}
      </TabList>

      {tabs.map(({ component }, index) => (
        <TabPanel
          value={`${index}`}
          key={index}
          className={clsx(classes.panel)}
        >
          {component}
        </TabPanel>
      ))}
    </TabContext>
  );
};

export default Account;
