import { Button, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";
import { procedureCommands } from "../../../../api/students/procedures";
import COMMAND_TYPES from "../../../../application/commandTypes";
import ConfirmationModal from "../../../ui/modals/ConfirmationModal";
import ProcedureTimelineItem from "./ProcedureTimelineItem";
import _ from "lodash";
import APPOINTMENT_TYPES from "../../../../application/appointmentTypes";
import { Fragment } from "react";
import AppointmentCreateModal from "./appointments/AppointmentCreateModal";
import useProcedure from "../../../../hooks/useProcedure";

const ProcedureStepFive = () => {
  const { procedure, setProcedure } = useProcedure();

  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [evaluations, setEvaluations] = useState([]);

  useEffect(() => {
    setEvaluations(
      _.filter(procedure.appointments, ["type", APPOINTMENT_TYPES.EVALUATION])
    );
  }, [procedure.appointments]);

  const confirmationHandler = async () => {
    const updated = await procedureCommands(procedure.id, {
      command: COMMAND_TYPES.EVALUATION_DONE,
    });

    if (updated) {
      setProcedure({ ...procedure, hadEvaluation: true });

      confirmationModalHandler();
    }
  };

  const isOpenHandler = () => setIsOpen(!isOpen);

  const confirmationModalHandler = () =>
    setIsConfirmationOpen(!isConfirmationOpen);

  return (
    <ProcedureTimelineItem completed={procedure.hadEvaluation}>
      <Typography variant="h6">Evaluatie inplannen</Typography>

      <Typography>Plan een evaluatie gesprek in.</Typography>

      {procedure.hadData && !procedure.hadEvaluation && (
        <div>
          <Button onClick={isOpenHandler}>Inplannen</Button>

          {evaluations.length > 0 && (
            <Fragment>
              <Button onClick={confirmationModalHandler}>
                Evaluaties afronden
              </Button>

              <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={confirmationModalHandler}
                onConfirm={confirmationHandler}
              />
            </Fragment>
          )}

          <AppointmentCreateModal
            isOpen={isOpen}
            onClose={isOpenHandler}
            type={APPOINTMENT_TYPES.EVALUATION}
          />
        </div>
      )}
    </ProcedureTimelineItem>
  );
};

export default ProcedureStepFive;
