import { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  Paper,
  makeStyles,
  Input,
} from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getToken } from "../../store/user/selectors";
import JwtService from "../../application/jwtService";
import { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 0,
  },
  input: {
    color: "#fff !important",
    "&::before": {
      borderBottom: "1px solid rgba(255, 255, 255, 0.7)",
    },
    "&:hover::before": {
      borderBottom: "2px solid #fff !important",
    },
  },
}));

// TODO:: API Request to search for volunteer or student
const SearchField = () => {
  const location = useLocation();

  const [isHidden, setIsHidden] = useState(true);
  const [query, setQuery] = useState("");
  const searchRef = useRef(0);
  const classes = useStyles();
  let minLength = 3;

  const token = useSelector((state) => getToken(state));

  const jwtService = useMemo(() => new JwtService(token), [token]);

  /* Handle the input, set query and fetch data */
  const inputHandler = async (e) => {
    let value = e.target.value;

    setQuery(e.target.value);

    setIsHidden(value.length < minLength);
  };

  /* Open dropdown if focus on input and there is enough text */
  const focusHandler = () => {
    setIsHidden(query.length < minLength);
  };

  /* Close the search dropdown if clicked outside of searchbar or dropdown */
  const clickOutsideHandler = (e) => {
    let { target } = e;
    let { current } = searchRef;

    if (!current?.contains(target)) setIsHidden(true);
  };

  /* Add script for click outside on mounted en remove on unmount */
  useEffect(() => {
    window.addEventListener("click", clickOutsideHandler);

    return () => {
      window.removeEventListener("click", clickOutsideHandler);
    };
  }, []);

  /* 
    Empty the search query after search. Because it will already be filled in
    on the page where the search happens.
  */
  useEffect(() => {
    setQuery("");
    setIsHidden(true);
  }, [location]);

  /* Shortend a very long search query */
  const shortenedQuery =
    query.length > 15 ? query.substr(0, 15).concat("...") : query;

  return (
    <Box position="relative" ref={searchRef}>
      <Box>
        <Input
          placeholder="Zoeken...."
          inputProps={{
            "aria-label": "Zoeken",
          }}
          value={query}
          onChange={inputHandler}
          onFocus={focusHandler}
          endAdornment={<SearchIcon />}
          color="primary"
          className={clsx(classes.input)}
        />
      </Box>

      <Box position="absolute" width="360px" top="48px" hidden={isHidden}>
        <Paper className={clsx(classes.card)}>
          <List>
            <ListItem>
              <Typography component="h6" variant="h6" gutterBottom>
                Zoeken naar "{shortenedQuery}"
              </Typography>
            </ListItem>
            <Divider />
            {jwtService.isInRoles(["Admin", "EmployeeAdmin"]) && (
              <ListItem button component={Link} to={`/gebruikers?q=${query}`}>
                Zoeken naar "{shortenedQuery}" in alle gebruikers
              </ListItem>
            )}
            {jwtService.isInRoles(["EmployeeAdmin"]) && (
              <ListItem button component={Link} to={`/medewerkers?q=${query}`}>
                Zoeken naar "{shortenedQuery}" in alle medewerkers
              </ListItem>
            )}
            {jwtService.isInRoles(["Employee", "EmployeeAdmin"]) && (
              <Fragment>
                <ListItem button component={Link} to={`/cursisten?q=${query}`}>
                  Zoeken naar "{shortenedQuery}" in alle taalvragers (cursisten)
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to={`/vrijwilligers?q=${query}`}
                >
                  Zoeken naar "{shortenedQuery}" in alle vrijwilligers
                </ListItem>
              </Fragment>
            )}
          </List>
        </Paper>
      </Box>
    </Box>
  );
};

export default SearchField;
