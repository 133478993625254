import Modal from "./Modal";
import SpaceBetweenBox from "../SpaceBetweenBox";
import { Button, Typography } from "@material-ui/core";
import DangerButton from "../buttons/DangerButton";
import SectionTitle from "../../Typography/SectionTitle";
import { cloneElement } from "react";

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  message = null,
  confirmButton,
  cancelButton,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <SectionTitle>Weet u het zeker?</SectionTitle>
      <Typography gutterBottom>
        {message ?? "Wilt u zeker doorgaan?"}
      </Typography>
      <SpaceBetweenBox>
        {cancelButton ? (
          cloneElement(cancelButton, { onClick: onClose })
        ) : (
          <Button onClick={onClose}>Annuleren</Button>
        )}

        {confirmButton ? (
          cloneElement(confirmButton, { onClick: onConfirm })
        ) : (
          <DangerButton onClick={onConfirm}>Doorgaan</DangerButton>
        )}
      </SpaceBetweenBox>
    </Modal>
  );
};

export default ConfirmationModal;
