import { Typography, Box, Grid, IconButton, Tooltip } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import PageSuspense from "../../../../components/ui/loaders/PageSuspense";
import AttachmentCard from "../../../../components/ui/cards/AttachmentCard";
import { request } from "../../../../api/requests";
import { toast } from "react-toastify";
import _ from "lodash";
import {
  CloudUpload as CloudUploadIcon,
  Edit as EditIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import StudentReportAddAttachmentModal from "../../../../components/pages/students/StudentReportAddAttachmentsModal";
import ReportDescriptionCard from "../../../../components/pages/students/reports/ReportDescriptionCard";
import { deleteReport, getReport } from "../../../../api/students/reports";
import useMounted from "../../../../hooks/useMounted";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import DeletionModal from "../../../../components/ui/modals/DeletionModal";
import ReportEditModal from "../../../../components/pages/students/reports/ReportEditModal";
import FileDownload from "js-file-download";
import { useStudent } from "../../../../hooks";

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1.5rem",
  },
  headerButtons: {
    display: "flex",
    gap: ".25rem",
  },
}));

const StudentReportPage = () => {
  const {
    params: { id: studentId, reportId },
  } = useRouteMatch();

  const isMounted = useMounted();

  const { student } = useStudent();

  const classes = useStyles();

  const [report, setReport] = useState(null);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isUploadAttachmentOpen, setIsUploadAttachmentOpen] = useState(false);

  const fetch = useCallback(async () => {
    setReport(null);

    const report = await getReport(studentId, reportId);

    if (report && isMounted.current) setReport(report);
  }, [studentId, reportId, isMounted]);

  const removeAttachment = async (attachment) => {
    const [, error] = await request({
      method: "DELETE",
      url: `/api/v1/students/${studentId}/reports/${reportId}/attachments/${attachment.id}`,
    });

    if (!error) {
      toast.success(
        `${attachment.originalName ?? attachment.filename} verwijderd`
      );

      const attachments = [
        ..._.filter(report.attachments, (item) => item.id !== attachment.id),
      ];

      setReport({ ...report, attachments });
    }
  };

  const downloadHandler = async (attachment) => {
    const [response, error] = await request({
      method: "GET",
      url: `/api/v1/students/${studentId}/reports/${reportId}/attachments/${attachment.id}`,
      responseType: "blob",
    });

    if (!error) {
      // Get the filename by splitting the content-disposition header.
      let filename =
        response.headers["content-disposition"]
          ?.split("filename=")[1]
          ?.split(";")[0] ?? "file.data";

      FileDownload(response?.data, filename, "application/octet-stream");
    }
  };

  const addAttachments = (attachments) => {
    setReport({
      ...report,
      attachments: [...report.attachments, ...attachments],
    });
  };

  const deleteHandler = async () => await deleteReport(studentId, reportId);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <PageSuspense isLoading={!report}>
      <header className={clsx(classes.header)}>
        <div>
          <Typography variant="h5" component="h2">
            {report?.title}
          </Typography>

          <Typography variant="subtitle2" component="h3">
            Verslag geschreven op: {report?.createdAt}
          </Typography>
        </div>

        <div className={clsx(classes.headerButtons)}>
          <IconButton onClick={() => setIsEditOpen(true)}>
            <EditIcon />
          </IconButton>

          <IconButton onClick={() => setIsDeleteOpen(true)}>
            <CloseIcon />
          </IconButton>
        </div>
      </header>

      <ReportDescriptionCard description={report?.description} />

      <Box component="section" marginTop="1rem">
        <Box
          display="flex"
          gridColumnGap=".5rem"
          alignItems="center"
          marginBottom=".5rem"
        >
          <Typography>Bijlages</Typography>
          <Tooltip title="Bijlages toevoegen." placement="top" arrow>
            <IconButton
              size="small"
              onClick={() => setIsUploadAttachmentOpen(true)}
              aria-label="Bijlages toevoegen."
            >
              <CloudUploadIcon />
            </IconButton>
          </Tooltip>
        </Box>

        {report?.attachments?.length > 0 ? (
          <Grid container spacing={3}>
            {report?.attachments?.map((attachment, index) => (
              <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
                <AttachmentCard
                  attachment={attachment}
                  onRemove={removeAttachment}
                  onDownload={downloadHandler}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography>Nog geen bijlages toegevoegd.</Typography>
        )}
      </Box>

      <StudentReportAddAttachmentModal
        isOpen={isUploadAttachmentOpen}
        onClose={() => setIsUploadAttachmentOpen(false)}
        addAttachments={addAttachments}
      />

      <DeletionModal
        isOpen={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        deleteHandler={deleteHandler}
      />

      <ReportEditModal
        isOpen={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        report={report}
        setReport={setReport}
        student={student}
      />
    </PageSuspense>
  );
};

export default StudentReportPage;
