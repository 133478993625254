import Modal from "../../components/ui/modals/Modal";
import SectionTitle from "../../components/Typography/SectionTitle";
import { useHistory } from "react-router";
import { useFormik } from "formik";
import { Button, Grid, Typography, Link as MUILink } from "@material-ui/core";
import FormField from "../../components/forms/FormField";
import SpaceBetweenBox from "../../components/ui/SpaceBetweenBox";
import LoadingButton from "../../components/ui/buttons/LoadingButton";
import { request } from "../../api/requests";
import { toast } from "react-toastify";
import * as yup from "yup";
import { Link } from "react-router-dom";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("De opgegeven E-mail is niet geldig.")
    .required("Vul het E-mail veld in."),
});

const ForgotPassword = () => {
  const history = useHistory();

  const submitHandler = async (values, { setErrors }) => {
    const [, error] = await request({
      method: "POST",
      url: `/api/v1/authentication/request-password`,
      data: values,
    });

    if (!error) {
      toast.success("Het nieuwe wachtwoord is verstuurd!");
    }
  };

  const { values, errors, isSubmitting, handleChange, handleSubmit } =
    useFormik({
      onSubmit: submitHandler,
      initialValues: {
        email: "",
      },
      validationSchema,
    });

  const closeHandler = () => {
    if (!isSubmitting) history.push("/auth/signin");
  };

  return (
    <Modal>
      <SectionTitle>Wachtwoord vergeten</SectionTitle>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography>
              Geef uw geregistreerde e-mail adres op om een nieuw wachtwoord te ontvangen.
              Check voor de zekerheid uw spam box, als u binnen 5 minuten nog steeds geen mail
              heeft ontvangen met uw nieuwe wachtwoord neem dan contact op met onze supportafdeling
              op <a href="mailto:support@justspark.com">support@justspark.com</a>
              {/* Vul uw ec-mail in om een nieuw wachtwoord aan te vragen. Als u het
              nieuwe wachtwoord niet binnen krijgt, kan het komen, omdat het
              e-mail verkeerd is ingevuld. Klik{" "}
              <MUILink component={Link} to={"/auth/signin"}>
                hier
              </MUILink>{" "} */}
              {/* om terug te gaan naar de inlogpagina. */}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FormField
              name="email"
              label="Vul hier uw e-mail adres in"
              values={values.email}
              error={errors.email}
              onInput={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <SpaceBetweenBox>
              <Button onClick={closeHandler} disabled={isSubmitting}>
                Ga terug
              </Button>

              <LoadingButton type="submit" disabled={isSubmitting}>
                Nieuw wachtwoord aanvragen
              </LoadingButton>
            </SpaceBetweenBox>
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
};

export default ForgotPassword;
