import Modal from "../../ui/modals/Modal";
import EmployeeForm from "./EmployeeForm";
import SectionTitle from "../../Typography/SectionTitle";
import useEmployee from "../../../hooks/useEmployee";

const EmployeeEditModal = ({ isOpen, onClose }) => {
  const { employee, updateEmployee } = useEmployee();

  const submitHandler = async (values, { setErrors }) => {
    updateEmployee(values, (_, error) => {
      if (error) setErrors(error?.response?.data?.errors);
      else onClose();
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <SectionTitle>{employee?.name} bijwerken</SectionTitle>

      <EmployeeForm
        employee={employee}
        onSubmit={submitHandler}
        onCancel={onClose}
      />
    </Modal>
  );
};

export default EmployeeEditModal;
