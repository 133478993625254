import _ from "lodash";

class JwtService {
  constructor(token) {
    if (token) {
      let splittedToken = token.split(".");

      this.tokenObject = JSON.parse(atob(splittedToken[1]));
    }
  }

  getClaims = () => this.tokenObject;

  getExpirationDate = () => this.tokenObject.exp;

  getRoles = () => this.tokenObject.role;

  isInRoles = (roles) => {
    const meRoles = this.getRoles();

    if (roles?.length < 1 || roles === null || roles === undefined) return true;

    if (typeof meRoles === "string") {
      return roles?.includes(meRoles);
    } else if (typeof meRoles === "object") {
      return _.intersection(roles, meRoles).length > 0;
    }

    return false;
  };
}

export default JwtService;
