import DataGrid from "../../components/datagrid";
import JwtService from "../../application/jwtService";
import { useSelector } from "react-redux";
import { getToken, getUser } from "../../store/user/selectors";
import { useMemo } from "react";
import { Button } from "@material-ui/core";
import { Link, useRouteMatch } from "react-router-dom";

const UsersPage = () => {
  const token = useSelector((state) => getToken(state));
  const user = useSelector((state) => getUser(state));

  const { url } = useRouteMatch();

  const columns = [
    { field: "name", headerName: "Naam", flex: 1 },
    { field: "email", headerName: "E-mail", flex: 1 },
    {
      field: "view",
      headerName: " ",
      renderCell: ({ row }) => (
        <Button component={Link} to={`${url}/${row.id}`} variant="outlined">
          Bekijken
        </Button>
      ),
    },
  ];

  const isAdmin = useMemo(() => {
    const jwtService = new JwtService(token);
    return jwtService.isInRoles(["Admin"]);
  }, [token]);

  return (
    <>
      <h2>
        Alle gebruikers
      </h2>
      <DataGrid
      columns={columns}
      apiUrl={`/api/v1/${
        isAdmin ? "users" : `organisations/${user.organisation.id}/users`
      }`}
      hasRemoved
    />
    </>
    
  );
};

export default UsersPage;
