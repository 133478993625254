import { ListItem, Typography } from "@material-ui/core";
import moment from "moment";

const UpdateListItem = ({ update }) => (
  <ListItem divider>
    <div>
      <Typography variant="subtitle2">
        {moment(update.createdAt).format("DD-MM-yyyy")}
      </Typography>
      <Typography gutterBottom>{update?.title}</Typography>

      <Typography>{update?.description}</Typography>
    </div>
  </ListItem>
);

export default UpdateListItem;
