export const stringValidationMessage = () => `Veld moet een tekst zijn.`;

export const requiredValidationMessage = () => `Veld is verplicht.`;

export const notRequiredValidationMessage = () => `Veld is niet verplicht.`;

export const maxLengthValidationMessage = (characters) =>
  `Maximaal ${characters} karakters`;

export const maxLengthTwoValidationMessage = (characters) =>
  `Maximaal 6 karakters`;

export const emailValidationMessage = ({ value }) =>
  `E-mail '${value}' is niet geldig.`;
