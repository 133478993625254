import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import Card from "./Card";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: "200px",
    "&:hover": {
      boxShadow: theme.shadows[5],
    },
  },
  url: {
    textDecoration: "none",
  },
}));

const ActionCard = ({ title, description, url }) => {
  const classes = useStyles();

  return (
    <Link to={url} className={clsx(classes.url)}>
      <Card styles={clsx(classes.card)}>
        <Typography component="h2" variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography component="p" variant="body1">
          {description}
        </Typography>
      </Card>
    </Link>
  );
};

export default ActionCard;
