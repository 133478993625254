import DataGrid from "../../components/datagrid";
import { useMemo } from "react";
import JwtService from "../../application/jwtService";
import { useSelector } from "react-redux";
import { getToken, getUser } from "../../store/user/selectors";
import { useState } from "react";
import StudentCreateModal from "../../components/pages/students/StudentCreateModal";
import {
  Add as AddIcon,
  GetApp as GetAppIcon,
  CloudUpload as CloudUploadIcon,
} from "@material-ui/icons";
import ImportModal from "../../components/ui/modals/ImportModal";
import SpeedDial from "../../components/ui/SpeedDial";
import { request } from "../../api/requests";
import * as FileDownload from "js-file-download";
import { Link, useRouteMatch } from "react-router-dom";
import { useRef } from "react";
import { Button } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";

const Students = () => {
  const token = useSelector((state) => getToken(state));
  const user = useSelector((state) => getUser(state));
  const { url } = useRouteMatch();

  const ref = useRef({ current: {} });

  const [isDialOpen, setIsDialOpen] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isImportOpen, setIsImportOpen] = useState(false);

  const isVolunteer = useMemo(() => {
    const jwtService = new JwtService(token);
    return jwtService.isInRoles(["Volunteer"]);
  }, [token]);

  const exportStudents = async () => {
    const [response, error] = await request({
      url: `/api/v1/organisations/${user.organisation.id}/students/export`,
      responseType: "blob",
    });

    if (!error) {
      // Get the filename by splitting the content-disposition header.
      let filename = response.headers["content-disposition"]
        .split("filename=")[1]
        .split(";")[0];

      //Download the file.
      FileDownload(
        response.data,
        filename,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    }
  };

  const actions = [
    {
      tooltipTitle: "Exporteren",
      icon: <GetAppIcon />,
      onClick: () => exportStudents(),
    },
    {
      tooltipTitle: "Importeren",
      icon: <CloudUploadIcon />,
      onClick: () => setIsImportOpen(true),
    },
    {
      tooltipTitle: "Toevoegen",
      icon: <AddIcon />,
      onClick: () => setIsCreateOpen(true),
    },
  ];

  const columns = [
    {
      field: "name",
      headerName: "Naam",
      flex: 1,
    },
    {
      field: "email",
      headerName: "E-mail",
      flex: 1,
    },
    {
      field: "phoneNumber",
      headerName: "Telefoon",
      flex: 1,
    },
    {
      field: "address",
      headerName: "Adres",
      flex: 1,
    },
    {
      field: "township",
      headerName: "Gemeente",
      flex: 1,
    },
    {
      field: "nationality",
      headerName: "Nationaliteit",
      flex: 1,
    },
    {
      field: "employer",
      headerName: "Werkgever",
      flex: 1,
      hide: true,
    },
    {
      field: "remarks",
      headerName: "Opmerkingen",
      flex: 1,
      hide: true,
    },
    {
      field: "quickNote",
      headerName: "Snelle notitie",
      flex: 1,
      hide: true,
    },
    {
      field: "volunteer",
      headerName: "Vrijwilliger",
      flex: 1,
      hide: true,
      renderCell: ({ row }) =>
        !!row.volunteer ? (
          <a
            href={`/vrijwilligers/${row.volunteer.id}`}
            style={{ color: "#fff" }}
            target="_blank"
          >
            {row.volunteer.name}
          </a>
        ) : (
          ""
        ),
    },
    {
      field: "procedures.startedAt",
      headerName: "Traject gestart op",
      hide: true,
      renderCell: ({ row }) => {
        let date = row.recentProcedure?.startedAt;

        return <div>{date && moment(date).format("DD-MM-YYYY")}</div>;
      },
    },
    {
      field: "Procedures.EndedAt",
      headerName: "Traject geëindigd op",
      hide: true,
      renderCell: ({ row }) => {
        let date = row.recentProcedure?.endedAt;

        return <div>{date && moment(date).format("DD-MM-YYYY")}</div>;
      },
    },
    {
      field: "Procedures.ReferredBy",
      headerName: "Doorverwezen vanuit",
      hide: true,
      renderCell: ({ row }) => <div>{row.recentProcedure?.referredBy}</div>,
    },
    {
      field: "Procedures.ReferredTo",
      headerName: "Doorverwezen naar",
      hide: true,
      renderCell: ({ row }) => <div>{row.recentProcedure?.referredTo}</div>,
    },
    {
      field: "Procedures.Level",
      headerName: "Type",
      hide: true,
      renderCell: ({ row }) => <div>{row.recentProcedure?.level}</div>,
    },
    {
      field: "Procedures.Reason",
      flex: 1,
      headerName: "Reden voor vroegtijdig stoppen",
      hide: true,
      renderCell: ({ row }) => <div>{row.recentProcedure?.reason}</div>,
    },
    {
      field: "view",
      headerName: " ",
      sortable: false,
      renderCell: ({ row }) => (
        <Button component={Link} to={`${url}/${row.id}`} variant="outlined">
          Open
        </Button>
      ),
    },
  ];

  const getMostRecentProcedureField = (procedures, field) => {
    if (!procedures || procedures.length < 1) return null;

    let procedure = _.filter(procedures, {
      endedAt: null,
    }).at(0);

    if (!procedure)
      procedure = _.orderBy(procedures, ["endedAt"], ["desc"]).at(0);

    if (!procedure || !procedure[field]) return null;

    return procedure[field];
  };

  const createdHandler = () => {
    ref?.current?.refetch();
  };

  return (
    <section>
      <h1>Taalvragers (Cursisten)</h1>
      <DataGrid
        columns={columns}
        apiUrl={`/api/v1/${
          isVolunteer ? "me" : `organisations/${user.organisation.id}`
        }/students`}
        hasRemoved
        tableRef={ref}
        defaultSortModel={{ field: "name", sort: "asc" }}
      />

      <StudentCreateModal
        isOpen={isCreateOpen}
        onClose={() => setIsCreateOpen(false)}
        onCreated={createdHandler}
      />

      <ImportModal
        isOpen={isImportOpen}
        onClose={() => setIsImportOpen(false)}
        name="Cursisten"
        example="taalhuis_cursisten.xlsx"
        url={`/api/v1/organisations/${user.organisation.id}/students/import`}
      />

      <SpeedDial
        ariaLabel="Cursisten acties"
        actions={actions}
        isDialOpen={isDialOpen}
        setIsDialOpen={setIsDialOpen}
      />
    </section>
  );
};

export default Students;
