import { Button, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import { useMemo } from "react";
import FormField from "../../../forms/FormField";
import SpaceBetweenBox from "../../../ui/SpaceBetweenBox";
import * as yup from "yup";
import LoadingButton from "../../../ui/buttons/LoadingButton";
import STATUS_TYPES from "../../../../application/statusTypes";

const validationSchema = yup.object().shape({
  title: yup.string().required("Voer een titel in"),
  description: yup.string().required("Voer een beschrijving in"),
  referredBy: yup.string().min(3, "Voer minimaal 3 karakters in"),
  referredTo: yup.string().min(3, "Voer minimaal 3 karakters in"),
  reason: yup.string(),
});

const ProcedureForm = ({ procedure, onSubmit, onCancel }) => {
  const initialValues = useMemo(
    () => ({
      title: procedure?.title ?? "",
      description: procedure?.description ?? "",
      level: procedure?.level ?? "",
      referredBy: procedure?.referredBy ?? "",
      referredTo: procedure?.referredTo ?? "",
      reason: procedure?.reason ?? "",
    }),
    [procedure]
  );

  const { values, errors, isSubmitting, handleChange, handleSubmit } =
    useFormik({
      onSubmit,
      initialValues,
      validationSchema,
      enableReinitialize: true,
    });

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormField
            name="title"
            label="Naam van het traject"
            value={values.title}
            onInput={handleChange}
            isDisabled={isSubmitting}
            error={errors.title}
            required
          />
        </Grid>

        <Grid item xs={12}>
          <FormField
            name="description"
            label="Beschrijving van het traject"
            value={values.description}
            onInput={handleChange}
            isDisabled={isSubmitting}
            error={errors.description}
            required
            multiline
          />
        </Grid>

        <Grid item xs={12}>
          <FormField
            name="level"
            label="Niveau"
            value={values.level}
            onInput={handleChange}
            isDisabled={isSubmitting}
            error={errors.level}
          />
        </Grid>

        <Grid item xs={12}>
          <FormField
            name="referredBy"
            label="Doorverwezen vanuit"
            value={values.referredBy}
            onInput={handleChange}
            isDisabled={isSubmitting}
            error={errors.referredBy}
          />
        </Grid>

        <Grid item xs={12}>
          <FormField
            name="referredTo"
            label="Doorverwezen naar"
            value={values.referredTo}
            onInput={handleChange}
            isDisabled={isSubmitting}
            error={errors.referredTo}
          />
        </Grid>

        {procedure?.status === STATUS_TYPES.FINISHED_EARLY && (
          <Grid item xs={12}>
            <FormField
              name="reason"
              label="Reden voor vroegtijdig beëindigen"
              value={values.reason}
              onInput={handleChange}
              isDisabled={isSubmitting}
              error={errors.reason}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <SpaceBetweenBox>
            <Button disabled={isSubmitting} onClick={onCancel}>
              Annuleren
            </Button>

            <LoadingButton disabled={isSubmitting} type="submit">
              Traject starten
            </LoadingButton>
          </SpaceBetweenBox>
        </Grid>
      </Grid>
    </form>
  );
};

export default ProcedureForm;
