import { Check as CheckIcon, Close as CloseIcon } from "@material-ui/icons";

export const objectToFormData = (object, formData = new FormData()) => {
  for (let key in object) {
    if (typeof object[key] === "object") {
      for (let item of object[key]) {
        formData.append(`${key}[]`, item);
      }
    } else {
      formData.append(key, object[key]);
    }
  }

  return formData;
};

// Transform api errors to object.
export const apiErrorsToObject = (errors) => {
  // Create empty object.
  const errorObject = {};

  // Loop over all errors
  for (var error in errors) {
    // Split the error message by a period.
    let splittedString = error.split(".");
    // Set the key to lowercase text
    let key = firstLetterToLower(splittedString[0]);

    // If the length of the splitted message is more than 1
    // it is a nested error.
    if (splittedString.length > 1) {
      // Create a deep errors object.
      let deepErrors = {};

      // Pass the error to the deepObject with the string after the dot as a key.
      // by trimming the first piece and the dot after of the string away with replace.
      deepErrors[error.replace(key + ".", "")] = errors[error];

      // Add the key to the outer errorObject if it doesn't exist.
      if (errorObject[key] === undefined) errorObject[key] = {};

      // The key that will be added to the parent with the child values.
      let innerKey = firstLetterToLower(splittedString[1]);

      // We use the function in a recursive way to get the deeper errors.
      errorObject[key][innerKey] = apiErrorsToObject(deepErrors)[innerKey];
    } else {
      // If it is not a nested error, add it to the array without a recursive way.
      errorObject[key] = errors[error];
    }
  }

  // Return the object with errors.
  return errorObject;
};

export const firstLetterToLower = (string) => {
  let stringFirstLetterLower = string.charAt(0).toLowerCase();
  return string.replace(string.charAt(0), stringFirstLetterLower);
};

export const boolField = (value) => {
  return value ? <CheckIcon /> : <CloseIcon />;
};
