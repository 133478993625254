import {
  Button,
  makeStyles,
  Grid,
  Typography,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import SectionTitle from "../../Typography/SectionTitle";
import { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { assignVolunteerToStudent } from "../../../api/students";
import { useRouteMatch } from "react-router";
import Modal from "../../ui/modals/Modal";
import clsx from "clsx";
import LoadingSpinner from "../../ui/loaders/LoadingSpinner";
import { useFormik } from "formik";
import { request } from "../../../api/requests";
import { useSelector } from "react-redux";
import { getUser } from "../../../store/user/selectors";
import * as yup from "yup";
import SpaceBetweenBox from "../../ui/SpaceBetweenBox";
import { useMemo } from "react";
import { Check as CheckIcon, Close as CloseIcon } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  searchBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: ".75rem",
    MozColumnGap: ".75rem",
    WebkitColumnGap: ".75rem",
  },
  searchField: {
    marginBottom: "16px",
    width: "100%",
    "& .MuiInputBase-root": {
      marginBottom: "0",
    },
  },
  form: {
    position: "relative",
  },
  paper: {
    overflow: "visible",
  },
  modal: {
    "& .MuiCard-root": {},
  },
  volunteerListWrapper: {
    height: "150px",
    overflowY: "auto",
  },
  volunteerList: {
    width: "100%",
    listStyleType: "none",
    padding: 0,
    margin: 0,
  },
  volunteerListItem: {
    display: "flex",
    cursor: "pointer",
    padding: ".5rem 1rem",
    borderRadius: "4px",
    transition: "background-color .1s ease-in-out",
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
    "&:not(:last-child)": {
      marginBottom: ".75rem",
    },
  },
  infoColumn: {
    flex: 1,
  },
  selectedItem: {
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
  volunteerBlock: {
    padding: ".5rem 1rem",
  },
}));

const validationSchema = yup.object().shape({
  q: yup
    .string()
    .required("Voer een naam of e-mail in")
    .min(3, "Voer minimaal 3 karaters in"),
});

const StudentAssignVolunteerModal = ({
  isOpen,
  onClose,
  currentVolunteer,
  assignVolunteer,
}) => {
  const {
    params: { id },
  } = useRouteMatch();

  const user = useSelector((state) => getUser(state));

  const [volunteers, setVolunteers] = useState(null);
  const [chosenVolunteer, setChosenVolunteer] = useState(null);

  const onSubmit = async (values, { setErrors }) => {
    const [response, error] = await request({
      method: "GET",
      url: `/api/v1/organisations/${user.organisation.id}/volunteers?q=${values.q}&limit=100&active=true`,
    });

    if (error) {
      setErrors({ q: "Kon geen vrijwilligers ophalen" });
    } else {
      var volunteers = response.data.items;

      if (volunteers.length < 1) {
        setErrors({ q: `Geen vrijwilligers gevonden` });
      }

      setVolunteers(volunteers);
    }
  };

  const initialValues = useMemo(
    () => ({
      q: "",
    }),
    []
  );

  const {
    values,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    setSubmitting,
  } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  const classes = useStyles();

  const chooseVolunteerHandler = (volunteer = null) => {
    setChosenVolunteer(volunteer);
  };

  const assignVolunteerHandler = async (event) => {
    setSubmitting(true);

    const error = await assignVolunteerToStudent(id, chosenVolunteer);

    if (!error) {
      assignVolunteer(chosenVolunteer);

      setChosenVolunteer(null);
      setVolunteers([]);
      onClose();
    }

    setSubmitting(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={!isSubmitting ? onClose : null}
      className={clsx(classes.modal)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SectionTitle>Vrijwilliger toewijzen</SectionTitle>

          <Typography gutterBottom>
            Zoek een vrijwilliger op om aan de cursist te koppelen. Of kies voor
            "Geen vrijwilliger" om een vrijwillger weg te halen.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit} className={clsx(classes.searchBox)}>
            <TextField
              onInput={handleChange}
              value={values.q}
              name="q"
              label="Vrijwilliger zoeken"
              placeholder="Zoek vrijwilliger op naam, e-mail, adres of voorkeuren."
              className={clsx(classes.searchField)}
              disabled={isSubmitting}
              error={!!errors.q}
              helperText={errors.q}
            />

            <IconButton type="submit" disabled={isSubmitting}>
              <SearchIcon />
            </IconButton>
          </form>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Gevonden vrijwilligers</Typography>

          <div className={clsx(classes.volunteerListWrapper)}>
            {isSubmitting ? (
              <LoadingSpinner size={25} />
            ) : (
              <ul className={clsx(classes.volunteerList)}>
                <li
                  onClick={() => chooseVolunteerHandler()}
                  className={clsx(
                    classes.volunteerListItem,
                    chosenVolunteer == null && classes.selectedItem
                  )}
                >
                  Geen vrijwilliger
                </li>

                {volunteers &&
                  volunteers.map((volunteer, index) => (
                    <li
                      onClick={() => chooseVolunteerHandler(volunteer)}
                      key={index}
                      className={clsx(
                        classes.volunteerListItem,
                        JSON.stringify(volunteer) ===
                          JSON.stringify(chosenVolunteer) &&
                          classes.selectedItem
                      )}
                      aria-label={`Selecteer ${volunteer.name} als vrijwilliger.`}
                    >
                      <div className={clsx(classes.infoColumn)}>
                        <Typography
                          variant="h6"
                          component="span"
                          gutterBottom={false}
                        >
                          {volunteer.name}
                        </Typography>

                        <Typography variant="body2" gutterBottom>
                          {volunteer.email}
                        </Typography>

                        {!!volunteer.preference && (
                          <Typography variant="body2">
                            {volunteer.preference}
                          </Typography>
                        )}
                      </div>

                      <div>
                        <Tooltip
                          title={
                            volunteer.available
                              ? "Beschikbaar"
                              : "Niet beschikbaar"
                          }
                        >
                          {volunteer.available ? <CheckIcon /> : <CloseIcon />}
                        </Tooltip>
                      </div>
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </Grid>

        <Grid item xs={12} className={clsx(classes.volunteerBlock)}>
          <Typography variant="h6">Huidige vrijwilliger</Typography>
          {currentVolunteer ? (
            <Typography variant="body2">
              {currentVolunteer.name}
              <br />
              {currentVolunteer.email}
            </Typography>
          ) : (
            <Typography variant="body2">
              De cursist heeft op het moment nog geen vrijwilliger.
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} className={clsx(classes.volunteerBlock)}>
          <Typography variant="h6">Gekozen vrijwilliger</Typography>
          {chosenVolunteer ? (
            <Typography variant="body2">
              {chosenVolunteer.name}
              <br />
              {chosenVolunteer.email}
            </Typography>
          ) : (
            <Typography variant="body2">Geen vrijwilliger gekozen.</Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <SpaceBetweenBox>
            <Button onClick={onClose} disabled={isSubmitting}>
              Annuleren
            </Button>

            <Button
              onClick={assignVolunteerHandler}
              disabled={
                isSubmitting ||
                JSON.stringify(currentVolunteer) ===
                  JSON.stringify(chosenVolunteer)
              }
            >
              Vrijwilliger toewijzen
            </Button>
          </SpaceBetweenBox>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default StudentAssignVolunteerModal;
