import { Redirect, Route, Switch } from "react-router";
import ForbiddenPage from "../pages/forbidden";
import NotFoundPage from "../pages/not-found";

const RouterSwitch = ({ children }) => (
  <Switch>
    {children}

    <Route path="/forbidden" exact component={ForbiddenPage} />
    <Route path="/not-found" exact component={NotFoundPage} />

    <Redirect to="/not-found" />
  </Switch>
);

export default RouterSwitch;
