import { Button, Typography } from "@material-ui/core";
import useStudent from "../../../../hooks/useStudent";
import ProcedureTimelineItem from "./ProcedureTimelineItem";

const ProcedureStepVolunteer = () => {
  const { student, setIsAssignVolunteerOpen } = useStudent();

  return (
    <ProcedureTimelineItem completed={!!student.volunteer}>
      <Typography variant="h6">Cursist matchen</Typography>

      <Typography>
        Kies een vrijwilliger om te matchen met een cursist.
      </Typography>

      <Button onClick={() => setIsAssignVolunteerOpen(true)}>
        {!!student.volunteer
          ? "Vrijwilliger toewijzen"
          : "Vrijwilliger wijzigen"}
      </Button>
    </ProcedureTimelineItem>
  );
};

export default ProcedureStepVolunteer;
