import { Button, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";
import * as _ from "lodash";
import ProcedureTimelineItem from "./ProcedureTimelineItem";
import AppointmentCreateModal from "./appointments/AppointmentCreateModal";
import APPOINTMENT_TYPES from "../../../../application/appointmentTypes";
import ConfirmationModal from "../../../ui/modals/ConfirmationModal";
import COMMAND_TYPES from "../../../../application/commandTypes";
import { procedureCommands } from "../../../../api/students/procedures";
import { Fragment } from "react";
import useProcedure from "../../../../hooks/useProcedure";

const ProcedureStepTwo = () => {
  const { procedure, setProcedure } = useProcedure();

  const [interviews, setInterviews] = useState([]);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const appointments = procedure?.appointments ?? [];

    setInterviews(
      _.filter(appointments, ["type", APPOINTMENT_TYPES.INTERVIEW])
    );
  }, [procedure.appointments]);

  const confirmationHandler = async () => {
    const updated = await procedureCommands(procedure.id, {
      command: COMMAND_TYPES.INTERVIEWS_DONE,
    });

    if (updated) {
      setProcedure({ ...procedure, hadInterviews: true });

      confirmationModalHandler();
    }
  };

  const isOpenHandler = () => setIsOpen(!isOpen);

  const confirmationModalHandler = () =>
    setIsConfirmationOpen(!isConfirmationOpen);

  return (
    <ProcedureTimelineItem completed={procedure.hadInterviews}>
      <Typography variant="h6" component="h2" gutterBottom>
        Kennismaking plannen
      </Typography>

      <Typography gutterBottom>
        Plan een kennismakingsgesprek in met de kandidaat.
      </Typography>

      <Fragment>
        {procedure.hadInterviews || (
          <div>
            <Button onClick={isOpenHandler}>
              {interviews.length < 1 ? "Inplannen" : "Extra gesprek inplannen"}
            </Button>

            <Button onClick={confirmationModalHandler}>
              {interviews.length < 1 ? "Overslaan" : "Stap afronden"}
            </Button>

            <ConfirmationModal
              isOpen={isConfirmationOpen}
              onClose={confirmationModalHandler}
              message="Zijn de kennismakingsgesprekken afgerond? Het is namelijk niet meer mogelijk om dit terug te draaien."
              onConfirm={confirmationHandler}
            />

            <AppointmentCreateModal
              isOpen={isOpen}
              onClose={isOpenHandler}
              type={APPOINTMENT_TYPES.INTERVIEW}
            />
          </div>
        )}
      </Fragment>
    </ProcedureTimelineItem>
  );
};

export default ProcedureStepTwo;
