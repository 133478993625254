import Modal from "../../ui/modals/Modal";
import StudentForm from "./StudentForm";
import SectionTitle from "../../Typography/SectionTitle";
import { patchStudent } from "../../../api/students/index";

const StudentEditModal = ({ isOpen, onClose, student, setStudent }) => {
  const submitHandler = async (values, { setSubmitting, setErrors }) => {
    const errors = await patchStudent(student.id, values);

    if (errors) {
      setErrors(errors);
    } else {
      setStudent({
        ...student,
        ...values,
      });
    }

    setSubmitting(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <SectionTitle>{student?.name} bijwerken</SectionTitle>

      <StudentForm
        student={student}
        onSubmit={submitHandler}
        onCancel={onClose}
      />
    </Modal>
  );
};

export default StudentEditModal;
