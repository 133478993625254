import { Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  dangerBtn: {
    backgroundColor: "#dc3545",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#c82333",
    },
  },
}));

const DangerButton = (props) => {
  const classes = useStyles();

  return (
    <Button {...props} className={clsx(classes.dangerBtn)}>
      {props.children}
    </Button>
  );
};

export default DangerButton;
