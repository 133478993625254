import { makeStyles } from "@material-ui/core";
import { Button, Typography } from "@material-ui/core";
import { useState } from "react";
import { toast } from "react-toastify";
import { request } from "../../../api/requests";
import SectionTitle from "../../Typography/SectionTitle";
import DangerButton from "../../ui/buttons/DangerButton";
import ConfirmationModal from "../../ui/modals/ConfirmationModal";
import Modal from "../../ui/modals/Modal";
import SpaceBetweenBox from "../../ui/SpaceBetweenBox";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  danger: {
    color: "red",
  },
}));

const UserGdprModal = ({ isOpen, onClose, user, setUser }) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isDeletingOpen, setIsDeletingOpen] = useState(false);

  const classes = useStyles();

  const deletePersonalData = async () => {
    setIsDeletingOpen(true);

    const [response, error] = await request({
      method: "PATCH",
      url: `/api/v1/users/${user?.id}/anonymize`,
    });

    if (error) {
      let errorMessage = error?.response?.data?.errors?.account[0];

      toast.error(
        errorMessage ??
          "Er was een error bij het verwijderen van de persoonsgegevens."
      );

      setIsDeletingOpen(false);
    } else {
      setUser({
        ...user,
        ...response?.data?.user,
      });

      closeConfirmationHandler();
    }
  };

  const closeConfirmationHandler = () => {
    setIsConfirmOpen(false);
    setIsDeletingOpen(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <SectionTitle>Persoonsgegevens vergeten</SectionTitle>

      <Typography gutterBottom>
        Bij het vergeten van een persoon worden al zijn gegevens verwijderd.
        Alle notities, updates, etc. worden wel bewaard.
        <br />
        <br />
        <span className={clsx(classes.danger)}>
          DE GEGEVENS ZIJN NIET MEER TERUG TE HALEN!
        </span>
      </Typography>

      <SpaceBetweenBox>
        <Button onClick={onClose}>Annuleren</Button>

        <DangerButton onClick={() => setIsConfirmOpen(true)}>
          Persoonsgegevens verwijderen
        </DangerButton>
      </SpaceBetweenBox>

      <ConfirmationModal
        isOpen={isConfirmOpen}
        onClose={closeConfirmationHandler}
        onConfirm={deletePersonalData}
        message="Weet u zeker dat u de persoonsgegevens wilt verwijderen?"
      />

      <Modal isOpen={isDeletingOpen}>
        <SectionTitle>Persoonsgegevens worden verwijderd...</SectionTitle>
      </Modal>
    </Modal>
  );
};

export default UserGdprModal;
