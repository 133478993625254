import { Typography } from "@material-ui/core";
import Card from "../../../ui/cards/Card";
import moment from "moment";

const LogCard = ({ log }) => (
  <Card>
    <Typography variant="caption" component="h4">
      {moment(log.createdAt).format("DD-MM-yyyy")}
    </Typography>
    <Typography variant="h6" component="h3" gutterBottom>
      {log.title}
    </Typography>
    <Typography variant="body1" component="p">
      {log.description}
    </Typography>
  </Card>
);

export default LogCard;
