import { useHistory } from "react-router";
import { postVolunteer } from "../../../api/volunteers";
import Modal from "../../ui/modals/Modal";
import SectionTitle from "../../Typography/SectionTitle";
import VolunteerForm from "./VolunteerForm";
import { useSelector } from "react-redux";
import { getUser } from "../../../store/user/selectors";

const VolunteerCreateModal = ({ isOpen, onClose, onCreated }) => {
  const user = useSelector((state) => getUser(state));
  const history = useHistory();

  const submitHandler = async (values, { setErrors }) => {
    const [volunteer, errors] = await postVolunteer(
      user.organisation.id,
      values
    );

    if (errors) {
      setErrors(errors);
    } else {
      if (values.redirectToEntity) {
        history.push(`/vrijwilligers/${volunteer.id}`);
      } else {
        if (typeof onCreated == "function") onCreated();
        onClose();
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <SectionTitle>Vrijwilliger toevoegen</SectionTitle>

      <VolunteerForm onSubmit={submitHandler} onCancel={onClose} />
    </Modal>
  );
};

export default VolunteerCreateModal;
