import {
  AppBar,
  Box,
  Grid,
  Paper,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import LoginForm from "../../components/pages/login/LoginForm";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useState } from "react";
import LoginBg from "../../assets/login-bg.png";
import LightLogo from "../../assets/MijnTaalhuis_Logo.svg";
import DarkLogo from "../../assets/MijnTaalhuis_Logo_DarkMode.svg";
import { useSelector } from "react-redux";
import DarkModeButton from "../../components/ui/DarkModeButton";
import AppBarUpdates from "../../components/layout/AppbarUpdates";
import { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
  column: {
    minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    "@media (min-width:0px) and (orientation: landscape)": {
      minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:0px) and (orientation: landscape)"]?.minHeight}px)`,
    },
    "@media (min-width:600px)": {
      minHeight: `calc(100vh - ${
        theme.mixins.toolbar["@media (min-width:600px)"]?.minHeight ?? 0
      }px)`,
    },
    padding: "4rem",
    position: "relative",
  },
  logo: {
    height: "118px",
    width: "279px",
    position: "relative",
  },
  betaTag: {
    display: "inline-block",
    position: "absolute",
    top: "5px",
    right: "-20px",
  },
  betaTagText: {
    padding: ".1rem .75rem",
    display: "block",
  },
  background: {
    backgroundImage: `url(${LoginBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
  },
  toolbar: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",
    "& .MuiIconButton-root": {
      color: "#fff !important",
    },
  },
}));

const SignIn = () => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const aboveMd = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const darkMode = useSelector((state) => state.theme.darkMode);

  return (
    <Fragment>
      <AppBar position="relative" color="secondary">
        <Toolbar>
          <div className={clsx(classes.toolbar)}>
            <DarkModeButton />
            <AppBarUpdates />
          </div>
        </Toolbar>
      </AppBar>
      <Grid container>
        <Grid item xs={12} md={7} className={classes.column}>
          <div className={clsx(classes.logo)}>
            <img
              src={darkMode ? DarkLogo : LightLogo}
              alt="Mijntaalhuis logo"
              className={clsx(classes.logo)}
            />
            <Paper className={clsx(classes.betaTag)}>
              <Typography className={clsx(classes.betaTagText)}>
                 {global.config.versionId.currentVersion.ID}
              </Typography>
            </Paper>
          </div>

          <Box maxWidth="400px" marginY="1.5rem">
            <LoginForm isOpen={isOpen} setIsOpen={setIsOpen} />
          </Box>
        </Grid>
        {aboveMd && (
          <Grid
            item
            xs={12}
            md={5}
            className={clsx(classes.background, classes.column)}
          />
        )}
      </Grid>
    </Fragment>
  );
};

export default SignIn;
