import { applyMiddleware, createStore } from "redux";
import { combineReducers } from "redux";
import themeReducer from "./theme";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import updatesReducer from "./updates";
import persistedUserReducer from "./user";
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
} from "redux-state-sync";

// Persistance config
const persistConfig = {
  key: "taalhuis-app",
  storage,
  whitelist: ["auth.token", "theme", "updates", "auth.twofactor"],
};

// Browser sync config
const syncConfig = {
  channel: "taalhuis_state_channel",
  broadcastChannelOption: { type: "localstorage" },
  blacklist: ["persist/PERSIST", "persist/REHYDRATE"],
};

// Combine the reducers to one root reducer
const rootReducer = combineReducers({
  auth: persistedUserReducer,
  theme: themeReducer,
  updates: updatesReducer,
});

// Setup persisted Reducer
const persistedReducers = persistReducer(persistConfig, rootReducer);

// Create the store from the persisted reducer
// Apply the state middleware
export const store = createStore(
  persistedReducers,
  {},
  applyMiddleware(createStateSyncMiddleware(syncConfig))
);

// Create a persistor from the store
export const persistor = persistStore(store);

// Initialize browser sync
initStateWithPrevTab(store);
