const initialState = {
  items: [],
};

const updatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_UPDATES":
      return { items: action.payload ?? [] };
    default:
      return state;
  }
};

export default updatesReducer;
