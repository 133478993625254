import axios from "axios";

const useRequest = () => {
  const postRequest = async (url, data = null, config = null) => {
    try {
      const response = await axios.post(url, data, config);

      return [response, null];
    } catch (exception) {
      return [null, exception];
    }
  };

  const patchRequest = async (url, data = null, config = null) => {
    try {
      const response = await axios.patch(url, data, config);

      return [response, null];
    } catch (exception) {
      return [null, exception];
    }
  };

  const deleteRequest = async (url, config = null) => {
    try {
      const response = await axios.delete(url, config);

      return [response, null];
    } catch (exception) {
      return [null, exception];
    }
  };

  return {
    postRequest,
    patchRequest,
    deleteRequest,
  };
};

export default useRequest;
