import { useDataTable } from "../../hooks";
import DataTableToolbar from "./DataTableToolbar";
// import { DataGrid as MuiDataGrid, nlNL } from "@mui/x-data-grid";
import { DataGridPro as MuiDataGrid, nlNL } from "@mui/x-data-grid-pro";
import { Box } from "@mui/material";

const DataTable = ({ columns = [] }) => {
  const {
    pageSize,
    setPageSize,
    totalRows,
    isLoading,
    error,
    pageHandler,
    rows,
    page,
    sortModel,
    sortModelHandler,
  } = useDataTable();

  return (
    <Box sx={{ color: "primary.main" }}>
      <MuiDataGrid
        components={{
          Toolbar: DataTableToolbar,
        }}
        loading={isLoading}
        error={error}
        onPageChange={pageHandler}
        paginationMode="server"
        page={page}
        pageSize={pageSize}
        pagination={true}
        rowCount={totalRows}
        rowsPerPageOptions={[10, 25, 50, 100, 150, 250]}
        onPageSizeChange={(size) => setPageSize(size)}
        disableColumnFilter
        disableColumnReorder
        disableMultipleColumnsSorting
        disableMultipleColumnsFiltering
        autoHeight
        columns={columns}
        rows={rows}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={sortModelHandler}
        localeText={nlNL.components.MuiDataGrid.defaultProps.localeText}
      />
    </Box>
  );
};

export default DataTable;
