import { createContext } from "react";
import VolunteerRouter from "../../../components/pages/volunteers/VolunteerRoutes";
import {
  VolunteerProvider,
  NotesProvider,
  FilesProvider,
  LogsProvider,
} from "../../../providers";

export const volunteerContext = createContext();

const ShowVolunteer = ({ children }) => {
  return (
    <VolunteerProvider>
      <LogsProvider>
        <FilesProvider>
          <NotesProvider>
            <VolunteerRouter routes={children} />
          </NotesProvider>
        </FilesProvider>
      </LogsProvider>
    </VolunteerProvider>
  );
};

export default ShowVolunteer;
