import Modal from "../../ui/modals/Modal";
import SectionTitle from "../../Typography/SectionTitle";
import UpdateForm from "./UpdateForm";
import { postUpdate } from "../../../api/updates";

const UpdateCreateModal = ({ isOpen, onClose, onCreated }) => {
  const submitHandler = async (values, { setErrors }) => {
    const [, errors] = await postUpdate(values);

    if (errors) {
      setErrors(errors);
    } else {
      onClose();
      onCreated();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <SectionTitle>Update toevoegen</SectionTitle>

      <UpdateForm onSubmit={submitHandler} onCancel={onClose} />
    </Modal>
  );
};

export default UpdateCreateModal;
