import DataGrid from "../../components/datagrid";
import { useSelector } from "react-redux";
import { getUser } from "../../store/user/selectors";
import {
  GetApp as GetAppIcon,
  Add as AddIcon,
  CloudUpload as CloudUploadIcon,
} from "@material-ui/icons";
import { useRef, useState } from "react";
import VolunteerCreateModal from "../../components/pages/volunteers/VolunteerCreateModal";
import { Fragment } from "react";
import SpeedDial from "../../components/ui/SpeedDial";
import { request } from "../../api/requests";
import * as FileDownload from "js-file-download";
import { Button } from "@material-ui/core";
import { Link, useRouteMatch } from "react-router-dom";
import ImportModal from "../../components/ui/modals/ImportModal";
import { boolField } from "../../application/helpers";

const Volunteers = () => {
  const user = useSelector((state) => getUser(state));

  const { url } = useRouteMatch();

  const ref = useRef({ current: {} });

  const [isDialOpen, setIsDialOpen] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isImportOpen, setIsImportOpen] = useState(false);

  const exportVolunteer = async () => {
    const [response, error] = await request({
      url: `/api/v1/organisations/${user.organisation.id}/volunteers/export`,
      responseType: "blob",
    });

    if (!error) {
      // Get the filename by splitting the content-disposition header.
      let filename = response.headers["content-disposition"]
        .split("filename=")[1]
        .split(";")[0];

      //Download the file.
      FileDownload(
        response.data,
        filename,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    }
  };

  const columns = [
    { field: "name", headerName: "Naam", flex: 1 },
    { field: "email", headerName: "E-mail", flex: 1 },
    { field: "phoneNumber", headerName: "Telefoon", flex: 1 },
    { field: "address", headerName: "Adres", flex: 1 },
    { field: "preference", headerName: "Voorkeuren", flex: 1, hide: true },
    {
      field: "active",
      headerName: "Actief",
      flex: 1,
      hide: true,
      renderCell: ({ row }) => boolField(row.active),
    },
    {
      field: "available",
      headerName: "Beschikbaar",
      flex: 1,
      hide: true,
      renderCell: ({ row }) => boolField(row.available),
    },
    {
      field: "view",
      headerName: " ",
      renderCell: ({ row }) => (
        <Button component={Link} to={`${url}/${row.id}`} variant="outlined">
          Bekijken
        </Button>
      ),
      sortable: false,
    },
  ];

  const actions = [
    {
      tooltipTitle: "Exporteren",
      icon: <GetAppIcon />,
      onClick: () => exportVolunteer(),
    },
    {
      tooltipTitle: "Importeren",
      icon: <CloudUploadIcon />,
      onClick: () => setIsImportOpen(true),
    },
    {
      tooltipTitle: "Toevoegen",
      icon: <AddIcon />,
      onClick: () => setIsCreateOpen(true),
    },
  ];

  const createdHandler = () => {
    ref?.current?.refetch();
  };

  return (
    <Fragment>
      <h2>
        Vrijwilligers
      </h2>
      <DataGrid
        columns={columns}
        apiUrl={`/api/v1/organisations/${user.organisation.id}/volunteers`}
        hasRemoved
        tableRef={ref}
      />

      <VolunteerCreateModal
        isOpen={isCreateOpen}
        onClose={() => setIsCreateOpen(false)}
        onCreated={createdHandler}
      />

      <ImportModal
        isOpen={isImportOpen}
        onClose={() => setIsImportOpen(false)}
        name="Vrijwilligers"
        example="taalhuis_vrijwilligers.xlsx"
        url={`/api/v1/organisations/${user.organisation.id}/volunteers/import`}
      />

      <SpeedDial
        ariaLabel="Vrijwilligers acties"
        actions={actions}
        isDialOpen={isDialOpen}
        setIsDialOpen={setIsDialOpen}
      />
    </Fragment>
  );
};

export default Volunteers;
