import FabBox from "../FabBox";
import { Fab } from "@material-ui/core";

const FloatingActionButton = (props) => {
  return (
    <FabBox>
      <div style={{ pointerEvents: "auto" }}>
        <Fab color="primary" {...props}>
          {props.icon}
        </Fab>
      </div>
    </FabBox>
  );
};

export default FloatingActionButton;
