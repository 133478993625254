import InformationList from "../../ui/lists/InformationList";
import InformationListItem from "../../ui/lists/InformationListItem";
import moment from "moment";

const StudentInfo = ({ student }) => {
  return (
    <InformationList>
      <InformationListItem title="Naam">{student.name}</InformationListItem>
      <InformationListItem title="E-mail">{student.email}</InformationListItem>
      <InformationListItem title="Telefoonnummer">
        {!!student?.phoneNumber
          ? student.phoneNumber
          : "Er is geen telefoonnummer geregistreerd"}
      </InformationListItem>
      <InformationListItem title="Adres">
        {!!student?.address ? student.address : "Er is geen adres geregistreerd"}
      </InformationListItem>
      <InformationListItem title="Gemeente">
        {!!student?.township ? student.township : "Er is geen gemeente geregistreerd"}
      </InformationListItem>
      <InformationListItem title="Nationaliteit">
        {!!student?.nationality
          ? student.nationality
          : "Er is geen nationaliteit opgegeven"}
      </InformationListItem>
      <InformationListItem title="Geboortedatum">
        {!!student?.dateOfBirth
          ? moment(student.dateOfBirth).format("DD-MM-yyyy")
          : "Er is geen geboortedatum geregistreerd"}
      </InformationListItem>
      <InformationListItem title="Werkgever">
        {student?.employer}
      </InformationListItem>
      <InformationListItem title="Opmerkingen">
        {student?.remarks}
      </InformationListItem>
    </InformationList>
  );
};

export default StudentInfo;
