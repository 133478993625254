import { Button, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { request } from "../../../api/requests";
import FormField from "../../forms/FormField";
import QRCode from "react-qr-code";
import SpaceBetweenBox from "../../ui/SpaceBetweenBox";
import * as yup from "yup";
import {
  emailValidationMessage,
  maxLengthValidationMessage,
  notRequiredValidationMessage,
  requiredValidationMessage,
  stringValidationMessage,
} from "../../../application/yup/validation";
import { MAX_LENGTH } from "../../../application/constants";
import { CheckBox } from "@material-ui/icons";

const validationSchema = yup.object().shape({
  name: yup
    .string(stringValidationMessage)
    .max(MAX_LENGTH, maxLengthValidationMessage)
    .required(requiredValidationMessage),
  email: yup
    .string(stringValidationMessage)
    .email(emailValidationMessage)
    .max(MAX_LENGTH, maxLengthValidationMessage)
    .required(requiredValidationMessage),
  phoneNumber: yup
    .string(stringValidationMessage)
    .max(MAX_LENGTH, maxLengthValidationMessage)
    .notRequired(notRequiredValidationMessage),
});

const AccountInfoTab = () => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const initialValues = useMemo(
    () => ({
      name: user.name,
      email: user.email,
      phoneNumber: user.phoneNumber ?? "",
      address: user.address ?? "",
      twofactor: false,
      twofactorQR: false,
      twofactorUri: "",
      secret: "",
    }),
    [user]
  );

  const hasAddress = useMemo(
    () =>
      user.discriminator === "Volunteer" || user.discriminator === "Student",
    [user]
  );

  const submitHandler = async (values, { setErrors }) => {
    if (values.twofactor) {
      const [response, error] = await request({
        method: "PATCH",
        url: `/api/v1/me/twofactor`,
        data: {"reset": true},
      });

      if (error) {
        setErrors(error?.response?.data?.errors ?? {});
      } else {
        values.email = response.data.email
        values.secret = response.data.secret
        // values.twofactorUri = `https://api.qrserver.com/v1/create-qr-code/?data=otpauth://totp/Justspark:${values.email}?secret=${values.secret}&amp;size=100x100`
        values.twofactorQR = true
        //dispatch({ type: "SET_USER", payload: { ...user, ...values } });
        //dispatch({ type: "SET_TOKEN", payload: response.data.token });
      }
      toast.success("Er is een nieuwe token aangemaakt, scan de QR code in je favoriete authenticator app, let op! als je dit niet doet kun je niet meer inloggen.");
    }
    else {
      const [response, error] = await request({
        method: "PATCH",
        url: `/api/v1/me`,
        data: values,
      });

      if (error) {
        setErrors(error?.response?.data?.errors ?? {});
      } else {
        toast.success("Uw account is bijgewerkt");

        dispatch({ type: "SET_USER", payload: { ...user, ...values } });
        dispatch({ type: "SET_TOKEN", payload: response.data.token });
      }
    }

  };

  const {
    values,
    errors,
    isSubmitting,
    handleChange,
    resetForm,
    handleSubmit,
  } = useFormik({
    onSubmit: submitHandler,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  const hasChanged = useMemo(
    () => JSON.stringify(initialValues) !== JSON.stringify(values),
    [initialValues, values]
  );

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormField
            name="name"
            label="Naam"
            value={values.name}
            onInput={handleChange}
            isDisabled={isSubmitting}
            error={errors.name}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormField
            type="email"
            name="email"
            label="E-mail"
            value={values.email}
            onInput={handleChange}
            isDisabled={isSubmitting}
            error={errors.email}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormField
            name="phoneNumber"
            label="Telefoonnummer"
            value={values.phoneNumber}
            onInput={handleChange}
            isDisabled={isSubmitting}
            error={errors.phoneNumber}
          />
        </Grid>
        <Grid item xs={12} md={6}>


        </Grid>
        <Grid item xs={12} md={6}>


        </Grid>
        <Grid item xs={12} md={12}>
          <Button type="submit" disabled={isSubmitting} onClick={() => { values.twofactor = true }}>
            Reset tweestapsverificatie token
          </Button>
          
          

        </Grid>
        {values.twofactorQR && (
          <Grid item xs={12} md={12}>
            {/* <img src={`https://api.qrserver.com/v1/create-qr-code/?data=otpauth://totp/MijnTaalhuis:${values.email}?secret=${values.secret}&amp;size=100x100`} alt="" title="TwoFactor" /> */}
            <QRCode
              size={150}
              style={{ height: "150", maxWidth: "100%", width: "100%" }}
              value={`otpauth://totp/MijnTaalhuis:${values.email}?secret=${values.secret}`}
              bgColor="#303030"
              fgColor="#ffffff"
              title="TwoFactorRegistratie"
            />
            <br></br>
            <br></br>Scan de volgende QR code in je authenticator app naar keuze of voer de volgende sleutel in je authenticator app <a href={`otpauth://totp/MijnTaalhuis:${values.email}?secret=${values.secret}`}>{values.secret}</a>
            <br></br>Let op! deze code krijg je niet meer te zien, in het geval dat je geen toegang meer hebt tot je toestel kun je via onze support afdeling je tweestapsverificatie resetten

          </Grid>
        )}
        






        {hasAddress && (
          <Grid item xs={12}>
            <FormField
              name="address"
              label="Adres"
              value={values.address}
              onInput={handleChange}
              isDisabled={isSubmitting}
              error={errors.address}
              multiline
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <SpaceBetweenBox>
            <Button onClick={resetForm} disabled={isSubmitting || !hasChanged}>
              Resetten
            </Button>

            <Button type="submit" disabled={isSubmitting || !hasChanged}>
              Opslaan
            </Button>
          </SpaceBetweenBox>
        </Grid>
      </Grid>
    </form>
  );
};

export default AccountInfoTab;
