import axios from "axios";

export const request = async (requestObject) => {
  try {
    const response = await axios(requestObject);

    return [response, null];
  } catch (error) {
    return [null, error];
  }
};
