import { Cell, Pie, PieChart, Tooltip } from "recharts";
import LegendList from "../../../ui/lists/LegendList";
import LegendListItem from "../../../ui/lists/LegendListItem";
import { GetStatusName } from "../../../../application/statusTypes";
import { Grid } from "@material-ui/core";

const colors = [
  "#00876c",
  "#569e7a",
  "#87b68d",
  "#b2cda5",
  "#dae6c2",
  "#ffffe4",
  "#f1dfb3",
  "#eabd88",
  "#e59768",
  "#df6e55",
  "#d43d51",
];

const StudentsByStatusChart = ({ studentsByStatus }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <LegendList>
          {studentsByStatus.map(({ status }, index) => (
            <LegendListItem
              color={colors[index]}
              key={`status-legend-${index}`}
            >
              {GetStatusName(status)}
            </LegendListItem>
          ))}
        </LegendList>
      </Grid>
      <Grid item xs={12} md={6}>
        <PieChart width={300} height={300}>
          <Pie
            data={studentsByStatus}
            dataKey="count"
            nameKey="status"
            cx="50%"
            cy="50%"
            outerRadius="80%"
            legendType="rect"
          >
            {studentsByStatus.map((appointment, index) => (
              <Cell
                key={`student-status-${appointment.index}`}
                fill={colors[index]}
              />
            ))}
          </Pie>

          <Tooltip
            formatter={(value, key) => `${GetStatusName(key)}: ${value}`}
          />
        </PieChart>
      </Grid>
    </Grid>
  );
};

export default StudentsByStatusChart;
