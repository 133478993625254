import { useFormik } from "formik";
import { Button, Grid } from "@material-ui/core";
import FormField from "../../forms/FormField";
import FormSectionTitle from "../../Typography/FormSectionTitle";
import * as yup from "yup";
import {
  LONG_MAX_LENGTH,
  MAX_ADDRESS_LENGTH,
  MAX_LENGTH,
} from "../../../application/constants";
import { useMemo } from "react";
import SpaceBetweenBox from "../../ui/SpaceBetweenBox";
import LoadingButton from "../../ui/buttons/LoadingButton";
import moment from "moment";
import {
  emailValidationMessage,
  maxLengthValidationMessage,
  notRequiredValidationMessage,
  requiredValidationMessage,
  stringValidationMessage,
} from "../../../application/yup/validation";

const validationSchema = yup.object().shape({
  name: yup
    .string(stringValidationMessage)
    .max(MAX_LENGTH, maxLengthValidationMessage)
    .required(requiredValidationMessage),
  email: yup
    .string(stringValidationMessage)
    .max(MAX_LENGTH, maxLengthValidationMessage)
    .email(emailValidationMessage)
    .required(requiredValidationMessage),
  phoneNumber: yup
    .string(stringValidationMessage)
    .max(MAX_LENGTH, maxLengthValidationMessage)
    .notRequired(notRequiredValidationMessage),
  address: yup
    .string(stringValidationMessage)
    .max(MAX_ADDRESS_LENGTH, maxLengthValidationMessage)
    .notRequired(notRequiredValidationMessage),
  nationality: yup
    .string(stringValidationMessage)
    .max(MAX_LENGTH, maxLengthValidationMessage)
    .notRequired(notRequiredValidationMessage),
  township: yup
    .string(stringValidationMessage)
    .max(MAX_LENGTH, maxLengthValidationMessage)
    .notRequired(notRequiredValidationMessage),
  remarks: yup
    .string(stringValidationMessage)
    .max(LONG_MAX_LENGTH, maxLengthValidationMessage)
    .notRequired(notRequiredValidationMessage),
  employer: yup
    .string(stringValidationMessage)
    .max(MAX_LENGTH, maxLengthValidationMessage)
    .notRequired(notRequiredValidationMessage),
  dateOfBirth: yup
    .date(stringValidationMessage)
    .notRequired(notRequiredValidationMessage),
});

const StudentCreateForm = ({ student, onSubmit, onCancel }) => {
  const initialValues = useMemo(
    () => ({
      name: student?.name ?? "",
      email: student?.email ?? "",
      phoneNumber: student?.phoneNumber ?? "",
      address: student?.address ?? "",
      nationality: student?.nationality ?? "",
      township: student?.township ?? "",
      remarks: student?.remarks ?? "",
      quickNote: student?.quickNote ?? "",
      employer: student?.employer ?? "",
      dateOfBirth: student?.dateOfBirth
        ? moment(student.dateOfBirth).format("yyyy-MM-DD")
        : "",
    }),
    [student]
  );

  const {
    values,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  return (
    <form onSubmit={handleSubmit} noValidate>
      <FormSectionTitle>Persoonlijke gegevens</FormSectionTitle>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormField
            label="Naam"
            name="name"
            value={values.name}
            error={errors.name}
            onInput={handleChange}
            isDisabled={isSubmitting}
            required={true}
          />
        </Grid>

        <Grid item xs={12}>
          <FormField
            label="E-mailadres"
            name="email"
            value={values.email}
            error={errors.email}
            onInput={handleChange}
            isDisabled={isSubmitting}
            required={true}
          />
        </Grid>

        <Grid item xs={12}>
          <FormField
            label="Telefoonnummer"
            name="phoneNumber"
            value={values.phoneNumber ?? ""}
            error={errors.phoneNumber}
            onInput={handleChange}
            isDisabled={isSubmitting}
          />
        </Grid>

        <Grid item xs={12}>
          <FormField
            label="Adres"
            name="address"
            value={values.address}
            error={errors.address}
            onInput={handleChange}
            isDisabled={isSubmitting}
            multiline
          />
        </Grid>

        <Grid item xs={12}>
          <FormField
            label="Gemeente"
            name="township"
            value={values.township}
            error={errors.township}
            onInput={handleChange}
            isDisabled={isSubmitting}
          />
        </Grid>

        <Grid item xs={12}>
          <FormField
            label="Nationaliteit"
            name="nationality"
            value={values.nationality}
            error={errors.nationality}
            onInput={handleChange}
            isDisabled={isSubmitting}
          />
        </Grid>

        <Grid item xs={12}>
          <FormField
            type="date"
            label="Geboortedatum"
            name="dateOfBirth"
            value={values.dateOfBirth}
            error={errors.dateOfBirth}
            onInput={handleChange}
            isDisabled={isSubmitting}
            inputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <FormField
            label="Opmerkingen"
            name="remarks"
            value={values.remarks}
            error={errors.remarks}
            onInput={handleChange}
            isDisabled={isSubmitting}
          />
        </Grid>

        <Grid item xs={12}>
          <FormField
            label="Werkgever"
            name="employer"
            value={values.employer}
            error={errors.employer}
            onInput={handleChange}
            isDisabled={isSubmitting}
          />
        </Grid>

        <Grid item xs={12}>
          <SpaceBetweenBox>
            <Button disabled={isSubmitting} onClick={onCancel}>
              Annuleren
            </Button>

            {!student ? (
              <div>
                <LoadingButton
                  type="submit"
                  onClick={() => {
                    setFieldValue("redirectToEntity", false);
                  }}
                  disabled={isSubmitting}
                >
                  Opslaan + sluiten
                </LoadingButton>

                <Button
                  type="submit"
                  onClick={() => {
                    setFieldValue("redirectToEntity", true);
                  }}
                  disabled={isSubmitting}
                >
                  Opslaan + openen
                </Button>
              </div>
            ) : (
              <LoadingButton type="submit" disabled={isSubmitting}>
                Opslaan
              </LoadingButton>
            )}
          </SpaceBetweenBox>
        </Grid>
      </Grid>
    </form>
  );
};

export default StudentCreateForm;
