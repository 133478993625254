import { Grid, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { useState } from "react";
import { Fragment } from "react";
import SectionTitle from "../../../Typography/SectionTitle";
import LoadingSpinner from "../../../ui/loaders/LoadingSpinner";
import AttachmentCard from "../../../ui/cards/AttachmentCard";
import { request } from "../../../../api/requests";
import usePagination from "../../../../hooks/usePagination";
import FileDownload from "js-file-download";
import useFiles from "../../../../hooks/useFiles";

const FileList = () => {
  const { files, deleteFile } = useFiles();

  const [page, setPage] = useState(1);

  const { items, pages } = usePagination({
    page,
    items: files,
    size: 18,
  });

  const removeHandler = async (file) => {
    deleteFile(file, () => {
      if (pages === page && items.length <= 1 && page !== 1) setPage(pages - 1);
    });
  };

  const downloadHandler = async (file) => {
    const [response, error] = await request({
      method: "GET",
      url: `/api/v1/users/${file.user.id}/files/${file.id}`,
      responseType: "blob",
    });

    if (!error) {
      // Get the filename by splitting the content-disposition header.
      let filename =
        response.headers["content-disposition"]
          ?.split("filename=")[1]
          ?.split(";")[0] ?? "file.data";

      FileDownload(response?.data, filename, "application/octet-stream");
    }
  };

  return (
    <Fragment>
      <SectionTitle>Bestanden</SectionTitle>

      {files ? (
        <Fragment>
          {files.length > 0 ? (
            <Grid container spacing={3}>
              {items.map((file) => (
                <Grid item xs={12} md={6} lg={4} key={file.id}>
                  <AttachmentCard
                    attachment={file}
                    onRemove={removeHandler}
                    onDownload={downloadHandler}
                  />
                </Grid>
              ))}

              <Grid item xs={12}>
                <Pagination
                  count={pages}
                  onChange={(e, page) => setPage(page)}
                />
              </Grid>
            </Grid>
          ) : (
            <Typography>Geen bestanden gevonden.</Typography>
          )}
        </Fragment>
      ) : (
        <LoadingSpinner />
      )}
    </Fragment>
  );
};

export default FileList;
