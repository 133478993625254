import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";

// Initial user state
const initialState = {
  user: null,
  token: null,
  twofactor: null,
};

const userPersistConfig = {
  key: "authentication",
  storage,
  blacklist: ["user"],
};

// User reducer
function userReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_TOKEN":
      return {
        ...state,
        token: action.payload,
      };
    case "TWOFACTOR":
      return {
        ...state,
        twofactor: action.payload
      }
    case "SET_USER":
      return { ...state, user: action.payload };
    case "SIGNOUT":
      return initialState;
    default:
      return state;
  }
}

const persistedUserReducer = persistReducer(userPersistConfig, userReducer);

export default persistedUserReducer;
