import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  legendListItem: {
    display: "flex",
    columnGap: ".5rem",
    MozColumnGap: ".5em",
    WebkitColumnGap: ".5rem",
    alignItems: "center",
    fontSize: "1.05rem",
    "&:not(:last-child)": {
      marginBottom: ".3rem",
    },
  },
  indicator: {
    width: "15px",
    height: "15px",
    borderRadius: "100%",
  },
}));

const LegendListItem = ({ children, color }) => {
  const classes = useStyles();

  return (
    <li className={clsx(classes.legendListItem)}>
      <div
        className={clsx(classes.indicator)}
        style={{
          backgroundColor: color,
        }}
      />
      {children}
    </li>
  );
};

export default LegendListItem;
