import axios from "axios";
import { store } from "../store";
import { toast } from "react-toastify";
import { history } from "../router";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use((config) => {
  const token = store.getState().auth.token;

  if (token) config.headers["authorization"] = `Bearer ${token}`;

  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response.status;

    if (status === 401) {
      toast.error("U bent uitgelogd, omdat de sessie voorbij is.");
      store.dispatch({ type: "SIGNOUT" });
    } else if (status === 403) {
      history.push("/forbidden");
    } else if (status === 404) {
      history.push("/not-found");
    } else if (status === 500) {
      toast.error(
        "Ohh er ging iets fout... Probeer het later opnieuw of neem contact op met de klantenservice"
      );
    }

    return Promise.reject(error);
  }
);
