import { useMemo } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  emailValidationMessage,
  maxLengthValidationMessage,
  notRequiredValidationMessage,
  requiredValidationMessage,
  stringValidationMessage,
} from "../application/yup/validation";
import { LONG_MAX_LENGTH, MAX_LENGTH } from "../application/constants";

const validationSchema = yup.object().shape({
  name: yup
    .string(stringValidationMessage)
    .max(MAX_LENGTH, maxLengthValidationMessage)
    .required(requiredValidationMessage),
  email: yup
    .string(stringValidationMessage)
    .email(emailValidationMessage)
    .max(MAX_LENGTH, maxLengthValidationMessage)
    .required(requiredValidationMessage),
  phoneNumber: yup
    .string(stringValidationMessage)
    .max(MAX_LENGTH, maxLengthValidationMessage)
    .notRequired(notRequiredValidationMessage),
  comments: yup
    .string(stringValidationMessage)
    .max(LONG_MAX_LENGTH, maxLengthValidationMessage)
    .notRequired(notRequiredValidationMessage),
});

const useEmployeeForm = (employee, onSubmit) => {
  const initialValues = useMemo(
    () => ({
      name: employee?.name ?? "",
      email: employee?.email ?? "",
      phoneNumber: employee?.phoneNumber ?? "",
      comments: employee?.comments ?? "",
    }),
    [employee]
  );

  return useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });
};

export default useEmployeeForm;
