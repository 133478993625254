import {
  SpeedDial as MUISpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from "@material-ui/lab";
import FabBox from "../FabBox";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  speedDial: {
    "& .MuiSpeedDialAction-staticTooltipLabel": {
      width: "max-content",
    },
  },
}));

const SpeedDial = ({
  actions = [],
  ariaLabel = "Speeddial",
  isDialOpen,
  setIsDialOpen,
}) => {
  const classes = useStyles();

  return (
    <FabBox>
      <MUISpeedDial
        icon={<SpeedDialIcon />}
        ariaLabel={ariaLabel}
        onOpen={() => setIsDialOpen(true)}
        onClose={() => setIsDialOpen(false)}
        open={isDialOpen}
        className={clsx(classes.speedDial)}
      >
        {actions.map((action, index) => (
          <SpeedDialAction {...action} tooltipOpen key={index} />
        ))}
      </MUISpeedDial>
    </FabBox>
  );
};

export default SpeedDial;
