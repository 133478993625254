import { Button, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";
import ProcedureTimelineItem from "./ProcedureTimelineItem";
import * as _ from "lodash";
import APPOINTMENT_TYPES from "../../../../application/appointmentTypes";
import AppointmentCreateModal from "./appointments/AppointmentCreateModal";
import ConfirmationModal from "../../../ui/modals/ConfirmationModal";
import { Fragment } from "react";
import { procedureCommands } from "../../../../api/students/procedures";
import COMMAND_TYPES from "../../../../application/commandTypes";
import useProcedure from "../../../../hooks/useProcedure";

const ProcedureStepThree = () => {
  const { procedure, setProcedure } = useProcedure();

  const [intakes, setIntakes] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  useEffect(() => {
    setIntakes(
      _.filter(procedure.appointments, ["type", APPOINTMENT_TYPES.INTAKE])
    );
  }, [procedure.appointments]);

  const hasIntakes = () => intakes.length > 0;

  const confirmationHandler = async () => {
    const updated = await procedureCommands(procedure.id, {
      command: COMMAND_TYPES.INTAKES_DONE,
    });

    if (updated) {
      setProcedure({ ...procedure, hadIntakes: true });

      confirmationModalHandler();
    }
  };

  const confirmationModalHandler = () =>
    setIsConfirmationOpen(!isConfirmationOpen);

  return (
    <ProcedureTimelineItem completed={procedure.hadIntakes}>
      <Typography variant="h6" component="h2" gutterBottom>
        Traject starten
      </Typography>

      <Typography gutterBottom>Plan een intake in met de kandidaat.</Typography>

      {!procedure.hadIntakes && procedure.hadInterviews && (
        <div>
          <Button onClick={() => setIsOpen(true)}>
            {hasIntakes() ? "Extra intake inplannen" : "Intake inplannen"}
          </Button>

          {hasIntakes() && (
            <Fragment>
              <Button onClick={confirmationModalHandler}>
                Intakes afronden
              </Button>

              <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={confirmationModalHandler}
                message="Zijn de intakegesprekken afgerond? Het is namelijk niet meer mogelijk om dit terug te draaien."
                onConfirm={confirmationHandler}
              />
            </Fragment>
          )}

          <AppointmentCreateModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            type={APPOINTMENT_TYPES.INTAKE}
          />
        </div>
      )}
    </ProcedureTimelineItem>
  );
};

export default ProcedureStepThree;
