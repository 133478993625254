import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: "280px",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    padding: "0 16px",
  },
  toolbar: theme.mixins.toolbar,
}));

const SidebarOrganisation = ({ organisation }) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      alignItems="center"
      className={clsx(classes.toolbar, classes.drawer)}
    >
      <Typography variant="h6" component="h2">
        {organisation}
      </Typography>
    </Box>
  );
};

export default SidebarOrganisation;
