import { Grid } from "@material-ui/core";
import EmployeeCommentaryBox from "../../../components/pages/employees/EmployeeCommentaryBox";
import UserInfo from "../../../components/UserInfo";
import SectionTitle from "../../../components/Typography/SectionTitle";
import useEmployee from "../../../hooks/useEmployee";

const EmployeePersonalInfoPage = () => {
  const { employee } = useEmployee();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <SectionTitle>Persoonlijke gegevens</SectionTitle>

        <UserInfo user={employee} />
      </Grid>

      <Grid item xs={12} md={6}>
        <EmployeeCommentaryBox />
      </Grid>
    </Grid>
  );
};

export default EmployeePersonalInfoPage;
