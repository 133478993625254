import InformationList from "../../ui/lists/InformationList";
import InformationListItem from "../../ui/lists/InformationListItem";
import { Box } from "@material-ui/core";

const OrganisationInfo = ({ organisation }) => {
  const address = organisation?.address;

  return (
    <Box maxWidth={500}>
      <InformationList>
        <InformationListItem title="Bedrijfsnaam">
          {organisation?.name}
        </InformationListItem>
        <InformationListItem title="Adres">{address}</InformationListItem>
      </InformationList>
    </Box>
  );
};

export default OrganisationInfo;
