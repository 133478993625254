import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  ThemeProvider as MuiThemeProvider,
  CssBaseline,
  createTheme,
} from "@material-ui/core";
import { ThemeProvider as Mui2ThemeProvider } from "@mui/material/styles";

const ThemeProvider = ({ children }) => {
  const { darkMode } = useSelector((store) => store.theme);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main: "#A45EE5",
          },
          secondary: {
            main: "#212121",
          },
          type: darkMode ? "dark" : "light",
        },
      }),
    [darkMode]
  );

  return (
    <MuiThemeProvider theme={theme}>
      <Mui2ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </Mui2ThemeProvider>
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
