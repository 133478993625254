import { List } from "@material-ui/core";
import Modal from "../../../../ui/modals/Modal";
import { useEffect } from "react";
import { useState } from "react";
import _ from "lodash";
import AppointmentListItem from "./AppointmentListItem";
import { patchAppointment } from "../../../../../api/students/procedures/appointments";
import { toast } from "react-toastify";
import useProcedure from "../../../../../hooks/useProcedure";

const AppointmentListModal = () => {
  const {
    isAppointmentsOpen,
    setIsAppointmentsOpen,
    procedure,
    setAppointments,
  } = useProcedure();

  const [sortedAppointments, setSortedAppointments] = useState([]);

  useEffect(() => {
    setSortedAppointments(
      _.orderBy(procedure?.appointments, ["startAt"], ["desc"])
    );
  }, [procedure?.appointments]);

  const cancelHandler = async (id) => {
    const errors = await patchAppointment(procedure.id, id);

    if (!errors) {
      toast.success("Afspraak geannuleerd.");

      let index = _.findIndex(procedure?.appointments ?? [], ["id", id]);

      const mutatedAppointments = [...(procedure?.appointments ?? [])];

      mutatedAppointments.splice(index, 1, {
        ...procedure?.appointments[index],
        cancelled: true,
      });

      setAppointments(mutatedAppointments);
    }
  };

  return (
    <Modal
      isOpen={isAppointmentsOpen}
      onClose={() => setIsAppointmentsOpen(false)}
    >
      <List>
        {sortedAppointments.map((appointment, index) => (
          <AppointmentListItem
            key={`appointment-${index}`}
            appointment={appointment}
            onCancel={cancelHandler}
          />
        ))}
      </List>
    </Modal>
  );
};

export default AppointmentListModal;
