import AppointmentListModal from "../../../../components/pages/students/procedure/appointments/AppointmentListModal";
import ProcedureEditModal from "../../../../components/pages/students/procedure/ProcedureEditModal";
import ProcedureHeader from "../../../../components/pages/students/procedure/ProcedureHeader";
import ProcedureTimeLine from "../../../../components/pages/students/procedure/ProcedureTimeline";
import ProcedureProvider from "../../../../providers/ProcedureProvider";

const StudentSingleProgressPage = () => {
  return (
    <ProcedureProvider>
      <ProcedureHeader />

      <ProcedureTimeLine />

      <ProcedureEditModal />
      <AppointmentListModal />
    </ProcedureProvider>
  );
};

export default StudentSingleProgressPage;
