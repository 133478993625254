import { request } from "./requests";
import { history } from "../router";
import { toast } from "react-toastify";
import { apiErrorsToObject } from "../application/helpers";

export const getOrganisation = async (id) => {
  const [response, error] = await request({
    method: "GET",
    url: `/api/v1/organisations/${id}`,
  });

  if (!error) return response?.data?.organisation;
};

export const postOrganisation = async (data) => {
  const [response, error] = await request({
    method: "POST",
    url: `/api/v1/organisations/`,
    data,
  });

  if (error) {
    const errors = apiErrorsToObject(error?.response?.data?.errors ?? {});

    return [null, errors];
  }

  toast.success("Organisatie toegevoegd.");
  const organisation = response?.data?.organisation;

  return [organisation, null];
};

export const patchOrganisation = async (id, data) => {
  const [, error] = await request({
    method: "PATCH",
    url: `/api/v1/organisations/${id}`,
    data,
  });

  if (error) {
    const errors = apiErrorsToObject(error?.response?.data?.errors ?? {});

    return errors;
  }

  toast.success("Organisatie bijgewerkt.");

  return null;
};

export const fetchOrganisationAppointmentsData = async (id) => {
  const [response, error] = await request({
    method: "GET",
    url: `/api/v1/organisations/${id}/numbers/appointments`,
  });

  if (error) return {};

  return response.data;
};

export const fetchOrganisationStudentsData = async (id) => {
  const [response, error] = await request({
    method: "GET",
    url: `/api/v1/organisations/${id}/numbers/students`,
  });

  if (error) return {};

  return response.data;
};

export const fetchOrganisationVolunteerData = async (id) => {
  const [response, error] = await request({
    method: "GET",
    url: `/api/v1/organisations/${id}/numbers/volunteers`,
  });

  if (error) return {};

  return response.data;
};

export const deleteOrganisation = async (id) => {
  const [, error] = await request({
    method: "DELETE",
    url: `/api/v1/organisations/${id}`,
  });

  if (!error) {
    toast.success("Organisatie verwijderd.");
    history.push(`/organisaties`);
  }
};
