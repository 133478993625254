import { request } from "./requests";

export const signIn = async (email, password, otp) => {
  return await request({
    method: "post",
    url: `/api/v1/authentication/signin`,
    data: {
      email,
      password,
      otp,

    },
  });
};
