import { useState } from "react";
import { Route, useRouteMatch } from "react-router";
import useStudent from "../../../hooks/useStudent";
import SideMenuLayout from "../../ui/menus/SideMenuLayout";
import DeletionModal from "../../ui/modals/DeletionModal";
import SpeedDial from "../../ui/SpeedDial";
import FileUploadModal from "../shared/files/FileUploadModal";
import NoteCreateModal from "../shared/notes/NoteCreateModal";
import ReportCreateModal from "./reports/ReportCreateModal";
import StudentAssignVolunteerModal from "./StudentAssignVolunteerModal";
import StudentEditModal from "./StudentEditModal";
import {
  Edit as EditIcon,
  FileCopy as FileCopyIcon,
  Save as SaveIcon,
  Share as ShareIcon,
  Favorite as FavoriteIcon,
} from "@material-ui/icons";
import SectionTitle from "../../Typography/SectionTitle";
import { RouterSwitch } from "../../";

const StudentRouter = ({ routes }) => {
  const { url, path: basePath } = useRouteMatch();

  const [isSpeedDialOpen, setSpeedDialOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [isCreateNoteOpen, setIsCreateNoteOpen] = useState(false);
  const [isStudentReportOpen, setIsStudentReportOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const {
    student,
    setStudent,
    fileUploadedHandler,
    isAssignVolunteerOpen,
    setIsAssignVolunteerOpen,
    deleteHandler,
    assignVolunteer,
    noteCreatedHandler,
  } = useStudent();

  const menu = [
    { label: "Persoonlijke gegevens", to: `${url}` },
    { label: "Progressie", to: `${url}/progressie` },
    { label: "Notities", to: `${url}/notities` },
    { label: "Verslagen", to: `${url}/verslagen` },
    { label: "Bestanden", to: `${url}/bestanden` },
  ];

  const actions = [
    {
      icon: <EditIcon />,
      tooltipTitle: "Bijwerken",
      onClick: () => setIsEditOpen(true),
    },
    {
      icon: <FileCopyIcon />,
      tooltipTitle: "Bestanden uploaden",
      onClick: () => setIsUploadOpen(true),
    },
    {
      icon: <SaveIcon />,
      tooltipTitle: "Notitie toevoegen",
      onClick: () => setIsCreateNoteOpen(true),
    },
    {
      icon: <ShareIcon />,
      tooltipTitle: "Verslag vastleggen",
      onClick: () => setIsStudentReportOpen(true),
    },
    {
      icon: <FavoriteIcon />,
      tooltipTitle: "Vrijwilliger toewijzen",
      onClick: () => setIsAssignVolunteerOpen(true),
    },
    {
      icon: <FavoriteIcon />,
      tooltipTitle: "Verwijderen",
      onClick: () => setIsDeleteOpen(true),
    },
  ];

  return (
    <>
      <SectionTitle>{student.name}</SectionTitle>
      <SideMenuLayout items={menu}>
        <RouterSwitch>
          {routes.map(({ path, component }, index) => (
            <Route
              component={component}
              path={`${basePath}/${path}`}
              exact
              key={index}
            />
          ))}
        </RouterSwitch>

        <SpeedDial
          actions={actions}
          ariaLabel="Cursist acties"
          isDialOpen={isSpeedDialOpen}
          setIsDialOpen={setSpeedDialOpen}
        />

        <StudentEditModal
          isOpen={isEditOpen}
          onClose={() => setIsEditOpen(false)}
          student={student}
          setStudent={setStudent}
        />

        <FileUploadModal
          isOpen={isUploadOpen}
          onClose={() => setIsUploadOpen(false)}
          onUploaded={fileUploadedHandler}
        />

        <StudentAssignVolunteerModal
          isOpen={isAssignVolunteerOpen}
          onClose={() => setIsAssignVolunteerOpen(false)}
          assignVolunteer={assignVolunteer}
          currentVolunteer={student?.volunteer}
        />

        <NoteCreateModal
          isOpen={isCreateNoteOpen}
          onClose={() => setIsCreateNoteOpen(false)}
          onCreated={noteCreatedHandler}
        />

        <ReportCreateModal
          isOpen={isStudentReportOpen}
          onClose={() => setIsStudentReportOpen(false)}
          student={student}
        />

        <DeletionModal
          isOpen={isDeleteOpen}
          onClose={() => setIsDeleteOpen(false)}
          deleteHandler={deleteHandler}
        />
      </SideMenuLayout>
    </>
  );
};

export default StudentRouter;
