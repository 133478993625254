import { toast } from "react-toastify";
import { apiErrorsToObject } from "../../application/helpers";
import { request } from "../requests";

export const getStudent = async (id) => {
  const [response, error] = await request({
    method: "GET",
    url: `/api/v1/students/${id}`,
  });

  if (!error) return response?.data?.student;
};

export const postStudent = async (id, data) => {
  const [response, error] = await request({
    method: "POST",
    url: `/api/v1/organisations/${id}/students`,
    data,
  });

  if (error) {
    const errors = apiErrorsToObject(error?.response?.data?.errors ?? {});

    return [null, errors];
  }

  toast.success("Cursist toegevoegd.");
  const student = response?.data?.student;

  return [student, null];
};

export const patchStudent = async (id, data) => {
  const [, error] = await request({
    method: "PATCH",
    url: `/api/v1/students/${id}`,
    data,
  });

  if (error) {
    const errors = apiErrorsToObject(error?.response?.data?.errors ?? {});

    return errors;
  }

  toast.success("Cursist bijgewerkt.");

  return null;
};

export const assignVolunteerToStudent = async (id, volunteer) => {
  const volunteerId = volunteer?.id ?? "";

  const [, error] = await request({
    method: "PATCH",
    url: `/api/v1/students/${id}/volunteer/${volunteerId}`,
  });

  if (error) return error;

  toast.success(
    volunteer
      ? "Vrijwilliger is toegewezen aan cursist."
      : "Vrijwillger is van cursist weggehaald."
  );

  return null;
};
