import EmployeeProvider from "../../../providers/EmployeeProvider";
import FilesProvider from "../../../providers/FilesProvider";
import EmployeeRouter from "../../../components/pages/employees/EmployeeRouter";

const ShowEmployeePage = ({ children }) => {
  return (
    <EmployeeProvider>
      <FilesProvider>
        <EmployeeRouter routes={children} />
      </FilesProvider>
    </EmployeeProvider>
  );
};

export default ShowEmployeePage;
