export const STATUS_TYPES = {
  STARTED: 0,
  INTERVIEW_PLANNED: 1,
  INTAKE_TO_BE_PLANNED: 2,
  INTAKE_PLANNED: 3,
  DATA_FASE: 4,
  EVALUATION_TO_BE_PLANNED: 5,
  EVALUATION_PLANNED: 6,
  PROCEDURE_TO_BE_FINISHED: 7,
  PROCEDURE_FINISHED: 8,
  FINISHED_EARLY: 9,
};

export const GetStatusName = (type) => {
  switch (type) {
    case STATUS_TYPES.INTERVIEW_PLANNED:
      return "Geplande kennismakingsgesprek";
    case STATUS_TYPES.INTAKE_TO_BE_PLANNED:
      return "Nog in te plannen intake";
    case STATUS_TYPES.INTAKE_PLANNED:
      return "Ingeplande intake";
    case STATUS_TYPES.DATA_FASE:
      return "Lopende trajecten";
    case STATUS_TYPES.EVALUATION_TO_BE_PLANNED:
      return "Nog in te plannen evaluatie";
    case STATUS_TYPES.EVALUATION_PLANNED:
      return "Ingeplande evaluatie";
    case STATUS_TYPES.PROCEDURE_TO_BE_FINISHED:
      return "Nog te sluiten trajecten";
    case STATUS_TYPES.PROCEDURE_FINISHED:
      return "Afgeronde trajecten";
    case STATUS_TYPES.FINISHED_EARLY:
      return "Vroegtijdige beëindigde trajecten ";
    default:
      return "Gestarte trajecten";
  }
};

export default STATUS_TYPES;
