import { createContext, useCallback, useState, useEffect } from "react";
import { useRouteMatch } from "react-router";
import useStudent from "../hooks/useStudent";
import useFetch from "../hooks/useFetch";

export const ProcedureContext = createContext();

const ProcedureProvider = ({ children }) => {
  const {
    params: { progressionId },
  } = useRouteMatch();

  const { response, isLoading } = useFetch(
    `/api/v1/procedures/${progressionId}`
  );
  const [procedure, setProcedure] = useState(null);

  const { student, isAssignVolunteerOpen, setIsAssignVolunteerOpen } =
    useStudent();

  const [isEditOpen, setEditOpen] = useState(false);
  const [isAppointmentsOpen, setIsAppointmentsOpen] = useState(false);

  const setAppointments = useCallback((appointments) => {
    setProcedure((procedure) => ({
      ...procedure,
      appointments,
    }));
  }, []);

  useEffect(() => {
    setProcedure(response?.data);
  }, [progressionId, response]);

  const state = {
    student,
    isAssignVolunteerOpen,
    setIsAssignVolunteerOpen,
    procedure,
    setProcedure,
    isEditOpen,
    setEditOpen,
    isAppointmentsOpen,
    setIsAppointmentsOpen,
    setAppointments,
  };

  return (
    <ProcedureContext.Provider value={state}>
      {isLoading ? "loading..." : children}
    </ProcedureContext.Provider>
  );
};

export default ProcedureProvider;
