import { Grid, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { useState } from "react";
import usePagination from "../../../../hooks/usePagination";
import LogCard from "./LogCard";
import SectionTitle from "../../../Typography/SectionTitle";
import { Fragment } from "react";
import LoadingSpinner from "../../../ui/loaders/LoadingSpinner";
import { useEffect } from "react";
import * as _ from "lodash";

const LogList = ({ logs }) => {
  const [page, setPage] = useState(1);
  const [sortedLogs, setSortedLogs] = useState(null);

  const { items, pages } = usePagination({
    page,
    items: sortedLogs,
    size: 3,
  });

  useEffect(() => {
    setSortedLogs(_.orderBy(logs, "createdAt", "desc"));
  }, [logs]);

  return (
    <Fragment>
      <SectionTitle>Recente updates</SectionTitle>

      {logs ? (
        <Fragment>
          {logs.length > 0 ? (
            <Grid container spacing={2}>
              {items.map((item, index) => (
                <Grid item xs={12} md={6} lg={4} key={index}>
                  <LogCard log={item} />
                </Grid>
              ))}

              <Grid item xs={12}>
                <Pagination
                  page={page}
                  count={pages}
                  onChange={(e, page) => setPage(page)}
                />
              </Grid>
            </Grid>
          ) : (
            <Typography>Geen recente updates gevonden.</Typography>
          )}
        </Fragment>
      ) : (
        <LoadingSpinner />
      )}
    </Fragment>
  );
};

export default LogList;
