import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import React, { useState } from "react";

const SidebarMenuItem = ({ item }) => {
  const [isExpanded, setIsExpaned] = useState();

  if (typeof item.subItems === "object" && item.subItems.length > 0) {
    return (
      <React.Fragment>
        <ListItem button onClick={() => setIsExpaned(!isExpanded)}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText>{item.title}</ListItemText>
        </ListItem>
        <Collapse in={isExpanded}>
          <List component="div" disablePadding>
            {item.subItems.map((item, index) => (
              <SidebarMenuItem item={item} key={index} />
            ))}
          </List>
        </Collapse>
      </React.Fragment>
    );
  }

  return (
    <ListItem component={Link} to={item.url ?? "#"} button>
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText>{item.title}</ListItemText>
    </ListItem>
  );
};

export default SidebarMenuItem;
