import { createContext, useEffect, useState, useCallback } from "react";
import { useRouteMatch } from "react-router";
import { useFetch, useRequest } from "../hooks";
import _ from "lodash";
import { toast } from "react-toastify";

export const NotesContext = createContext();

const NotesProvider = ({ children }) => {
  /** Retrieve the id from the url. */
  const {
    params: { id },
  } = useRouteMatch();

  /** The api uri needed for all requests in context. */
  let apiUri = `/api/v1/users/${id}/notes`;

  /** Fetch notes from api. */
  const { response } = useFetch(apiUri);

  /** Get the api methods from the hook. */
  const { postRequest, patchRequest, deleteRequest } = useRequest();

  /** Initialize notes state. */
  const [notes, setNotes] = useState(null);

  /** Set retrieved notes from response. */
  useEffect(() => {
    setNotes(response?.data?.notes ?? []);
  }, [response]);

  /** Create a note and add note to notes list. */
  const createNote = useCallback(
    async (data, callback) => {
      // Send post request to create note.
      const [response, error] = await postRequest(apiUri, data);

      // Continue if there is no error.
      if (!error) {
        toast.success("Notitie toegevoegd.");

        // Add the note to a variable.
        const note = response?.data?.note;
        // Set the notes array.
        setNotes((notes) => [...notes, note]);
      }

      // At the end run the callback function
      if (typeof callback === "function") callback(response, error);
    },
    [postRequest, apiUri]
  );

  /** Update a note and merge it with original note. */
  const updateNote = useCallback(
    async (note, data, callback) => {
      // Send patch request to server to update note.
      const [response, error] = await patchRequest(apiUri, data);

      // If patch request was successful continue.
      if (!error) {
        // Clone notes array.
        const temporaryNotes = [...notes];

        // Search for updated note.
        const index = _.findIndex(temporaryNotes, { id: note.id });

        // Find note, remove note and insert updated note at same index.
        temporaryNotes.splice(index, 1, { ...note, ...data });

        // Show toast that updating was successful.
        toast.success("Notitie geüpdate.");
        // Set the state of the notes.
        setNotes(temporaryNotes);
      }

      // At the end run callback if there was a callback.
      if (typeof callback === "function") callback(response, error);
    },
    [notes, patchRequest, apiUri]
  );

  /** Delete a note and remove it from list. */
  const deleteNote = useCallback(
    async (note, callback) => {
      // Send delete request to server to update note.
      const [response, error] = await deleteRequest(apiUri);

      // Continue if there is no error.
      if (!error) {
        // Create temporary array with notes.
        const temporaryNotes = [...notes];

        // Search for deleted note in array.
        const index = _.findIndex(temporaryNotes, { id: note.id });

        // Find and remove note.
        temporaryNotes.splice(index, 1);

        // Show toast that deleting was successful.
        toast.success("Notitie verwijderd.");
        // Set the new array in the notes state.
        setNotes(temporaryNotes);
      }

      // Run callback at the end.
      if (typeof callback === "function") callback(response, error);
    },
    [notes, deleteRequest, apiUri]
  );

  /** The state to pass to the context. */
  const state = {
    notes,
    createNote,
    updateNote,
    deleteNote,
  };

  return (
    <NotesContext.Provider value={state}>{children}</NotesContext.Provider>
  );
};

export default NotesProvider;
