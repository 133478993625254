import { Box } from "@material-ui/core";

const FabBox = ({ children }) => (
  <Box
    position="fixed"
    right={16}
    bottom={16}
    style={{ pointerEvents: "none" }}
  >
    {children}
  </Box>
);

export default FabBox;
