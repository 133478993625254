import Modal from "../../ui/modals/Modal";
import { Box, Button, Typography } from "@material-ui/core";
import FormFile from "../../forms/FormFile";
import { useFormik } from "formik";
import { useCallback } from "react";
import * as yup from "yup";
import { objectToFormData } from "../../../application/helpers";
import { request } from "../../../api/requests";
import { useRouteMatch } from "react-router";
import { toast } from "react-toastify";

const validationSchema = yup.object().shape({
  files: yup.array().required().min(1, "Minimaal een bestand is verplicht."),
});

const StudentReportAddAttachmentModal = ({
  isOpen,
  onClose,
  addAttachments,
}) => {
  const {
    params: { id: studentId, reportId },
  } = useRouteMatch();

  const { errors, isSubmitting, handleSubmit, setFieldValue, setSubmitting } =
    useFormik({
      initialValues: {
        files: [],
      },
      onSubmit: async (values, { setErrors }) => {
        const formData = objectToFormData(values);

        const [response, error] = await request({
          method: "POST",
          url: `/api/v1/students/${studentId}/reports/${reportId}/attachments`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        });

        if (!error) {
          toast.success(`Bijlages zijn toegevoegd`);

          addAttachments(response.data);

          onClose();
        }

        setSubmitting(false);
      },
      validationSchema: validationSchema,
      validateOnChange: false,
      validateOnBlur: false,
    });

  const setFiles = useCallback(
    (files) => {
      setFieldValue("files", files);
    },
    [setFieldValue]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <Typography component="h3" variant="h6">
          Attachment toevoegen
        </Typography>

        <FormFile
          onInput={setFiles}
          error={errors.files}
          extensions=".doc,.docx,.xlsx,.pdf,.png,.jpg"
        />

        <Box display="flex" justifyContent="space-between">
          <Button onClick={onClose} disabled={isSubmitting}>
            Annuleren
          </Button>

          <Button type="submit" disabled={isSubmitting}>
            Uploaden
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default StudentReportAddAttachmentModal;
