import {
  makeStyles,
  Card as MUICard,
  CardContent as MUICardContent,
} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  card: {
    width: "100%",
    height: "100%",
  },
}));

const Card = (props) => {
  const classes = useStyles();
  const { styles } = props;

  return (
    <MUICard className={clsx(classes.card, styles)} {...props}>
      <MUICardContent>{props.children}</MUICardContent>
    </MUICard>
  );
};

export default Card;
