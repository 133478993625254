import { useState } from "react";
import { useContext } from "react";
import { useMemo } from "react";
import { toast } from "react-toastify";
import { request } from "../../../../../api/requests";
import { procedureCommands } from "../../../../../api/students/procedures";
import APPOINTMENT_TYPES from "../../../../../application/appointmentTypes";
import COMMAND_TYPES from "../../../../../application/commandTypes";
import STATUS_TYPES from "../../../../../application/statusTypes";
import { ProcedureContext } from "../../../../../providers/ProcedureProvider";
import SectionTitle from "../../../../Typography/SectionTitle";
import Modal from "../../../../ui/modals/Modal";
import AppointmentForm from "./AppointmentForm";

const AppointmentCreateModal = ({ isOpen, onClose, type }) => {
  const { student, procedure, setProcedure } = useContext(ProcedureContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const label = useMemo(() => {
    // eslint-disable-next-line default-case
    switch (type) {
      case APPOINTMENT_TYPES.INTAKE:
        return "Intakesgesprek";
      case APPOINTMENT_TYPES.INTERVIEW:
        return "Kennismakingsgesprek";
      case APPOINTMENT_TYPES.EVALUATION:
        return "Evaluatie";
    }
  }, [type]);

  const submitHandler = async (values, { setErrors }) => {
    // Set submitting on the form to true.
    setIsSubmitting(true);

    // Add the appointment.
    const [response, error] = await request({
      method: "POST",
      url: `/api/v1/procedures/${procedure.id}/appointments`,
      data: values,
    });

    // If there are errors, set the errors and return to end the function.
    if (error) {
      setErrors(error?.response?.data?.errors ?? {});
      return;
    } else {
      // Give notification that appointment is planned.
      toast.success(`${label} geplanned.`);

      // Patch the command to the server to change status.
      // Get the corresponding status and add it to the procedure if it succeeds.
      let command = null;
      let status = null;
      // eslint-disable-next-line default-case
      switch (type) {
        case APPOINTMENT_TYPES.INTERVIEW:
          command = COMMAND_TYPES.INTERVIEW_PLANNED;
          status = STATUS_TYPES.INTERVIEW_PLANNED;
          break;
        case APPOINTMENT_TYPES.INTAKE:
          command = COMMAND_TYPES.INTAKE_PLANNED;
          status = STATUS_TYPES.INTAKE_PLANNED;
          break;
        case APPOINTMENT_TYPES.EVALUATION:
          command = COMMAND_TYPES.EVALUATION_PLANNED;
          status = STATUS_TYPES.EVALUATION_PLANNED;
          break;
      }

      // Create temporary procedure.
      const updatedProcedure = {
        ...procedure,
        startedAt: !procedure.startedAt ? new Date() : procedure.startedAt,
        appointments: [
          ...(procedure?.appointments ?? []),
          response?.data?.appointment,
        ],
      };

      // Patch the command to server and add status after success.
      if (command !== null) {
        const updated = await procedureCommands(procedure.id, {
          command,
        });

        // Updated succeed at the status to the current procedure in memory.
        if (updated) updatedProcedure.status = status;
      }

      // Set the new procedure and setsubmitting to false. Close modal after.
      setProcedure(updatedProcedure);
      setIsSubmitting(false);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={!isSubmitting ? onClose : null}>
      <SectionTitle>{label} inplannen</SectionTitle>

      <AppointmentForm
        onSubmit={submitHandler}
        onCancel={onClose}
        type={type}
      />
    </Modal>
  );
};

export default AppointmentCreateModal;
