import InformationList from "./ui/lists/InformationList";
import InformationListItem from "./ui/lists/InformationListItem";
import { Switch } from "@material-ui/core";
import { Fragment } from "react";

const UserInfo = ({ user, switchHandler = null }) => (
  <InformationList>
    <InformationListItem title="Naam">{user?.name}</InformationListItem>
    {user?.address && (
      <InformationListItem title="Adres">{user?.address}</InformationListItem>
    )}
    <InformationListItem title="E-mail">{user?.email}</InformationListItem>
    <InformationListItem title="Telefoonnummer">
      {user?.phoneNumber ?? "Geen telefoonnummer toegevoegd"}
    </InformationListItem>
    {switchHandler ? (
      <InformationListItem title="Actief">
        <Switch checked={user?.active} onChange={switchHandler} />
      </InformationListItem>
    ) : (
      <Fragment />
    )}
  </InformationList>
);

export default UserInfo;
