import { Grid, Typography } from "@material-ui/core";
import { useCallback } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  fetchOrganisationAppointmentsData,
  fetchOrganisationStudentsData,
  fetchOrganisationVolunteerData,
} from "../../../../api/organisations";
import { getUser } from "../../../../store/user/selectors";
import SectionTitle from "../../../Typography/SectionTitle";
import Card from "../../../ui/cards/Card";
import LoadingSpinner from "../../../ui/loaders/LoadingSpinner";
// import AppointmentsChart from "./AppointmentsChart";
// import StudentsByStatusChart from "./StudentsByStatusChart";

const StudentDashboard = () => {
  const user = useSelector((state) => getUser(state));

  const mounted = useRef(true);

  const [data, setData] = useState(null);

  const fetch = useCallback(async () => {
    const organisationId = user?.organisation?.id;

    if (!organisationId) return;

    let appointmentsData = fetchOrganisationAppointmentsData(organisationId);
    let studentsData = fetchOrganisationStudentsData(organisationId);
    let volunteersData = fetchOrganisationVolunteerData(organisationId);

    appointmentsData = await appointmentsData;
    studentsData = await studentsData;
    volunteersData = await volunteersData;
    console.log(studentsData)
  });

fetch();

  //   if (mounted.current)
  //     setData({ ...appointmentsData, ...studentsData, ...volunteersData });
  // }, [user]);
  
  // useEffect(() => {
  //   mounted.current = true;

  //   fetch();

  //   return () => {
  //     mounted.current = false;
  //   };
  // }, [fetch]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={8}>
        <Card>
          <SectionTitle>Lopende trajecten</SectionTitle>
          { }
          {/* {data?.studentsByStatus ? (
            <StudentsByStatusChart studentsByStatus={data?.studentsByStatus} />
          ) : (
            <LoadingSpinner />
          )} */}
        </Card>
      </Grid>





    </Grid>
  );
};

export default StudentDashboard;
