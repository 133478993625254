import { Grid, Typography, Button } from "@material-ui/core";
import SectionTitle from "../../../../components/Typography/SectionTitle";
import UserInfo from "../../../../components/UserInfo";
import LogList from "../../../../components/pages/shared/logs/LogList";
import StudentInfo from "../../../../components/pages/students/StudentInfo";
import { useStudent, useLogs } from "../../../../hooks";

const StudentPersonalInfoPage = () => {
  const { student, isAssignVolunteerOpen, setIsAssignVolunteerOpen } =
    useStudent();
  const { logs } = useLogs();

  return (
    <Grid component="section" container spacing={3}>
      <Grid item xs={12} xl={6}>
        <SectionTitle>Persoonlijke gegevens</SectionTitle>

        <StudentInfo student={student} />
      </Grid>
      <Grid item xs={12} xl={6}>
        <SectionTitle>Vrijwilliger</SectionTitle>

        {student?.volunteer ? (
          <UserInfo user={student.volunteer} />
        ) : (
          <Typography gutterBottom>Geen toegewezen vrijwilliger</Typography>
        )}

        <Button
          type="button"
          variant="contained"
          onClick={() => setIsAssignVolunteerOpen(!isAssignVolunteerOpen)}
        >
          {student?.volunteer
            ? "Vrijwilliger wijzigen"
            : "Vrijwilliger toewijzen"}
        </Button>
      </Grid>

      <Grid item xs={12}>
        <LogList logs={logs} />
      </Grid>
    </Grid>
  );
};

export default StudentPersonalInfoPage;
