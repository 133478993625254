import { Button, IconButton, TextField, Toolbar } from "@material-ui/core";
import { Search as SearchIcon, Clear as ClearIcon } from "@material-ui/icons";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { useDataTable } from "../../hooks";
import {
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";

const useStyles = makeStyles(() => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  search: {
    height: "max-content",
    display: "flex",
    alignItems: "center",
    columnGap: ".75rem",
    MozColumnGap: ".75rem",
    WebkitColumnGap: ".75rem",
  },
}));

const DataTableToolbar = () => {
  const { query, setQuery, isRemoved, setIsRemoved, hasRemoved } =
    useDataTable();

  const classes = useStyles();

  return (
    <Toolbar className={clsx(classes.toolbar)}>
      <div style={{ paddingTop: ".5rem" }}>
        <div style={{ display: "block " }}>
          <GridToolbarExport
            printOptions={{
              hideToolbar: true,
              allColumns: true,
            }}
          />
          <GridToolbarColumnsButton />
        </div>
        {hasRemoved && (
          <Button onClick={() => setIsRemoved(!isRemoved)}>
            Zoek {isRemoved ? "met" : "zonder"} persoonsgegevens
          </Button>
        )}
      </div>
      <div className={clsx(classes.search)}>
        <TextField
          value={query ?? ""}
          onInput={({ target }) => setQuery(target.value)}
          placeholder="Zoeken..."
          name="q"
          InputProps={{
            startAdornment: <SearchIcon />,
            endAdornment: (
              <IconButton
                onClick={() => setQuery("")}
                style={{ visibility: !!query ? "visible" : "hidden" }}
              >
                <ClearIcon />
              </IconButton>
            ),
          }}
        />
      </div>
    </Toolbar>
  );
};

export default DataTableToolbar;
