import { Fragment } from "react";
import { CircleLoader } from "react-spinners";

const PageSuspense = ({ children, isLoading = false, component }) => {
  if (isLoading)
    return component ?? <CircleLoader color={"#A45EE5"} size={60} />;

  return <Fragment>{children}</Fragment>;
};

export default PageSuspense;
