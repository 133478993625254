import Modal from "../../../ui/modals/Modal";
import SectionTitle from "../../../Typography/SectionTitle";
import ReportForm from "./ReportForm";
import { useHistory } from "react-router";
import { postReport } from "../../../../api/students/reports";

const ReportCreateModal = ({ isOpen, onClose, student }) => {
  const history = useHistory();

  const submitHandler = async (values, { setErrors }) => {
    const [report, errors] = await postReport(student.id, values);

    if (errors) {
      setErrors(errors);
    } else {
      onClose();
      history.push(`/cursisten/${student.id}/verslagen/${report.id}`);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <SectionTitle>Verslag</SectionTitle>

      <ReportForm onSubmit={submitHandler} onCancel={onClose} />
    </Modal>
  );
};

export default ReportCreateModal;
