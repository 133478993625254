import { patchReport } from "../../../../api/students/reports";
import SectionTitle from "../../../Typography/SectionTitle";
import Modal from "../../../ui/modals/Modal";
import ReportForm from "./ReportForm";

const ReportEditModal = ({ isOpen, onClose, student, report, setReport }) => {
  const submitHandler = async (values, { setErrors }) => {
    const errors = await patchReport(student.id, report.id, values);

    if (errors) {
      setErrors(errors);
    } else {
      onClose();
      setReport({
        ...report,
        ...values,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <SectionTitle>Verslag bijwerken</SectionTitle>

      <ReportForm report={report} onSubmit={submitHandler} onCancel={onClose} />
    </Modal>
  );
};

export default ReportEditModal;
