import React, { useMemo } from "react";
import {
  AppBar as MaterialBar,
  Toolbar,
  makeStyles,
  Box,
  Paper,
  Typography,
  IconButton,
} from "@material-ui/core";
import clsx from "clsx";
import SearchField from "./SearchField";
import AppbarAccount from "./AppbarAccount";
import AppbarUpdates from "./AppbarUpdates";
import { useDispatch, useSelector } from "react-redux";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import { getToken } from "../../store/user/selectors";
import JwtService from "../../application/jwtService";

const useStyles = makeStyles((theme) => ({
  appBar: {
    width: "calc(100% - 280px)",
    "& .MuiIconButton-root": {
      color: "#fff !important",
    }, 
  },
  betaTag: {
    display: "inline-block",
    top: "10px",
  },
  betaTagText: {
    padding: ".1rem .75rem",
    display: "block",
  },
}));

const AppBar = ({ pageTitle }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector((state) => getToken(state));
  const jwtService = useMemo(() => new JwtService(token), [token]);

  return (
    <MaterialBar
      position="fixed"
      color="secondary"
      className={clsx(classes.appBar)}
    >
      <Toolbar>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box display="flex" gridColumnGap="2.5rem" alignItems="center">
            {pageTitle && (
              <Typography component="h1" variant="h6">
                {pageTitle}
              </Typography>
            )}

            {jwtService.isInRoles(["Admin", "Employee", "EmployeeAdmin"]) && (
              <SearchField />
            )}
            <Paper className={clsx(classes.betaTag)}>
              <Typography className={clsx(classes.betaTagText)}>
                {global.config.versionId.currentVersion.ID}
              </Typography>
            </Paper>
          </Box>

          <Box display="flex" position="relative" gridColumnGap={10}>
            {/* <IconButton onClick={() => dispatch({ type: "TOGGLE_DARKMODE" })}>
              <Brightness7Icon />
            </IconButton> */}
            

            <AppbarUpdates />

            <AppbarAccount />
          </Box>
        </Box>
      </Toolbar>
    </MaterialBar>
  );
};

export default AppBar;
