import Modal from "../../ui/modals/Modal";
import OrganisationForm from "./OrganisationForm";
import { patchOrganisation } from "../../../api/organisations";
import SectionTitle from "../../Typography/SectionTitle";

const OrganisationEditModal = ({
  isOpen,
  onClose,
  organisation,
  setOrganisation,
}) => {
  const submitHandler = async (values, { setErrors }) => {
    const errors = await patchOrganisation(organisation.id, values);

    if (errors) {
      setErrors(errors);
    } else {
      onClose();

      setOrganisation({
        ...organisation,
        ...values,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <SectionTitle>Klant bijwerken</SectionTitle>

      <OrganisationForm
        organisation={organisation}
        onSubmit={submitHandler}
        onCancel={onClose}
      />
    </Modal>
  );
};

export default OrganisationEditModal;
