import React from "react";
import { useSelector } from "react-redux";
import { Router as ReactRouter, Route } from "react-router-dom";
import signIn from "../pages/auth/signin";
import { Redirect } from "react-router";
import ForgotPassword from "../pages/auth/forgot-password";
import AuthRoute from "../components/AuthRoute";
import routes from "./routes";
import Default from "../layouts/Default";
import { isAuthenticated as reduxIsAuthenticated } from "../store/user/selectors";
import { RouterSwitch } from "../components";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

const Router = () => {
  const isAuthenticated = useSelector((store) => reduxIsAuthenticated(store));

  return (
    <ReactRouter history={history}>
      {!isAuthenticated ? (
        <RouterSwitch>
          <Route path="/auth/signin" exact component={signIn} />

          <Route
            path="/auth/wachtwoord-vergeten"
            exact
            component={ForgotPassword}
          />

          <Redirect to="/auth/signin" />
        </RouterSwitch>
      ) : (
        <Default>
          <RouterSwitch>
            {routes.map((route) => (
              <AuthRoute
                path={route.path}
                exact={!!route.children ? false : true}
                component={route.component}
                roles={route.roles ?? null}
                key={route.path}
                children={route.children ?? []}
              />
            ))}
          </RouterSwitch>
        </Default>
      )}
    </ReactRouter>
  );
};

export default Router;
