import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Fragment } from "react";
import { useState } from "react";
import Modal from "../../../ui/modals/Modal";
import clsx from "clsx";
import NoteForm from "./NoteForm";
import Card from "../../../ui/cards/Card";
import SectionTitle from "../../../Typography/SectionTitle";
import SpaceBetweenBox from "../../../ui/SpaceBetweenBox";
import DeletionModal from "../../../ui/modals/DeletionModal";
import moment from "moment";
import useNotes from "../../../../hooks/useNotes";

const useStyles = makeStyles(() => ({
  card: {
    height: "180px",
    cursor: "pointer",
    "& .MuiCardContent-root": {
      paddingBottom: "16px",
    },
  },
}));

const NoteCard = ({ note }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const classes = useStyles();

  const ModalHandler = () => {
    setIsOpen(!isOpen);
  };

  const { deleteNote, updateNote } = useNotes();

  const submitHandler = async (values, { setErrors }) => {
    await updateNote(note, values, (_, error) => {
      if (error) setErrors(error?.response?.data?.errors ?? {});
    });
  };

  const deleteHandler = async () => {
    await deleteNote(note, (_, error) => {
      if (!error) {
        setIsDeleteOpen(false);
        setIsOpen(false);
      }
    });
  };

  return (
    <Fragment>
      <Card
        onClick={() => setIsOpen(true)}
        styles={clsx(classes.card)}
        aria-label="Notitie bewerken"
      >
        <Typography variant="subtitle2" gutterBottom>
          {moment(note.updatedAt).format("DD-MM-yyyy")}
        </Typography>
        <Typography variant="body1">{note.description}</Typography>
      </Card>

      <Modal isOpen={isOpen} onClose={ModalHandler}>
        <SpaceBetweenBox>
          <SectionTitle>Notitie bijwerken</SectionTitle>

          <Button onClick={() => setIsDeleteOpen(true)}>Verwijderen</Button>
        </SpaceBetweenBox>

        <NoteForm
          note={note}
          onCancel={ModalHandler}
          onSubmit={submitHandler}
        />

        <DeletionModal
          isOpen={isDeleteOpen}
          onClose={() => setIsDeleteOpen(false)}
          deleteHandler={deleteHandler}
        />
      </Modal>
    </Fragment>
  );
};

export default NoteCard;
