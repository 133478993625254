import { useEffect, useState, useCallback } from "react";
import { request } from "../api/requests";
import useMounted from "./useMounted";

const useFetch = (url) => {
  const isMounted = useMounted();

  const [response, setResponse] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const fetch = useCallback(async () => {
    setLoading(true);

    const [response, error] = await request({
      url,
      method: "get",
    });

    if (isMounted.current) {
      if (error) {
        setResponse(null);
        setError(true);
      } else {
        setResponse(response);
        setError(false);
      }
      setLoading(false);
    }
  }, [isMounted, url]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    response,
    isLoading,
    isError,
  };
};

export default useFetch;
