import { useHistory } from "react-router";
import { postOrganisation } from "../../../api/organisations";
import Modal from "../../ui/modals/Modal";
import SectionTitle from "../../Typography/SectionTitle";
import OrganisationForm from "./OrganisationForm";

const OrganisationCreateModal = ({ isOpen, onClose, onCreated }) => {
  const history = useHistory();

  const submitHandler = async (values, { setErrors, setSubmitting }) => {
    const [organisation, errors] = await postOrganisation(values);

    if (errors) {
      setErrors(errors);
    } else {
      if (values?.redirectToEntity) {
        history.push(`/organisaties/${organisation.id}`);
      } else {
        onCreated();
        onClose();
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <SectionTitle>Tenant klant toevoegen</SectionTitle>

      <OrganisationForm onSubmit={submitHandler} onCancel={onClose} />
    </Modal>
  );
};

export default OrganisationCreateModal;
