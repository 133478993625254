import { Button, Grid, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import { useState } from "react";
import { procedureCommands } from "../../../../api/students/procedures";
import COMMAND_TYPES from "../../../../application/commandTypes";
import useProcedure from "../../../../hooks/useProcedure";
import FormField from "../../../forms/FormField";
import SectionTitle from "../../../Typography/SectionTitle";
import LoadingButton from "../../../ui/buttons/LoadingButton";
import ConfirmationModal from "../../../ui/modals/ConfirmationModal";
import Modal from "../../../ui/modals/Modal";
import SpaceBetweenBox from "../../../ui/SpaceBetweenBox";

const ProcedureFinalizeModal = ({ isOpen, onClose }) => {
  const { procedure, setProcedure } = useProcedure();

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const submitHandler = async (values) => {
    confirmationModalHandler();

    const updated = await procedureCommands(procedure.id, {
      command:
        !procedure.endedAt && procedure.hadEvaluation
          ? COMMAND_TYPES.PROCEDURE_DONE
          : COMMAND_TYPES.FINISH_EARLY,
      referredTo: values.referredTo,
      reason: values.reason,
    });

    if (updated) {
      setProcedure({
        ...procedure,
        hadData: true,
        hadEvaluation: true,
        hadIntakes: true,
        hadInterviews: true,
        endedAt: Date.now(),
        referredTo: values.referredTo,
        reason: values.reason,
      });

      onClose();
    }
  };

  const initialValues = {
    referredTo: "",
  };

  const { values, errors, isSubmitting, handleChange, handleSubmit } =
    useFormik({
      onSubmit: submitHandler,
      enableReinitialize: true,
      initialValues,
    });

  const confirmationModalHandler = () =>
    setIsConfirmationOpen(!isConfirmationOpen);

  return (
    <Modal isOpen={isOpen} onClose={!isSubmitting ? onClose : null}>
      <form onSubmit={handleSubmit} noValidate>
        <SectionTitle>
          {!procedure.endedAt && procedure.hadEvaluation
            ? "Traject afronden"
            : "Traject vroegtijdig beeïndigen"}
        </SectionTitle>

        <Typography gutterBottom>
          Na het afronden van het traject kan het niet meer teruggedraaid
          worden. Het is wel nog mogelijk om extra verslagen, notities en
          bestanden toe te voegen, omdat dit aan de cursist zelf wordt
          gekoppeld.
          <br />
          Hieronder is het wel nog mogelijk om een doorverwijzing mee te geven.
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              label="Doorverwijzen naar"
              value={values.referredTo}
              name="referredTo"
              error={errors.referredTo}
              isDisabled={isSubmitting}
              onInput={handleChange}
            />
          </Grid>

          {(!procedure.endedAt && procedure.hadEvaluation) || (
            <Grid item xs={12}>
              <FormField
                name="reason"
                label="Reden voor vroegtijdig beëindigen"
                value={values.reason}
                onInput={handleChange}
                isDisabled={isSubmitting}
                error={errors.reason}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <SpaceBetweenBox>
              <Button onClick={onClose} disabled={isSubmitting}>
                Annuleren
              </Button>

              <LoadingButton
                onClick={confirmationModalHandler}
                disabled={isSubmitting}
              >
                Afronden
              </LoadingButton>
            </SpaceBetweenBox>
          </Grid>
        </Grid>

        <ConfirmationModal
          isOpen={isConfirmationOpen}
          onClose={confirmationModalHandler}
          onConfirm={handleSubmit}
          message="Zeker weten het traject afronden?"
        />
      </form>
    </Modal>
  );
};

export default ProcedureFinalizeModal;
