import { useFormik } from "formik";
import { useMemo } from "react";
import * as yup from "yup";
import {
  emailValidationMessage,
  maxLengthValidationMessage,
  notRequiredValidationMessage,
  requiredValidationMessage,
  stringValidationMessage,
} from "../application/yup/validation";
import {
  MAX_LENGTH,
  MAX_ADDRESS_LENGTH,
  LONG_MAX_LENGTH,
} from "../application/constants";

const validationSchema = yup.object().shape({
  name: yup
    .string(stringValidationMessage)
    .max(MAX_LENGTH, maxLengthValidationMessage)
    .required(requiredValidationMessage),
  email: yup
    .string(stringValidationMessage)
    .max(MAX_LENGTH, maxLengthValidationMessage)
    .email(emailValidationMessage)
    .required(requiredValidationMessage),
  phoneNumber: yup
    .string(stringValidationMessage)
    .max(MAX_LENGTH, maxLengthValidationMessage)
    .notRequired(notRequiredValidationMessage),
  address: yup
    .string(stringValidationMessage)
    .max(MAX_ADDRESS_LENGTH, maxLengthValidationMessage)
    .notRequired(notRequiredValidationMessage),
  preference: yup
    .string(stringValidationMessage)
    .max(LONG_MAX_LENGTH, maxLengthValidationMessage)
    .notRequired(notRequiredValidationMessage),
});

const useVolunteerForm = (volunteer, onSubmit) => {
  const initialValues = useMemo(
    () => ({
      name: volunteer?.name ?? "",
      email: volunteer?.email ?? "",
      phoneNumber: volunteer?.phoneNumber ?? "",
      address: volunteer?.address ?? "",
      preference: volunteer?.preference ?? "",
      active: volunteer?.active ?? false,
      available: volunteer?.available ?? false,
    }),
    [volunteer]
  );

  return useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });
};

export default useVolunteerForm;
