import NoteList from "../../../../components/pages/shared/notes/NoteList";
import { useStudent } from "../../../../hooks";
import { Box, Button } from "@material-ui/core";
import SectionTitle from "../../../../components/Typography/SectionTitle";
import FormField from "../../../../components/forms/FormField";
import { useFormik } from "formik";

const StudentNotesPage = () => {
  const { student, updateStudent } = useStudent();

  const { values, errors, isSubmitting, handleChange, handleSubmit } =
    useFormik({
      initialValues: { ...student },
      onSubmit: async (values) => {
        await updateStudent(values);
      },
      enableReinitialize: true,
    });

  return (
    <>
      <Box mb={2}>
        <form onSubmit={handleSubmit}>
          <SectionTitle>Snelle notitie</SectionTitle>

          <Box mb={2}>
            <FormField
              name="quickNote"
              value={values.quickNote ?? ""}
              onInput={handleChange}
              error={errors.quickNote}
              isDisabled={isSubmitting}
              multiline
            />
          </Box>

          <Button type="submit" variant="outlined" disabled={isSubmitting}>
            Opslaan
          </Button>
        </form>
      </Box>

      <NoteList />
    </>
  );
};

export default StudentNotesPage;
