import { useMediaQuery } from "@material-ui/core";
import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import {BarLoader} from "react-spinners";

const useStyles = makeStyles(() => ({
    container: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '2rem'
    }
}));

const LayoutLoading = () => {
    const classes = useStyles();
    const LessThanSmallBreakpoint = useMediaQuery((theme) => theme.breakpoints.down("sm"))

    return (
        <div className={clsx(classes.container)}>
            <Typography component="h3" variant="h3">Een ogenblik geduld...</Typography>
            <BarLoader color={"#A45EE5"} width={LessThanSmallBreakpoint ? "90%" : "60%"} height={8} />
        </div>
    );
}

export default LayoutLoading;