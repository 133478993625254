import { Route, useRouteMatch } from "react-router";
import VolunteerEditModal from "../../../components/pages/volunteers/VolunteerEditModal";
import SpeedDial from "../../../components/ui/SpeedDial";
import SideMenuLayout from "../../../components/ui/menus/SideMenuLayout";
import NoteCreateModal from "../../../components/pages/shared/notes/NoteCreateModal";
import FileUploadModal from "../../../components/pages/shared/files/FileUploadModal";
import { RouterSwitch } from "../../../components";
import DeletionModal from "../../../components/ui/modals/DeletionModal";
import { useState } from "react";
import { Create as CreateIcon, Edit as EditIcon } from "@material-ui/icons";
import SectionTitle from "../../Typography/SectionTitle";
import useVolunteer from "../../../hooks/useVolunteer";

const VolunteerRouter = ({ routes }) => {
  /**
   * Get the current path to pass to the child routes
   * and the current url for the page sidemenu.
   */
  const { path: basePath, url } = useRouteMatch();

  const { volunteer, deleteVolunteer } = useVolunteer();

  const [isDialOpen, setIsDialOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [isNoteCreateOpen, setIsNoteCreateOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const menu = [
    { label: "Persoonlijke gegevens", to: url },
    { label: "Notities", to: `${url}/notities` },
    { label: "Bestanden", to: `${url}/bestanden` },
  ];

  const actions = [
    {
      icon: <CreateIcon />,
      tooltipTitle: "Notitie toevoegen",
      onClick: () => setIsNoteCreateOpen(true),
    },
    {
      icon: <EditIcon />,
      tooltipTitle: "Bijwerken",
      onClick: () => setIsEditOpen(true),
    },
    {
      icon: <EditIcon />,
      tooltipTitle: "Bestanden uploaden",
      onClick: () => setIsUploadOpen(true),
    },
    {
      icon: <EditIcon />,
      tooltipTitle: "Verwijderen",
      onClick: () => setIsDeleteOpen(true),
    },
  ];

  return (
    <>
      <SectionTitle>{volunteer?.name}</SectionTitle>
      <SideMenuLayout items={menu}>
        <RouterSwitch>
          {routes.map(({ path, component }, index) => (
            <Route
              path={`${basePath}/${path}`}
              component={component}
              key={index}
            />
          ))}
        </RouterSwitch>

        <VolunteerEditModal
          isOpen={isEditOpen}
          onClose={() => setIsEditOpen(false)}
        />

        <FileUploadModal
          isOpen={isUploadOpen}
          onClose={() => setIsUploadOpen(false)}
        />

        <NoteCreateModal
          isOpen={isNoteCreateOpen}
          onClose={() => setIsNoteCreateOpen(false)}
        />

        <DeletionModal
          isOpen={isDeleteOpen}
          onClose={() => setIsDeleteOpen(false)}
          deleteHandler={deleteVolunteer}
        />

        <SpeedDial
          ariaLabel="Vrijwilligers functies"
          actions={actions}
          isDialOpen={isDialOpen}
          setIsDialOpen={setIsDialOpen}
        />
      </SideMenuLayout>
    </>
  );
};

export default VolunteerRouter;
