import Modal from "../../ui/modals/Modal";
import SectionTitle from "../../Typography/SectionTitle";
import VolunteerForm from "./VolunteerForm";
import useVolunteer from "../../../hooks/useVolunteer";

const VolunteerEditModal = ({ isOpen, onClose }) => {
  const { volunteer, updateVolunteer } = useVolunteer();

  const submitHandler = async (values, { setErrors }) => {
    await updateVolunteer(values, (_, error) => {
      if (error) setErrors(error?.response?.data?.errors ?? []);
      else onClose();
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <SectionTitle>{volunteer?.name} bijwerken</SectionTitle>

      <VolunteerForm
        onSubmit={submitHandler}
        volunteer={volunteer}
        onCancel={onClose}
      />
    </Modal>
  );
};

export default VolunteerEditModal;
