import { Grid, Typography } from "@material-ui/core";
import VolunteerPreferencesBox from "../../../components/pages/volunteers/VolunteerPrefetenceBox";
import LogList from "../../../components/pages/shared/logs/LogList";
import VolunteerInfo from "../../../components/pages/volunteers/VolunteerInfo";
import { useVolunteer, useLogs } from "../../../hooks";

const PersonalInfoPage = () => {
  const { volunteer } = useVolunteer();
  const { logs } = useLogs();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={6}>
        <Typography component="h2" variant="h6" gutterBottom>
          Persoonlijke gegevens
        </Typography>

        <VolunteerInfo volunteer={volunteer} />
      </Grid>

      <Grid item xs={12} lg={6}>
        <VolunteerPreferencesBox />
      </Grid>

      <Grid item xs={12}>
        <LogList logs={logs} />
      </Grid>
    </Grid>
  );
};

export default PersonalInfoPage;
