import { makeStyles } from "@material-ui/core";
import { Button } from "@material-ui/core";
import LoadingSpinner from "../loaders/LoadingSpinner";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  loadingButton: {
    "& .MuiButton-label": {
      display: "flex",
      columnGap: ".5rem",
      MozColumnGap: ".5rem",
      WebkitColumnGap: ".5rem",
      alignItems: "center",
    },
  },
}));

const LoadingButton = (props) => {
  const { children, disabled } = props;

  const classes = useStyles();

  return (
    <Button {...props} className={clsx(classes.loadingButton)}>
      {disabled && <LoadingSpinner size={16} />}
      {children}
    </Button>
  );
};

export default LoadingButton;
