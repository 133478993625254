import DataGrid from "../../components/datagrid";
import { Button } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import UpdateCreateModal from "../../components/pages/updates/UpdateCreateModal";
import { useState } from "react";
import { useRef } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import moment from "moment";
import FloatingActionButton from "../../components/ui/FloatingActionButton";

const UpdatesPage = () => {
  const { url } = useRouteMatch();

  const ref = useRef({ current: {} });

  const [isOpen, setIsOpen] = useState(false);

  const columns = [
    {
      field: "title",
      headerName: "Titel",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Aangemaakt op",
      flex: 1,
      renderCell: ({ row }) => moment(row.createdAt).format("DD-MM-yyyy"),
    },
    {
      field: "view",
      headerName: " ",
      renderCell: ({ row }) => (
        <Button component={Link} to={`${url}/${row.id}`} variant="outlined">
          Bekijken
        </Button>
      ),
    },
  ];

  const createdHandler = () => {
    ref?.current?.refetch();
  };

  return (
    <section>
      <DataGrid apiUrl="/api/v1/appupdates" columns={columns} tableRef={ref} />

      <UpdateCreateModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onCreated={createdHandler}
      />

      <FloatingActionButton
        onClick={() => setIsOpen(true)}
        icon={<AddIcon />}
      />
    </section>
  );
};

export default UpdatesPage;
