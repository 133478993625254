import Router from "./router";
import { StoreProvider, ThemeProvider } from "./providers";
import { ToastContainer } from "react-toastify";
import DataFetcher from "./components/DataFetcher";
import "@fontsource/roboto";

function App() {
  return (
    <StoreProvider>
      <ThemeProvider>
        <DataFetcher>
          <Router />

          <ToastContainer />
        </DataFetcher>
      </ThemeProvider>
    </StoreProvider>
  );
}

export default App;
