import { Button, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import { useMemo } from "react";
import FormField from "../../forms/FormField";
import * as yup from "yup";
import { MAX_LENGTH } from "../../../application/constants";
import SpaceBetweenBox from "../../ui/SpaceBetweenBox";
import LoadingButton from "../../ui/buttons/LoadingButton";
import {
  stringValidationMessage,
  maxLengthValidationMessage,
  requiredValidationMessage,
} from "../../../application/yup/validation";

const validationSchema = yup.object().shape({
  title: yup
    .string(stringValidationMessage)
    .max(MAX_LENGTH, maxLengthValidationMessage)
    .required(requiredValidationMessage),
  description: yup
    .string(stringValidationMessage)
    .max(MAX_LENGTH, maxLengthValidationMessage)
    .required(requiredValidationMessage),
});

const UpdateForm = ({ update, onSubmit, onCancel }) => {
  const initialValues = useMemo(
    () => ({
      title: update?.title ?? "",
      description: update?.description ?? "",
    }),
    [update]
  );

  const { values, errors, isSubmitting, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      onSubmit,
      validationSchema,
      enableReinitialize: true,
    });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormField
            label="Titel"
            name="title"
            value={values.title}
            error={errors.title}
            onInput={handleChange}
            isDisabled={isSubmitting}
          />
        </Grid>

        <Grid item xs={12}>
          <FormField
            label="Beschrijving"
            name="description"
            value={values.description}
            error={errors.description}
            onInput={handleChange}
            multiline
            isDisabled={isSubmitting}
          />
        </Grid>

        <Grid item xs={12}>
          <SpaceBetweenBox>
            <Button type="button" disabled={isSubmitting} onClick={onCancel}>
              Annuleren
            </Button>

            <LoadingButton type="submit" disabled={isSubmitting}>
              Opslaan
            </LoadingButton>
          </SpaceBetweenBox>
        </Grid>
      </Grid>
    </form>
  );
};

export default UpdateForm;
