import { DataTableProvider } from "../../providers";
import DataTable from "./DataTable";

const DataGrid = ({
  columns,
  apiUrl,
  hasRemoved,
  tableRef,
  defaultSortModel = { field: "name" },
}) => {
  return (
    <DataTableProvider
      apiUrl={apiUrl}
      hasRemoved={hasRemoved}
      tableRef={tableRef}
      defaultSortModel={defaultSortModel}
    >
      <DataTable columns={columns} />
    </DataTableProvider>
  );
};

export default DataGrid;
