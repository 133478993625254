import { Box, Typography, ListItem, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: "bold",
  },
}));

const InformationListItem = ({ children, title }) => {
  const classes = useStyles();

  return (
    <ListItem divider={true}>
      <Box width={180} textAlign="right" paddingRight="2rem">
        <Typography className={clsx(classes.title)}>{title}</Typography>
      </Box>
      <Typography>{children}</Typography>
    </ListItem>
  );
};

export default InformationListItem;
