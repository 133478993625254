import { Tabs, Tab, makeStyles, Paper } from "@material-ui/core";
import { useState } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: "hidden",
  },
  tabs: {
    "& .MuiTab-wrapper": {
      alignItems: "flex-start",
      padding: "0 1rem",
      textAlign: "left",
    },
  },
}));

const SideMenu = ({ items = [] }) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);

  const tabHandler = (e, newValue) => setCurrentTab(newValue);

  return (
    <Paper className={clsx(classes.paper)}>
      <Tabs
        value={currentTab}
        onChange={tabHandler}
        variant="fullWidth"
        orientation="vertical"
        indicatorColor="primary"
        className={clsx(classes.tabs)}
      >
        {items.map((item, index) => (
          <Tab label={item.label} component={Link} to={item.to} key={index} />
        ))}
      </Tabs>
    </Paper>
  );
};

export default SideMenu;
