import { Button } from "@material-ui/core";
import { useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import DataGrid from "../../../../components/datagrid";
import { useRouteMatch } from "react-router";
import ProcedureCreateModal from "../../../../components/pages/students/procedure/ProcedureCreateModal";
import { useStudent } from "../../../../hooks";

const StudentProgressPage = () => {
  const { student } = useStudent();
  const { url } = useRouteMatch();

  const [isCreateOpen, setIsCreateOpen] = useState(false);

  const columns = useMemo(
    () => [
      {
        field: "title",
        headerName: "Titel",
        flex: 1,
      },
      {
        field: "description",
        headerName: "Beschrijving",
        flex: 1,
      },
      {
        field: "referredBy",
        headerName: "Doorverwezen vanuit",
        flex: 1,
      },
      {
        field: "referredTo",
        headerName: "Doorverwezen naar",
        flex: 1,
      },
      {
        field: "view",
        headerName: " ",
        renderCell: ({ row }) => (
          <Button component={Link} to={`${url}/${row.id}`} variant="outlined">
            Bekijken
          </Button>
        ),
      },
    ],
    [url]
  );

  const ref = useRef({ current: {} });

  return (
    <>
      <Button onClick={() => setIsCreateOpen(true)}>Traject starten</Button>

      <DataGrid
        apiUrl={`/api/v1/students/${student.id}/procedures`}
        columns={columns}
        tableRef={ref}
      />

      <ProcedureCreateModal
        isOpen={isCreateOpen}
        onClose={() => setIsCreateOpen(false)}
      />
    </>
  );
};

export default StudentProgressPage;
