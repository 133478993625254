import { List, ListItem } from "@material-ui/core";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import UpdateListItem from "./UpdateListItem";

const UpdateList = () => {
  const updates = useSelector((state) => state.updates.items);

  return (
    <List>
      {updates.length ? (
        <Fragment>
          {updates.map((update, index) => (
            <UpdateListItem key={index} update={update} />
          ))}
        </Fragment>
      ) : (
        <ListItem>Geen updates beschikbaar</ListItem>
      )}
    </List>
  );
};

export default UpdateList;
