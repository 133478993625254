import fileDownload from "js-file-download";
import { createContext, useCallback, useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { request } from "../api/requests";
import useFetch from "../hooks/useFetch";
import _ from "lodash";

export const FilesContext = createContext();

const FilesProvider = ({ children }) => {
  /** Retrieve the id from the url. */
  const {
    params: { id },
  } = useRouteMatch();

  /** Fetch notes from api. */
  const { response } = useFetch(`/api/v1/users/${id}/files`);

  /** Initialize files state. */
  const [files, setFiles] = useState(null);

  /** Set retrieved files from response. */
  useEffect(() => {
    setFiles(response?.data?.files);
  }, [response]);

  /** Upload a file or multiple files and add them to the array of files. */
  const uploadFiles = useCallback(
    async (data, callback) => {
      const [response, error] = await request({
        method: "POST",
        url: `/api/v1/users/${id}/files`,
        data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (!error) setFiles((files) => [...files, ...response?.data?.files]);

      if (callback instanceof Function) callback(response, error);
    },
    [id]
  );

  /** Delete a file file and remove from state. */
  const deleteFile = useCallback(
    async (file, callback) => {
      const [, error] = await request({
        method: "DELETE",
        url: `/api/v1/users/${id}/files/${file.id}`,
      });

      if (!error)
        setFiles((files) => [
          ..._.filter(files, (object) => object.id !== file.id),
        ]);

      if (callback instanceof Function) callback();
    },
    [id]
  );

  /** Download a file from the server. */
  const downloadFile = useCallback(
    async (file) => {
      const [response, error] = await request({
        method: "GET",
        url: `/api/v1/users/${id}/files/${file.id}`,
        responseType: "blob",
      });

      if (!error) {
        // Get the filename by splitting the content-disposition header.
        let filename =
          response.headers["content-disposition"]
            ?.split("filename=")[1]
            ?.split(";")[0] ?? "file.data";

        fileDownload(response?.data, filename, "application/octet-stream");
      }
    },
    [id]
  );

  /** The state to pass to the context. */
  const state = { files, uploadFiles, deleteFile, downloadFile };

  return (
    <FilesContext.Provider value={state}>{children}</FilesContext.Provider>
  );
};

export default FilesProvider;
