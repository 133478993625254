import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getToken } from "../store/user/selectors";
import JwtService from "../application/jwtService";
import { Fragment } from "react";
import EmployeeDashboard from "../components/pages/dashboard/employee/EmployeeDashboard";
import StudentDashboard from "../components/pages/dashboard/student/StudentDashboard";
import VolunteerDashboard from "../components/pages/dashboard/volunteer/VolunteerDashboard";
import DashboardActions from "../components/pages/dashboard/DashboardActions";

const Home = () => {
  const token = useSelector((state) => getToken(state));
  const jwtService = useMemo(() => new JwtService(token), [token]);

  return (
    <Fragment>
      <DashboardActions />

      {jwtService.isInRoles(["Employee", "EmployeeAdmin"]) && (
        <EmployeeDashboard />
      )}

      {jwtService.isInRoles(["Volunteer"]) && <VolunteerDashboard />}

      {jwtService.isInRoles(["Student"]) && <StudentDashboard />}
    </Fragment>
  );
};

export default Home;
