import { toast } from "react-toastify";
import { apiErrorsToObject } from "../application/helpers";
import { history } from "../router";
import { request } from "./requests";

export const getUpdate = async (id) => {
  const [response, error] = await request({
    method: "GET",
    url: `/api/v1/appupdates/${id}`,
  });

  if (!error) return response?.data?.appUpdate;
};

export const postUpdate = async (data) => {
  const [response, error] = await request({
    method: "POST",
    url: `/api/v1/appupdates`,
    data,
  });

  if (error) {
    const errors = apiErrorsToObject(error?.response?.data?.errors ?? {});

    return [null, errors];
  }

  toast.success("Update is toegevoegd.");
  const update = response?.data?.appUpdate;

  return [update, null];
};

export const patchUpdate = async (id, data) => {
  const [, error] = await request({
    method: "PATCH",
    url: `/api/v1/appupdates/${id}`,
    data,
  });

  if (error) return apiErrorsToObject(error?.response?.data?.errors ?? {});

  toast.success("Update bijgewerkt.");

  return null;
};

export const deleteUpdate = async (id) => {
  const [, error] = await request({
    method: "DELETE",
    url: `/api/v1/appupdates/${id}`,
  });

  if (!error) {
    toast.success("Update verwijderd.");
    history.push("/updates");
  }
};
