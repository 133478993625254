import { Box, List, makeStyles, Paper } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  paper: {
    padding: 0,
  },
}));

const InformationList = ({ children }) => {
  const classes = useStyles();

  return (
    <Box marginBottom="0.7rem">
      <Paper className={clsx(classes.paper)}>
        <List>{children}</List>
      </Paper>
    </Box>
  );
};

export default InformationList;
