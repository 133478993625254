import { Button, Typography } from "@material-ui/core";
import useProcedure from "../../../../hooks/useProcedure";
import ProcedureTimelineItem from "./ProcedureTimelineItem";

const ProcedureStepOne = () => {
  const { procedure, setEditOpen } = useProcedure();

  return (
    <ProcedureTimelineItem completed>
      <Typography variant="h6" component="h2">
        {procedure.title}
      </Typography>

      <Typography gutterBottom>{procedure.description}</Typography>

      <Typography gutterBottom>
        {procedure.referredBy
          ? `De cursist is doorverwezen vanuit ${procedure.referredBy}`
          : "De cursist is niet doorverwezen vanuit een andere organisatie."}
      </Typography>

      <Typography></Typography>

      <Button onClick={() => setEditOpen(true)}>Traject bijwerken</Button>
    </ProcedureTimelineItem>
  );
};

export default ProcedureStepOne;
