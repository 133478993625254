import { Box } from "@material-ui/core";

const SpaceBetweenBox = ({ children }) => (
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    width="100%"
  >
    {children}
  </Box>
);

export default SpaceBetweenBox;
