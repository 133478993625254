import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./plugins/axios";
import "./assets/general.css";
import "react-toastify/dist/ReactToastify.css";
import "nprogress/nprogress.css";
import './config';

import { LicenseInfo } from "@mui/x-data-grid-pro";

LicenseInfo.setLicenseKey(
  "0934ae7d561a4ec775c2c84a0614f2c3T1JERVI6NDExMDQsRVhQSVJZPTE2ODA2OTQ0NzAwMDAsS0VZVkVSU0lPTj0x"
);

ReactDOM.render(<App />, document.getElementById("root"));
