import Modal from "../../ui/modals/Modal";
import SectionTitle from "../../Typography/SectionTitle";
import UpdateForm from "./UpdateForm";
import { patchUpdate } from "../../../api/updates";

const UpdateEditModal = ({ update, setUpdate, isOpen, onClose }) => {
  const submitHandler = async (values, { setErrors }) => {
    const errors = await patchUpdate(update.id, values);

    if (errors) {
      setErrors(errors);
    } else {
      onClose();

      setUpdate({
        ...update,
        ...values,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <SectionTitle>Update bijwerken</SectionTitle>

      <UpdateForm onSubmit={submitHandler} update={update} onCancel={onClose} />
    </Modal>
  );
};

export default UpdateEditModal;
