import React from "react";
import {
  StudentProvider,
  LogsProvider,
  FilesProvider,
  NotesProvider,
} from "../../../providers";
import StudentRouter from "../../../components/pages/students/StudentRouter";

const ShowStudent = ({ children }) => {
  return (
    <StudentProvider>
      <LogsProvider>
        <FilesProvider>
          <NotesProvider>
            <StudentRouter routes={children} />
          </NotesProvider>
        </FilesProvider>
      </LogsProvider>
    </StudentProvider>
  );
};

export default ShowStudent;
