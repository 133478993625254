import React from "react";
import { Drawer, Divider } from "@material-ui/core";
import SidebarHeader from "./SidebarHeader";
import SidebarOrganisation from "./SidebarOrganisation";
import SidebarMenu from "./SidebarMenu";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const user = useSelector((state) => state.auth.user);

  return (
    <Drawer variant="permanent" anchor="left">
      <SidebarOrganisation
        organisation={"MijnTaalhuis Portal"}
      />

      <Divider />

      <SidebarHeader />

      <SidebarMenu />
    </Drawer>
  );
};

export default Sidebar;
