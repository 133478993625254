import { Box, Paper } from "@material-ui/core";
import React, { useEffect, useRef, cloneElement, useCallback } from "react";

const Dropdown = ({
  children,
  button = "button",
  isHidden,
  setIsHidden,
  width,
  left,
  right,
  top,
  bottom,
}) => {
  const element = useRef(0);

  const closeOnClickOutside = useCallback(
    (e) => {
      const { current } = element;

      if (current && !current?.contains(e.target)) setIsHidden(true);
    },
    [setIsHidden]
  );

  useEffect(() => {
    window.addEventListener("click", closeOnClickOutside);

    return () => {
      window.removeEventListener("click", closeOnClickOutside);
    };
  }, [closeOnClickOutside]);

  return (
    <Box ref={element}>
      {cloneElement(button, { onClick: () => setIsHidden(!isHidden) })}

      <Box
        position="absolute"
        hidden={isHidden}
        right={right}
        left={left}
        top={top}
        bottom={bottom}
        width={width ?? 250}
        maxHeight={300}
      >
        <Paper>{children}</Paper>
      </Box>
    </Box>
  );
};

export default Dropdown;
