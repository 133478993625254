import FileList from "../../../components/pages/shared/files/FileList";
import { useStudent } from "../../../hooks";

const StudentFilesPage = () => {
  const { files, setFiles } = useStudent();

  return <FileList files={files} setFiles={setFiles} />;
};

export default StudentFilesPage;
