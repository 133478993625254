import { useEffect, useState } from "react";

const usePagination = (object) => {
  const [pages, setPages] = useState(1);
  const [items, setItems] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    let pageSize = object?.size ?? 10;

    let amountOfItems = object?.items?.length ?? 0;
    let pages = Math.ceil(amountOfItems / pageSize) || 1;
    let page = object?.page;
    let items = [
      ...(object?.items?.slice((page - 1) * pageSize, page * pageSize) ?? []),
    ];

    setPages(pages);
    setItems(items);
    setPageSize(pageSize);
  }, [object?.items, object?.page, object?.size]);

  return { items, pages, pageSize };
};

export default usePagination;
