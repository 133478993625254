import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  CloudUpload as CloudUploadIcon,
  Attachment as AttachmentIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import { Fragment } from "react";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: "100%",
    height: "150px",
    border: "1px solid rgba(255, 255, 255, 0.3)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    transition: "box-shadow .12s ease-out",
    flexDirection: "column",
    gap: ".4rem",
    borderRadius: "4px",
    "&:not(:disabled):hover": {
      boxShadow: theme.shadows[4],
    },
  },
  disabled: {
    boxShadow: "none !important",
    color: "rgba(255, 255, 255, 0.3)",
  },
  fileList: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
  },
  fileListItem: {
    display: "flex",
    alignItems: "center",
    columnGap: ".75rem",
    MozColumnGap: ".75rem",
    WebkitColumnGap: ".75rem",
  },
  removeFileBtn: {
    padding: 0,
    backgroundColor: "transparent",
    color: theme.palette.type === "dark" ? "#fff" : "rgba(0, 0, 0, 0.87)",
    cursor: "pointer",
    border: "none",
    display: "contents",
  },
  error: {
    color: "red",
  },
}));

const FormFile = ({
  onInput,
  name,
  error,
  disabled = false,
  amount = 6,
  extensions = null,
}) => {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [fileError, setFileError] = useState(null);

  // On file upload handler
  const fileHandler = ({ target }) => {
    let combinedFiles;

    // Combine current files with newly uploaded files in an array
    // if there can be multiple files uploaded, else get the first file emit it.
    if (amount > 1) combinedFiles = [...files, ...target.files];
    else combinedFiles = [target.files[0]];

    // Check if the amount of file isn't exceeded.
    if (combinedFiles.length > amount) {
      setFileError(`Teveel bestanden geüpload, maximaal ${amount} toegestaan.`);
      return;
    }

    if (!!extensions) {
      // Check if the files have the right extensions.
      for (let file of combinedFiles) {
        // Get the extension from the fail and add period in front of it.
        let extension = "." + file.name.split(".").pop();

        // Split the extension array.
        let extensionArray = extensions.split(",");

        // Check if extension is in extensionArray.
        // If not, set file error and return.
        if (!extensionArray.includes(extension)) {
          setFileError(`Er is een ongeldig bestand geüpload.`);
          return;
        }
      }
    }

    setFileError(null);

    // Set and emit the files
    setAndEmit(combinedFiles);

    // Set the target value back to null so the application thinks the
    // element is empty again
    target.value = null;
  };

  // On file removal handler
  const removeFile = (index) => {
    // Spread current files in a temporary array
    const tempFiles = [...files];

    // Remove the file that needs to be removed with splice
    // We passed the index and remove 1 file from the index
    tempFiles.splice(index, 1);

    // Set and emit the files
    setAndEmit(tempFiles);
  };

  // Function to set and emit files in component
  const setAndEmit = (files) => {
    // Set the files in the component state for the filelist
    // and emit the current files back through the oninput function
    setFiles(files);
    onInput(files);
  };

  return (
    <Fragment>
      <label className={clsx(classes.inputField, disabled && classes.disabled)}>
        <CloudUploadIcon fontSize="large" />
        <Typography>
          Klik hier om maximaal {amount}{" "}
          {amount === 1 ? "bestand" : "bestanden"} te selecteren
          {extensions && (
            <Fragment>
              <br />
              Toegestaande bestandstypes zijn: {extensions}
            </Fragment>
          )}
        </Typography>
        <input
          type="file"
          name={name}
          onInput={fileHandler}
          hidden={true}
          multiple={amount > 1}
          disabled={disabled}
          accept={extensions}
        />
      </label>
      <span className={clsx(classes.error)}>{fileError ?? error}</span>

      {files.length > 0 && (
        <div>
          <ul className={clsx(classes.fileList)}>
            {files.map((file, index) => (
              <li className={clsx(classes.fileListItem)} key={index}>
                <AttachmentIcon />
                <span>{file.name}</span>
                <button
                  type="button"
                  className={clsx(classes.removeFileBtn)}
                  aria-label="Bestand verwijderen"
                  onClick={() => removeFile(index)}
                >
                  <CloseIcon />
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </Fragment>
  );
};

export default FormFile;
