import { List } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SidebarMenuItem from "./SidebarMenuItem";
import BusinessIcon from "@material-ui/icons/Business";
import PeopleIcon from "@material-ui/icons/People";
import UpdateIcon from "@material-ui/icons/Update";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getToken } from "../../store/user/selectors";
import JwtService from "../../application/jwtService";

const SidebarMenu = () => {
  const token = useSelector((store) => getToken(store));

  const menuItems = useMemo(() => {
    const jwtService = new JwtService(token);

    let items = [
      {
        title: "Mijn overzicht",
        icon: <DashboardIcon />,
        url: "/",
      },
    ];

    if (jwtService.isInRoles(["Admin"])) {
      items = items.concat([
        {
          title: "Tenant Beheer",
          url: "/organisaties",
          icon: <BusinessIcon />,
        },
        {
          title: "Berichtgeving",
          url: "/updates",
          icon: <UpdateIcon />,
        },
      ]);
    }

    if (jwtService.isInRoles(["Admin", "EmployeeAdmin"])) {
      items = items.concat([
        {
          title: "Alle gebruikers",
          icon: <PeopleIcon />,
          url: "/gebruikers",
        },
      ]);
    }

    if (jwtService.isInRoles(["EmployeeAdmin"])) {
      items = items.concat([
        {
          title: "Medewerkers",
          url: "/medewerkers",
          icon: <PeopleIcon />,
        },
      ]);
    }

    if (jwtService.isInRoles(["EmployeeAdmin", "Employee"])) {
      items = items.concat([
        {
          title: "Vrijwilligers",
          url: "/vrijwilligers",
          icon: <PeopleIcon />,
        },
        {
          title: "Taalvragers (Cursisten)",
          url: "/cursisten",
          icon: <PeopleIcon />,
        },
      ]);
    }

    if (jwtService.isInRoles(["Volunteer"])) {
      items = items.concat([
        {
          title: "Taalvragers (Cursisten)",
          url: "/cursisten",
          icon: <PeopleIcon />,
        },
      ]);
    }

    return items;
  }, [token]);

  return (
    <List>
      {menuItems.map((item, index) => (
        <SidebarMenuItem item={item} key={`menu-item-${index}`} />
      ))}
    </List>
  );
};

export default SidebarMenu;
