import Modal from "../../ui/modals/Modal";
import EmployeeForm from "./EmployeeForm";
import SectionTitle from "../../Typography/SectionTitle";
import { postEmployee } from "../../../api/employees";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getUser } from "../../../store/user/selectors";

const EmployeeCreateModal = ({ isOpen, onClose, onCreated }) => {
  const user = useSelector((state) => getUser(state));
  const history = useHistory();

  const submitHandler = async (values, { setErrors, setSubmitting }) => {
    const [employee, errors] = await postEmployee(user.organisation.id, values);

    if (errors) {
      setErrors(errors);
    } else {
      if (values.redirectToEntity) {
        history.push(`/medewerkers/${employee.id}`);
      } else {
        onCreated();
        onClose();
      }
    }

    setSubmitting(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <SectionTitle>Medewerker toevoegen</SectionTitle>

      <EmployeeForm onCancel={onClose} onSubmit={submitHandler} />
    </Modal>
  );
};

export default EmployeeCreateModal;
