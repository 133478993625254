import AppBar from "../components/layout/Appbar";
import Sidebar from "../components/layout/Sidebar";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { getToken, getUser } from "../store/user/selectors";
import { useEffect } from "react";
import JwtService from "../application/jwtService";
import LayoutLoading from "../components/layout/LayoutLoading";
import PageSuspense from "../components/ui/loaders/PageSuspense";
import { fetchMe } from "../api/profile";
import { useCallback } from "react";

const useStyles = makeStyles((theme) => ({
  drawerShift: {
    marginLeft: "280px",
  },
  toolbar: theme.mixins.toolbar,
  main: {
    padding: "20px",
    minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    "@media (min-width:0px) and (orientation: landscape)": {
      minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:0px) and (orientation: landscape)"]?.minHeight}px)`,
    },
    "@media (min-width:600px)": {
      minHeight: `calc(100vh - ${
        theme.mixins.toolbar["@media (min-width:600px)"]?.minHeight ?? 0
      }px)`,
    },
  },
}));

const Default = ({ children, title }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector((state) => getToken(state));
  const user = useSelector((state) => getUser(state));

  const fetchSignedInUser = useCallback(async () => {
    const [response, error] = await fetchMe();

    if (error) {
      dispatch({ type: "SET_TOKEN", payload: null });
      return;
    }

    dispatch({
      type: "SET_USER",
      payload: response.data.user,
    });
  }, [dispatch]);

  useEffect(() => {
    fetchSignedInUser();
  }, [fetchSignedInUser]);

  useEffect(() => {
    const jwtService = new JwtService(token);

    let interval = setInterval(() => {
      let expirationDateInMilliseconds = jwtService.getExpirationDate() * 1000;

      if (Date.now() >= expirationDateInMilliseconds) {
        clearInterval(interval);
        dispatch({ type: "SIGNOUT" });
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [token, dispatch]);

  return (
    <PageSuspense isLoading={!user} component={<LayoutLoading />}>
      <div id="wrapper">
        <AppBar pageTitle={title} />
        <Sidebar />

        <div className={clsx(classes.toolbar)} />
        <main
          className={clsx(classes.drawerShift, classes.toolbar, classes.main)}
        >
          {children}
        </main>
      </div>
    </PageSuspense>
  );
};

export default Default;
