import { Button, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import { useMemo } from "react";
import FormField from "../../../forms/FormField";
import SpaceBetweenBox from "../../../ui/SpaceBetweenBox";
import * as yup from "yup";
import { LONG_MAX_LENGTH } from "../../../../application/constants";
import LoadingButton from "../../../ui/buttons/LoadingButton";
import {
  maxLengthValidationMessage,
  requiredValidationMessage,
  stringValidationMessage,
} from "../../../../application/yup/validation";

const validationSchema = yup.object().shape({
  description: yup
    .string(stringValidationMessage)
    .max(LONG_MAX_LENGTH, maxLengthValidationMessage)
    .required(requiredValidationMessage),
});

const NoteForm = ({ note, onCancel, onSubmit }) => {
  const initialValues = useMemo(
    () => ({
      description: note?.description ?? "",
    }),
    [note]
  );

  const { values, errors, isSubmitting, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      onSubmit,
      validationSchema,
    });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormField
            label="Notitie"
            value={values.description}
            error={errors.description}
            name="description"
            onInput={handleChange}
            multiline
            isDisabled={isSubmitting}
          />
        </Grid>

        <Grid item xs={12}>
          <SpaceBetweenBox>
            <Button onClick={onCancel} disabled={isSubmitting}>
              Annuleren
            </Button>

            <LoadingButton type="submit" disabled={isSubmitting}>
              Opslaan
            </LoadingButton>
          </SpaceBetweenBox>
        </Grid>
      </Grid>
    </form>
  );
};

export default NoteForm;
