const APPOINTMENT_TYPES = {
  INTERVIEW: 0,
  INTAKE: 1,
  EVALUATION: 2,
};

export const GetAppointmentName = (type) => {
  switch (type) {
    case APPOINTMENT_TYPES.INTERVIEW:
      return "Kennismakingsgesprek";
    case APPOINTMENT_TYPES.INTAKE:
      return "Intakegesprek";
    case APPOINTMENT_TYPES.EVALUATION:
      return "Evaluatiegesprek";
    default:
      return "Afspraak";
  }
};

export default APPOINTMENT_TYPES;
