import InformationList from "../../ui/lists/InformationList";
import InformationListItem from "../../ui/lists/InformationListItem";
import { Check as CheckIcon, Close as CloseIcon } from "@material-ui/icons";

const VolunteerInfo = ({ volunteer }) => {
  return (
    <InformationList>
      <InformationListItem title="Naam">{volunteer?.name}</InformationListItem>
      <InformationListItem title="E-mail">
        {volunteer?.email}
      </InformationListItem>
      <InformationListItem title="Telefoonnummer">
        {volunteer?.phoneNumber ?? "Geen telefoonnummer toegevoegd"}
      </InformationListItem>
      <InformationListItem title="Beschikbaar">
        {volunteer?.available ? <CheckIcon /> : <CloseIcon />}
      </InformationListItem>
      <InformationListItem title="Actief">
        {volunteer?.active ? <CheckIcon /> : <CloseIcon />}
      </InformationListItem>
    </InformationList>
  );
};

export default VolunteerInfo;
