import { Button, Grid } from "@material-ui/core";
import LoadingButton from "../../ui/buttons/LoadingButton";
import SpaceBetweenBox from "../../ui/SpaceBetweenBox";
import FormField from "../../forms/FormField";
import useEmployeeForm from "../../../hooks/useEmployeeForm";

const EmployeeForm = ({ employee = null, onSubmit, onCancel }) => {
  const {
    values,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useEmployeeForm(employee, onSubmit);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormField
            label="Naam"
            name="name"
            value={values.name}
            error={errors.name}
            onInput={handleChange}
            isDisabled={isSubmitting}
            required
          />
        </Grid>

        <Grid item xs={12}>
          <FormField
            label="E-mail"
            name="email"
            value={values.email}
            error={errors.email}
            onInput={handleChange}
            isDisabled={isSubmitting}
            required
          />
        </Grid>

        <Grid item xs={12}>
          <FormField
            label="Telefoonnummer"
            name="phoneNumber"
            value={values.phoneNumber ?? ""}
            error={errors.phoneNumber}
            onInput={handleChange}
            isDisabled={isSubmitting}
          />
        </Grid>

        <Grid item xs={12}>
          <FormField
            label="Commentaar"
            name="comments"
            value={values.comments ?? ""}
            error={errors.comments}
            onInput={handleChange}
            isDisabled={isSubmitting}
            multiline
          />
        </Grid>

        <Grid item xs={12}>
          <SpaceBetweenBox>
            <Button disabled={isSubmitting} onClick={onCancel}>
              Annuleren
            </Button>

            {!employee ? (
              <div>
                <LoadingButton
                  type="submit"
                  onClick={() => {
                    setFieldValue("redirectToEntity", false);
                  }}
                  disabled={isSubmitting}
                >
                  Opslaan + sluiten
                </LoadingButton>

                <Button
                  type="submit"
                  onClick={() => {
                    setFieldValue("redirectToEntity", true);
                  }}
                  disabled={isSubmitting}
                >
                  Opslaan + openen
                </Button>
              </div>
            ) : (
              <LoadingButton type="submit" disabled={isSubmitting}>
                Opslaan
              </LoadingButton>
            )}
          </SpaceBetweenBox>
        </Grid>
      </Grid>
    </form>
  );
};

export default EmployeeForm;
