import { makeStyles } from "@material-ui/core";
import {
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from "@material-ui/lab";
import clsx from "clsx";
import { Check as CheckIcon, Close as CloseIcon } from "@material-ui/icons";
import Card from "../../../ui/cards/Card";

const useStyles = makeStyles(() => ({
  complete: { backgroundColor: "green" },
  inComplete: { backgroundColor: "red" },
}));

const ProcedureTimelineItem = ({ children, completed = false }) => {
  const classes = useStyles();

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          className={clsx(completed ? classes.complete : classes.inComplete)}
        >
          {completed ? <CheckIcon /> : <CloseIcon />}
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Card>{children}</Card>
      </TimelineContent>
    </TimelineItem>
  );
};

export default ProcedureTimelineItem;
