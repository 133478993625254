import { Button, IconButton, makeStyles, Typography } from "@material-ui/core";
import Card from "./Card";
import clsx from "clsx";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { Close as CloseIcon } from "@material-ui/icons";
import { Fragment, useState } from "react";
import Modal from "../modals/Modal";

const useStyles = makeStyles(() => ({
  card: {
    display: "flex",
    "& .MuiCardContent-root": {
      width: "100%",
      paddingBottom: "16px !important",
      display: "flex",
      flexDirection: "row",
      columnGap: "1rem",
      MozColumnGap: "1rem",
      WebkitColumnGap: "1`rem",
      alignItems: "center",
      "& a": {
        flex: 1,
      },
    },
  },
  link: {
    background: "none",
    border: "none",
    cursor: "pointer",
    flex: 1,
    textAlign: "left",
    color: "#fff",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  danger: {
    backgroundColor: "red",
    color: "#fff",
    "&:hover": {
      backgroundColor: "darkred",
    },
  },
}));

const AttachmentCard = ({ attachment, onRemove, onDownload }) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const closeHandler = () => setIsOpen(false);

  const removeHandler = async () => {
    setIsRemoving(true);
    await onRemove(attachment);
    setIsRemoving(false);
    closeHandler();
  };

  const attachmentName =
    attachment.originalName ?? `${attachment.filename}${attachment.extension}`;

  return (
    <Fragment>
      <Card styles={clsx(classes.card)}>
        <InsertDriveFileIcon />
        <button
          className={clsx(classes.link)}
          onClick={() => onDownload(attachment)}
        >
          {attachmentName}
        </button>
        <IconButton size="small" onClick={() => setIsOpen(true)}>
          <CloseIcon />
        </IconButton>
      </Card>

      <Modal isOpen={isOpen} onClose={!isRemoving ? closeHandler : null}>
        <Typography component="h3" variant="h6" gutterBottom>
          Wilt u {attachmentName} zeker verwijderen?
        </Typography>

        <div className={clsx(classes.buttons)}>
          <Button onClick={closeHandler} disabled={isRemoving}>
            Annuleren
          </Button>

          <Button
            onClick={removeHandler}
            variant="contained"
            className={clsx(classes.danger)}
            disabled={isRemoving}
          >
            Verwijderen
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
};

export default AttachmentCard;
