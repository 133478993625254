import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  legendList: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
  },
}));

const LegendList = ({ children }) => {
  const classes = useStyles();

  return <ul className={clsx(classes.legendList)}>{children}</ul>;
};

export default LegendList;
