import { Button, Grid, Switch, FormControlLabel } from "@material-ui/core";
import FormField from "../../forms/FormField";
import SpaceBetweenBox from "../../ui/SpaceBetweenBox";
import LoadingButton from "../../ui/buttons/LoadingButton";
import useVolunteerForm from "../../../hooks/useVolunteerForm";

const VolunteerForm = ({ volunteer = null, onSubmit, onCancel }) => {
  const {
    values,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useVolunteerForm(volunteer, onSubmit);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormField
            label="Naam"
            name="name"
            value={values.name}
            error={errors.name}
            onInput={handleChange}
            isDisabled={isSubmitting}
            required
          />
        </Grid>

        <Grid item xs={12}>
          <FormField
            label="E-mail"
            name="email"
            value={values.email}
            error={errors.email}
            onInput={handleChange}
            isDisabled={isSubmitting}
            required
          />
        </Grid>

        <Grid item xs={12}>
          <FormField
            label="Telefoonnummer"
            name="phoneNumber"
            value={values.phoneNumber}
            error={errors.phoneNumber}
            onInput={handleChange}
            isDisabled={isSubmitting}
          />
        </Grid>

        <Grid item xs={12}>
          <FormField
            label="Adres"
            name="address"
            value={values.address}
            error={errors.address}
            onInput={handleChange}
            isDisabled={isSubmitting}
            multiline
          />
        </Grid>

        <Grid item xs={12}>
          <FormField
            label="Voorkeuren"
            name="preference"
            value={values.preference}
            error={errors.preference}
            onInput={handleChange}
            isDisabled={isSubmitting}
            multiline
          />
        </Grid>

        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch
                name="available"
                checked={values.available}
                onChange={(event) =>
                  setFieldValue("available", event.target.checked)
                }
              />
            }
            label="Beschikbaar"
          />
        </Grid>

        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch
                name="active"
                checked={values.active}
                onChange={(event) =>
                  setFieldValue("active", event.target.checked)
                }
              />
            }
            label="Actief"
          />
        </Grid>

        <Grid item xs={12}>
          <SpaceBetweenBox>
            <Button disabled={isSubmitting} onClick={onCancel}>
              Annuleren
            </Button>

            {!volunteer ? (
              <div>
                <LoadingButton
                  type="submit"
                  onClick={() => {
                    setFieldValue("redirectToEntity", false);
                  }}
                  disabled={isSubmitting}
                >
                  Opslaan + sluiten
                </LoadingButton>

                <Button
                  type="submit"
                  onClick={() => {
                    setFieldValue("redirectToEntity", true);
                  }}
                  disabled={isSubmitting}
                >
                  Opslaan + openen
                </Button>
              </div>
            ) : (
              <LoadingButton type="submit" disabled={isSubmitting}>
                Opslaan
              </LoadingButton>
            )}
          </SpaceBetweenBox>
        </Grid>
      </Grid>
    </form>
  );
};

export default VolunteerForm;
