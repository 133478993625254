import { Button, Typography } from "@material-ui/core";
import { Fragment } from "react";
import { useState } from "react";
import STATUS_TYPES from "../../../../application/statusTypes";
import useProcedure from "../../../../hooks/useProcedure";
import ProcedureFinalizeModal from "./ProcedureFinalizeModal";
import ProcedureTimelineItem from "./ProcedureTimelineItem";

const ProcedureStepSix = () => {
  const { procedure } = useProcedure();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <ProcedureTimelineItem completed={!!procedure.endedAt}>
      <Typography variant="h6">
        {procedure.status === STATUS_TYPES.FINISHED_EARLY
          ? "Procedure vroegtijdig beïndigd"
          : "Traject afronden"}
      </Typography>

      <Typography>
        Het traject afronden en een eventuele doorverwijzing invullen.
      </Typography>

      {!procedure.endedAt && (
        <Fragment>
          <Button onClick={() => setIsOpen(true)}>
            {!procedure.endedAt && procedure.hadEvaluation
              ? "Afronden"
              : "Vroegtijdig beeïndigen"}
          </Button>

          <ProcedureFinalizeModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
          />
        </Fragment>
      )}
    </ProcedureTimelineItem>
  );
};

export default ProcedureStepSix;
