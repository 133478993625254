const COMMAND_TYPES = {
  INTERVIEW_PLANNED: 0,
  INTERVIEWS_DONE: 1,
  INTAKE_PLANNED: 2,
  INTAKES_DONE: 3,
  DATA_DONE: 4,
  EVALUATION_PLANNED: 5,
  EVALUATION_DONE: 6,
  PROCEDURE_DONE: 7,
  FINISH_EARLY: 8,
};

export default COMMAND_TYPES;
