import { Pagination } from "@material-ui/lab";
import { useState } from "react";
import { Fragment } from "react";
import NoteCard from "./NoteCard";
import usePagination from "../../../../hooks/usePagination";
import { Grid, Typography } from "@material-ui/core";
import LoadingSpinner from "../../../ui/loaders/LoadingSpinner";
import SectionTitle from "../../../Typography/SectionTitle";
import * as _ from "lodash";
import { useEffect } from "react";
import useNotes from "../../../../hooks/useNotes";

const NoteList = () => {
  const [page, setPage] = useState(1);
  const [sortedNotes, setSortedNotes] = useState(null);

  const { items, pages } = usePagination({
    items: sortedNotes,
    page,
    size: 12,
  });

  const { notes } = useNotes();

  useEffect(() => {
    setSortedNotes(_.orderBy(notes, "updatedAt", "desc"));
  }, [notes]);

  return (
    <Fragment>
      <SectionTitle>Notities</SectionTitle>

      {notes ? (
        <Fragment>
          {notes.length > 0 ? (
            <Grid container spacing={2}>
              {items.map((item, index) => (
                <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
                  <NoteCard note={item} />
                </Grid>
              ))}

              <Grid item xs={12}>
                <Pagination
                  page={page}
                  count={pages}
                  onChange={(e, page) => setPage(page)}
                />
              </Grid>
            </Grid>
          ) : (
            <Typography>Geen notities gevonden</Typography>
          )}
        </Fragment>
      ) : (
        <LoadingSpinner />
      )}
    </Fragment>
  );
};

export default NoteList;
