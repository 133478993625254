import { Button, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import { useMemo } from "react";
import * as yup from "yup";
import FormField from "../../../../forms/FormField";
import LoadingButton from "../../../../ui/buttons/LoadingButton";
import SpaceBetweenBox from "../../../../ui/SpaceBetweenBox";

const validationSchema = yup.object().shape({
  reason: yup.string().required("Vul een reden in voor de afspraak"),
  location: yup.string(),
  startAt: yup
    .date("Vul een datum en tijd in")
    .required("Vul een datum en tijd in"),
});

const AppointmentForm = ({ appointment, onSubmit, onCancel, type }) => {
  const initialValues = useMemo(() => {
    return {
      reason: appointment?.reason ?? "",
      location: appointment?.location ?? "",
      startAt: appointment?.startAt ?? "",
      type: appointment?.type ?? type,
    };
  }, [appointment, type]);

  const { values, errors, isSubmitting, handleChange, handleSubmit } =
    useFormik({
      onSubmit,
      initialValues,
      validationSchema,
      enableReinitialize: true,
    });

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormField
            label="Reden (wordt in de mail getoond)"
            onInput={handleChange}
            name="reason"
            value={values.reason}
            error={errors.reason}
            required
            isDisabled={isSubmitting}
          />
        </Grid>

        <Grid item xs={12}>
          <FormField
            label="Locatie"
            onInput={handleChange}
            name="location"
            value={values.location}
            error={errors.location}
            isDisabled={isSubmitting}
          />
        </Grid>

        <Grid item xs={12}>
          <FormField
            label="Datum en tijd van de afspraak"
            onInput={handleChange}
            name="startAt"
            value={values.startAt}
            type="datetime-local"
            error={errors.startAt}
            inputLabelProps={{
              shrink: true,
            }}
            isDisabled={isSubmitting}
          />
        </Grid>

        <Grid item xs={12}>
          <SpaceBetweenBox>
            <Button onClick={onCancel} disabled={isSubmitting}>
              Annuleren
            </Button>

            <LoadingButton type="submit" disabled={isSubmitting}>
              Inplannen
            </LoadingButton>
          </SpaceBetweenBox>
        </Grid>
      </Grid>
    </form>
  );
};

export default AppointmentForm;
