import { useState } from "react";
import {
  makeStyles,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
import { Link } from "react-router-dom";
import Dropdown from "../Dropdown";
import Avatar from "../Avatar";

const useStyles = makeStyles(() => ({
  accountIconButton: {
    overflow: "hidden",
    backgroundColor: "rgba(255, 255, 255, 0.08)",
    "& > .MuiIconButton-label": {
      width: "25px",
      height: "25px",
      fontSize: "20px",
    },
  },
  avatar: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
}));

const AppbarAccount = () => {
  const classes = useStyles();
  const [isHidden, setIsHidden] = useState(true);
  const dispatch = useDispatch();

  const signout = () => {
    dispatch({ type: "SIGNOUT" });
  };

  return (
    <Dropdown
      isHidden={isHidden}
      setIsHidden={setIsHidden}
      right={0}
      top="56px"
      button={
        <IconButton className={clsx(classes.accountIconButton)}>
          <Avatar className={clsx(classes.avatar)} />
        </IconButton>
      }
    >
      <List>
        <ListItem button component={Link} to="/account">
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText>Mijn profiel</ListItemText>
        </ListItem>

        <ListItem button onClick={signout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText>Afmelden</ListItemText>
        </ListItem>
      </List>
    </Dropdown>
  );
};

export default AppbarAccount;
