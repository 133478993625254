import React, { useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import DataGrid from "../../components/datagrid";
import { Edit as EditIcon, Close as CloseIcon } from "@material-ui/icons";
import { deleteOrganisation } from "../../api/organisations";
import SectionTitle from "../../components/Typography/SectionTitle";
import OrganisationEditModal from "../../components/pages/organisation/OrganisationEditModal";
import PageSuspense from "../../components/ui/loaders/PageSuspense";
import OrganisationInfo from "../../components/pages/organisation/OrganisationInfo";
import SpeedDial from "../../components/ui/SpeedDial";
import { Link, useRouteMatch } from "react-router-dom";
import DeletionModal from "../../components/ui/modals/DeletionModal";
import useMounted from "../../hooks/useMounted";
import { Fragment } from "react";
import { useFetch } from "../../hooks";

const ShowOrganisation = () => {
  const {
    params: { id },
  } = useRouteMatch();

  const isMounted = useMounted();

  const { response, isLoading } = useFetch(`/api/v1/organisations/${id}`);

  const [organisation, setOrganisation] = useState(null);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDialOpen, setIsDialOpen] = useState(false);

  useEffect(() => {
    if (isMounted.current && response)
      setOrganisation(response.data.organisation);
  }, [isMounted, response]);

  const deleteHandler = async () => {
    await deleteOrganisation(id);
  };

  let columns = [
    {
      field: "name",
      headerName: "Naam",
      flex: 1,
    },
    {
      field: "email",
      headerName: "E-mail",
      flex: 1,
    },
    {
      field: "view",
      headerName: " ",
      renderCell: ({ row }) => (
        <Button
          component={Link}
          to={`/gebruikers/${row.id}`}
          variant="outlined"
        >
          Bekijken
        </Button>
      ),
    },
  ];

  const actions = [
    {
      icon: <EditIcon />,
      tooltipTitle: "Bewerken",
      onClick: () => setIsEditOpen(true),
    },
    {
      icon: <CloseIcon />,
      tooltipTitle: "Verwijderen",
      onClick: () => setIsDeleteOpen(true),
    },
  ];

  return (
    <PageSuspense isLoading={isLoading}>
      {organisation && (
        <Fragment>
          <Grid container spacing={3}>
            <Grid item xs={12} component="section">
              <SectionTitle>Klant informatie</SectionTitle>

              <OrganisationInfo organisation={organisation} />
            </Grid>

            <Grid item xs={12} component="section">
              <SectionTitle>Alle gebruikers</SectionTitle>

              <DataGrid
                columns={columns}
                apiUrl={`/api/v1/organisations/${id}/users`}
                hasRemoved
              />
            </Grid>
          </Grid>

          <OrganisationEditModal
            isOpen={isEditOpen}
            onClose={() => setIsEditOpen(false)}
            organisation={organisation}
            setOrganisation={setOrganisation}
          />

          <DeletionModal
            isOpen={isDeleteOpen}
            onClose={() => setIsDeleteOpen(false)}
            deleteHandler={deleteHandler}
            message="Als u de organisatie verwijderd is het niet meer mogelijk om gegevens van de organisatie terug te krijgen. Onder gegevens vallen gebruikers, verslagen, notities, bestanden, etc."
          />

          <SpeedDial
            actions={actions}
            ariaLabel="Organisatie acties"
            isDialOpen={isDialOpen}
            setIsDialOpen={setIsDialOpen}
          />
        </Fragment>
      )}
    </PageSuspense>
  );
};

export default ShowOrganisation;
