import { Button, Typography } from "@material-ui/core";
import { useState } from "react";
import { procedureCommands } from "../../../../api/students/procedures";
import COMMAND_TYPES from "../../../../application/commandTypes";
import useProcedure from "../../../../hooks/useProcedure";
import ConfirmationModal from "../../../ui/modals/ConfirmationModal";
import ProcedureTimelineItem from "./ProcedureTimelineItem";

const ProcedureStepFour = () => {
  const {
    procedure,
    setProcedure,
    setIsUploadOpen,
    setIsStudentReportOpen,
    setIsCreateNoteOpen,
  } = useProcedure();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const confirmationHandler = async () => {
    const updated = await procedureCommands(procedure.id, {
      command: COMMAND_TYPES.DATA_DONE,
    });

    if (updated) {
      setProcedure({ ...procedure, hadData: true });

      confirmationModalHandler();
    }
  };

  const confirmationModalHandler = () =>
    setIsConfirmationOpen(!isConfirmationOpen);

  return (
    <ProcedureTimelineItem completed={procedure.hadData}>
      <Typography variant="h6">Traject data</Typography>

      <Typography>
        Voeg documenten toe aan de cursist voor het traject.
      </Typography>

      {!procedure.hadData && procedure.hadIntakes && (
        <div>
          <Button onClick={() => setIsUploadOpen(true)}>
            Bestand uploaden
          </Button>

          <Button onClick={() => setIsStudentReportOpen(true)}>
            Verslag toevoegen
          </Button>

          <Button onClick={() => setIsCreateNoteOpen(true)}>
            Notitie toevoegen
          </Button>

          <Button onClick={() => setIsConfirmationOpen(true)}>Afronden</Button>

          <ConfirmationModal
            isOpen={isConfirmationOpen}
            onClose={confirmationModalHandler}
            onConfirm={confirmationHandler}
            message="Na het afronden van deze fase het is nog steeds mogelijk om verslagen, notities en bestanden toe te voegen aan een cursist via het quick menu rechts beneden."
          />
        </div>
      )}
    </ProcedureTimelineItem>
  );
};

export default ProcedureStepFour;
