import { createContext, useCallback, useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { toast } from "react-toastify";
import { request } from "../api/requests";
import useFetch from "../hooks/useFetch";
import useMounted from "../hooks/useMounted";
import PageSuspense from "../components/ui/loaders/PageSuspense";
import { deleteUser } from "../api/users";

/** Create the volunteer context. */
export const VolunteerContext = createContext();

const VolunteerProvider = ({ children }) => {
  /** Get the user id from the url. */
  const {
    params: { id },
  } = useRouteMatch();

  /**
   * Using the useMounted hook to check
   * if component is mounted before setting state.
   */
  const isMounted = useMounted();

  /** Fetch the volunteer by id. */
  const { response } = useFetch(`/api/v1/volunteers/${id}`);

  /** Initialize the state for volunteer. */
  const [volunteer, setVolunteer] = useState(null);

  /** Run useEffect on mount and if the useEffect method changes. */
  useEffect(() => {
    if (isMounted) setVolunteer(response?.data?.volunteer);
  }, [isMounted, response]);

  /** Method to update the volunteer. */
  const updateVolunteer = useCallback(
    async (data, callback) => {
      const [, error] = await request({
        method: "PATCH",
        url: `/api/v1/volunteers/${id}`,
        data,
      });

      if (isMounted.current && !error) {
        setVolunteer((volunteer) => ({ ...volunteer, ...data }));

        toast.success("Vrijwilliger bijgewerkt.");

        if (callback instanceof Function) callback();
      }
    },
    [isMounted, id]
  );

  /** Method to delete the volunteer from the system. */
  const deleteVolunteer = async () =>
    await deleteUser(id, "Vrijwilliger verwijderd!", "/vrijwilligers");

  /** Construct the state from values and methods. */
  const state = {
    volunteer,
    setVolunteer,
    updateVolunteer,
    deleteVolunteer,
  };

  return (
    <VolunteerContext.Provider value={state}>
      <PageSuspense isLoading={!volunteer}>{children}</PageSuspense>
    </VolunteerContext.Provider>
  );
};

export default VolunteerProvider;
