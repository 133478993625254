import { toast } from "react-toastify";
import {
  apiErrorsToObject,
  objectToFormData,
} from "../../../application/helpers";
import { request } from "../../requests";
import { history } from "../../../router";

export const getReport = async (studentId, reportId) => {
  const [response, error] = await request({
    method: "GET",
    url: `/api/v1/students/${studentId}/reports/${reportId}`,
  });

  if (!error) return response?.data?.report;
};

export const postReport = async (studentId, values) => {
  const data = objectToFormData(values);

  const [response, error] = await request({
    method: "POST",
    url: `/api/v1/students/${studentId}/reports`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });

  if (error) {
    const errors = apiErrorsToObject(error?.response?.data?.errors);

    return [null, errors];
  }

  toast.success("Verslag toegevoegd.");
  const report = response?.data?.report;

  return [report, null];
};

export const patchReport = async (studentId, reportId, data) => {
  const [, error] = await request({
    method: "PATCH",
    url: `/api/v1/students/${studentId}/reports/${reportId}`,
    data,
  });

  if (error) {
    const errors = apiErrorsToObject(error?.response?.data?.errors);

    return errors;
  }

  toast.success("Verslag bijgewerkt.");

  return null;
};

export const deleteReport = async (studentId, reportId) => {
  const [, error] = await request({
    method: "DELETE",
    url: `/api/v1/students/${studentId}/reports/${reportId}`,
  });

  if (!error) {
    toast.success("Verslag verwijderd.");
    history.push(`/cursisten/${studentId}/verslagen`);
  }
};
