import { request } from "./requests";
import { apiErrorsToObject } from "../application/helpers";
import { toast } from "react-toastify";
import { history } from "../router";

export const getVolunteer = async (id) => {
  const [response, error] = await request({
    method: "GET",
    url: `/api/v1/volunteers/${id}`,
  });

  if (!error) return response?.data?.volunteer;
};

export const patchVolunteer = async (id, data) => {
  const [, error] = await request({
    method: "PATCH",
    url: `/api/v1/volunteers/${id}`,
    data,
  });

  if (error) {
    const errors = apiErrorsToObject(error?.response?.data?.errors ?? {});

    return errors;
  }

  toast.success("Vrijwilliger bijgewerkt.");

  return null;
};

export const postVolunteer = async (organisationId, data) => {
  const [response, error] = await request({
    method: "POST",
    url: `/api/v1/organisations/${organisationId}/volunteers`,
    data,
  });

  if (error) {
    const errors = apiErrorsToObject(error?.response?.data?.errors ?? {});

    return [null, errors];
  }

  toast.success("Vrijwilliger toegevoegd.");
  const volunteer = response?.data?.volunteer;

  return [volunteer, null];
};

export const deleteVolunteers = async (id) => {
  const [, error] = await request({
    method: "DELETE",
    url: `/api/v1/volunteers/${id}`,
  });

  if (!error) {
    toast.success("Vrijwilligers verwijderd.");
    history.push(`/vrijwilligers`);
  }
};
