import {
  Button,
  Grid,
  Link,
  List,
  ListItem,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import FormFile from "../../forms/FormFile";
import Modal from "../../ui/modals/Modal";
import SectionTitle from "../../Typography/SectionTitle";
import SpaceBetweenBox from "../../ui/SpaceBetweenBox";
import { Fragment } from "react";
import * as yup from "yup";
import { request } from "../../../api/requests";
import { toast } from "react-toastify";
import { apiErrorsToObject } from "../../../application/helpers";
import LoadingButton from "../buttons/LoadingButton";
import { useState } from "react";
import LoadingSpinner from "../loaders/LoadingSpinner";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "1.5rem",
  },
  errorMessageItem: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

const validationSchema = yup.object().shape({
  file: yup.mixed().required("Upload een bestand om te importeren!"),
});

const ImportModal = ({ isOpen, onClose, example, name, url }) => {
  const [importErrors, setImportErrors] = useState([]);

  const classes = useStyles();

  const submitHandler = async (values, { setErrors, resetForm }) => {
    const data = new FormData();
    data.append("file", values.file);

    const [response, error] = await request({
      method: "POST",
      url: url,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data,
    });

    if (error) {
      setErrors(apiErrorsToObject(error?.response?.data?.errors));
    } else {
      let errors = response?.data?.errors;

      if (errors.length > 0) {
        toast.warning(
          `${name} geïmporteerd, maar er zijn een aantal ${name} die niet geïmporteerd kunnen worden, bekijk de errors hieronder.`
        );
      } else {
        toast.success(`${name} succesvol geïmporteerd!`);
      }

      setImportErrors(response?.data?.errors);
      resetForm();
    }
  };

  const { errors, isSubmitting, handleSubmit, setFieldValue, resetForm } =
    useFormik({
      initialValues: {
        file: null,
      },
      onSubmit: submitHandler,
      validationSchema,
    });

  const closeHandler = () => {
    setImportErrors(null);
    resetForm();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={closeHandler}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SectionTitle>{name} importeren</SectionTitle>
            <Typography>
              Het is bij Mijn Taalhuis mogelijk om {name.toLowerCase()} te
              uploaden via een excel bestandje. Het excel bestand moet voldoen
              aan ons formaat.{" "}
              {example && (
                <Fragment>
                  klik <Link href={`/examples/${example}`}>hier</Link> om de
                  template met het formaat te downloaden.
                </Fragment>
              )}
            </Typography>
            <br />
            <Typography style={{ color: "#FF0000" }}>
              LET OP: Van het excel bestand wordt alleen het eerste blad
              gelezen. Verander ook de kopjes niet, omdat hierop wordt
              gecontroleerd of het excel document klopt volgens onze structuur.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {isSubmitting ? (
              <div className={clsx(classes.loader)}>
                <LoadingSpinner size={60} />
                <Typography variant="body2" component="p">
                  Bezig met het importeren
                </Typography>
              </div>
            ) : (
              <FormFile
                onInput={(files) => setFieldValue("file", files[0])}
                disabled={isSubmitting}
                error={errors.file}
                amount={1}
                extensions=".xlsx"
              />
            )}
          </Grid>

          {importErrors && importErrors.length > 0 && (
            <Grid item xs={12}>
              <Paper>
                <List>
                  {importErrors.map(({ title, messages }, index) => (
                    <ListItem
                      className={clsx(classes.errorMessageItem)}
                      key={`error-${index}`}
                    >
                      <Typography variant="subtitle1" gutterBottom>
                        {title}
                      </Typography>
                      {messages.map((message, messageIndex) => (
                        <Typography
                          key={`error-${index}-message-${messageIndex}`}
                          variant="body2"
                        >
                          - {message}
                        </Typography>
                      ))}
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
          )}

          <Grid item xs={12}>
            <SpaceBetweenBox>
              <Button onClick={onClose} disabled={isSubmitting}>
                Annuleren
              </Button>

              <LoadingButton type="submit" disabled={isSubmitting}>
                Importeren
              </LoadingButton>
            </SpaceBetweenBox>
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
};

export default ImportModal;
