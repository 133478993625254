import { Box, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { useSelector } from "react-redux";
import Avatar from "../Avatar";

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    width: "100%",
    padding: "26px 16px 16px 16px",
    maxWidth: "100%",
    display: "flex",
    columnGap: "1rem",
  },
  text: {
    display: "inline-block",
    lineHeight: 0.5,
    wordSpacing: 0.1,
  },
  title: {
    fontWeight: "600",
    fontSize: 15,
  },
  subtitle: {
    fontSize: 12,
    color: "#B4B4B4",
  },
  avatar: {
    borderRadius: "100%",
    overflow: "hidden",
    background: "rgba(255, 255, 255, 0.08)",
  },
}));

const SidebarHeader = () => {
  const classes = useStyles();
  const user = useSelector((store) => store.auth.user);

  const concatText = (text = "") => {
    return text.length > 30 ? text.substr(0, 30).concat("...") : text;
  };
  const organisationText = (userPayload) => {
    if (userPayload.organisation != null) {
      return userPayload.organisation.name;
    }
    return "Global"
  };

  return (
    <Box component="header" className={clsx(classes.box)}>
      <Avatar className={clsx(classes.avatar)} />
      <div>
        <Tooltip title={user?.name} aria-label="E-mail" arrow placement="right">
          <Typography
            component="p"
            variant="body2"
            className={clsx(classes.text, classes.title)}
          >
            {concatText(user?.name)}
          </Typography>
        </Tooltip>
        <br />
        <Tooltip title={"test"} aria-label="Organisation" arrow placement="right">
          <Typography
            component="p"
            variant="body2"
            className={clsx(classes.text, classes.subtitle)}
          > 
            {concatText(organisationText(user))}
          </Typography>
        </Tooltip>
        <br />
        <Tooltip
          title={user?.email}
          aria-label="E-mail"
          arrow
          placement="right"
        >
          <Typography
            component="p"
            variant="body2"
            className={clsx(classes.text, classes.subtitle)}
          >
            {concatText(user?.email)}
            
          </Typography>
        </Tooltip>
      </div>
    </Box>
  );
};

export default SidebarHeader;
