import { IconButton } from "@material-ui/core";
import UpdateIcon from "@material-ui/icons/Update";
import { useState } from "react";
import Dropdown from "../Dropdown";
import UpdateList from "../ui/lists/UpdateList";

const AppbarUpdates = () => {
  const [isHidden, setIsHidden] = useState(true);

  return (
    <Dropdown
      isHidden={isHidden}
      setIsHidden={setIsHidden}
      right={0}
      top="56px"
      button={
        <IconButton>
          <UpdateIcon />
        </IconButton>
      }
    >
      <UpdateList />
    </Dropdown>
  );
};

export default AppbarUpdates;
