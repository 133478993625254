import FormField from "../../forms/FormField";
import SectionTitle from "../../Typography/SectionTitle";
import { Box, Button } from "@material-ui/core";
import useEmployeeForm from "../../../hooks/useEmployeeForm";
import useEmployee from "../../../hooks/useEmployee";

const EmployeeCommentaryBox = () => {
  const { employee, updateEmployee } = useEmployee();

  const submitHandler = async (values) => await updateEmployee(values);

  const { values, isSubmitting, handleChange, handleSubmit } = useEmployeeForm(
    employee,
    submitHandler
  );

  const hasChanged = employee?.comments !== values?.comments;

  return (
    <form onSubmit={handleSubmit} noValidate>
      <SectionTitle>Opmerkingen</SectionTitle>

      <Box marginBottom={3} maxWidth="400px">
        <FormField
          type="textfield"
          label="Opmerkingen"
          variant="filled"
          multiline
          name="comments"
          value={values.comments ?? ""}
          onInput={handleChange}
          isDisabled={isSubmitting}
        />
      </Box>

      <Button
        type="submit"
        variant="contained"
        color="secondary"
        disabled={!hasChanged || isSubmitting}
      >
        Opslaan
      </Button>
    </form>
  );
};

export default EmployeeCommentaryBox;
