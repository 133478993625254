import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import JwtService from "../application/jwtService";
import {
  getToken,
  isAuthenticated as isAuthenticatedSelector,
} from "../store/user/selectors";
import PropTypes from "prop-types";

const AuthRoute = ({
  component: Component,
  path,
  exact = false,
  roles,
  children = [],
}) => {
  const isAuthenticated = useSelector((state) =>
    isAuthenticatedSelector(state)
  );
  const token = useSelector((state) => getToken(state));

  const jwtService = useMemo(() => new JwtService(token), [token]);

  const isAuthorized = () => {
    if (roles === undefined || roles === null) return true;

    return jwtService.isInRoles(roles);
  };

  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => {
        if (!isAuthenticated) {
          return <Redirect to="/auth/signin" />;
        } else if (!isAuthorized()) {
          return <Redirect to="/forbidden" />;
        } else {
          return <Component {...props} children={children} />;
        }
      }}
    />
  );
};

AuthRoute.propTypes = {
  roles: PropTypes.array,
};

export default AuthRoute;
