import { useEffect, useState } from "react";
import SectionTitle from "../../components/Typography/SectionTitle";
import InformationList from "../../components/ui/lists/InformationList";
import InformationListItem from "../../components/ui/lists/InformationListItem";
import { Button } from "@material-ui/core";
import PageSuspense from "../../components/ui/loaders/PageSuspense";
import UserGdprModal from "../../components/pages/users/UserGdprModal";
import { Fragment } from "react";
import { useMounted, useFetch } from "../../hooks";
import { useRouteMatch } from "react-router";

const ShowUserPage = () => {
  const {
    params: { id },
  } = useRouteMatch();

  const [user, setUser] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const isMounted = useMounted();

  const { response, isLoading } = useFetch(`/api/v1/users/${id}`);

  useEffect(() => {
    if (isMounted.current && response) setUser(response.data.user);
  }, [isMounted, response]);

  return (
    <PageSuspense isLoading={isLoading}>
      {user && (
        <>
          <SectionTitle>
            {user.isRemoved ? "Verwijderde gebruiker" : user.name}
          </SectionTitle>

          <InformationList>
            <InformationListItem title="Naam">{user.name}</InformationListItem>
            <InformationListItem title="E-mail">{user.email}</InformationListItem>
            <InformationListItem title="Reset OTP token">{user.email}</InformationListItem>
            <InformationListItem title="Reset wachtwoord">{user.email}</InformationListItem>
          </InformationList>

          {user?.isRemoved || (
            <Fragment>
              <Button variant="contained" onClick={() => setIsOpen(true)}>
                Persoonsgegevens verwijderen
              </Button>

              <UserGdprModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                user={user}
                setUser={setUser}
              />
            </Fragment>
          )}
        </>
      )}
    </PageSuspense>
  );
};

export default ShowUserPage;
