import { useEffect } from "react";
import { useState } from "react";
import PageSuspense from "../../components/ui/loaders/PageSuspense";
import { useRouteMatch } from "react-router";
import useMounted from "../../hooks/useMounted";
import { deleteUpdate } from "../../api/updates";
import { Typography } from "@material-ui/core";
import { Fragment } from "react";
import Card from "../../components/ui/cards/Card";
import SpeedDial from "../../components/ui/SpeedDial";
import { Edit as EditIcon, Close as CloseIcon } from "@material-ui/icons";
import DeletionModal from "../../components/ui/modals/DeletionModal";
import UpdateEditModal from "../../components/pages/updates/UpdateEditModal";
import moment from "moment";
import { useFetch } from "../../hooks";

const ShowUpdatePage = () => {
  const {
    params: { id },
  } = useRouteMatch();

  const isMounted = useMounted();

  const [update, setUpdate] = useState(null);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDialOpen, setIsDialOpen] = useState(false);

  const { response, isLoading } = useFetch(`/api/v1/appupdates/${id}`);

  useEffect(() => {
    if (isMounted.current && response) setUpdate(response.data.update);
  }, [isMounted, response]);

  const actions = [
    {
      icon: <EditIcon />,
      tooltipTitle: "Bijwerken",
      onClick: () => setIsEditOpen(true),
    },
    {
      icon: <CloseIcon />,
      tooltipTitle: "Verwijderen",
      onClick: () => setIsDeleteOpen(true),
    },
  ];

  const deleteHandler = async () => await deleteUpdate(id);

  return (
    <PageSuspense isLoading={!isLoading}>
      {update && (
        <Fragment>
          <Card>
            <Typography variant="h5" component="h2">
              {update.title}
            </Typography>
            <Typography variant="subtitle2" component="h3" gutterBottom>
              {moment(update.createdAt).format("DD-MM-yyyy")}
            </Typography>

            <Typography variant="body1" component="p">
              {update.description}
            </Typography>
          </Card>

          <UpdateEditModal
            isOpen={isEditOpen}
            onClose={() => setIsEditOpen(false)}
            update={update}
            setUpdate={setUpdate}
          />

          <DeletionModal
            isOpen={isDeleteOpen}
            onClose={() => setIsDeleteOpen(false)}
            deleteHandler={deleteHandler}
          />

          <SpeedDial
            ariaLabel="App update acties"
            actions={actions}
            isDialOpen={isDialOpen}
            setIsDialOpen={setIsDialOpen}
          />
        </Fragment>
      )}
    </PageSuspense>
  );
};

export default ShowUpdatePage;
