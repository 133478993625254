import { Grid, Typography } from "@material-ui/core";
import FormField from "../../forms/FormField";
import { Button } from "@material-ui/core";
import useVolunteer from "../../../hooks/useVolunteer";
import useVolunteerForm from "../../../hooks/useVolunteerForm";

const VolunteerPreferencesBox = () => {
  const { volunteer, updateVolunteer } = useVolunteer();
  const { values, isSubmitting, handleChange, handleSubmit } = useVolunteerForm(
    volunteer,
    async (values) => {
      await updateVolunteer(values);
    }
  );

  return (
    <form onSubmit={handleSubmit} method="post">
      <Typography component="h2" variant="h6" gutterBottom>
        Voorkeuren
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormField
            label="Voorkeuren"
            name="preference"
            value={values.preference}
            onInput={handleChange}
            isDisabled={isSubmitting}
            multiline
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            type="submit"
            disabled={
              isSubmitting || values.preference === volunteer?.preference
            }
          >
            Opslaan
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default VolunteerPreferencesBox;
