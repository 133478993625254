import { toast } from "react-toastify";
import { apiErrorsToObject } from "../../application/helpers";
import { request } from "../requests";

export const getProcedure = async (studentId) => {
  const [response, error] = await request({
    method: "GET",
    url: `/api/v1/students/${studentId}/procedure`,
  });

  if (!error) return response?.data?.procedure;
};

export const patchProcedure = async (id, data) => {
  const [, error] = await request({
    method: "PATCH",
    url: `/api/v1/procedures/${id}`,
    data,
  });

  if (error) return apiErrorsToObject(error?.response?.data?.errors);

  toast.success("Traject bijgewerkt.");

  return null;
};

export const procedureCommands = async (id, data) => {
  const [, error] = await request({
    method: "PATCH",
    url: `/api/v1/procedures/${id}/commands`,
    data,
  });

  if (error) return false;

  toast.success("Stap afgerond.");
  return true;
};
