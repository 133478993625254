import { Grid, Button } from "@material-ui/core";
import { useFormik } from "formik";
import { useCallback } from "react";
import FormField from "../../../forms/FormField";
import FormFile from "../../../forms/FormFile";
import FormSectionTitle from "../../../Typography/FormSectionTitle";
import * as yup from "yup";
import SpaceBetweenBox from "../../../ui/SpaceBetweenBox";
import { useMemo } from "react";
import { LONG_MAX_LENGTH, MAX_LENGTH } from "../../../../application/constants";
import LoadingButton from "../../../ui/buttons/LoadingButton";
import {
  stringValidationMessage,
  maxLengthValidationMessage,
  requiredValidationMessage,
  notRequiredValidationMessage,
} from "../../../../application/yup/validation";

const validationSchema = yup.object().shape({
  title: yup
    .string(stringValidationMessage)
    .max(MAX_LENGTH, maxLengthValidationMessage)
    .required(requiredValidationMessage),
  description: yup
    .string(stringValidationMessage)
    .max(LONG_MAX_LENGTH, maxLengthValidationMessage)
    .required(requiredValidationMessage),
  files: yup.array().max(6).notRequired(notRequiredValidationMessage),
});

const ReportForm = ({ report, onSubmit, onCancel }) => {
  const initialValues = useMemo(() => {
    const values = {
      title: report?.title ?? "",
      description: report?.description ?? "",
    };

    if (!report) return { ...values, files: [] };

    return values;
  }, [report]);

  const {
    values,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    onSubmit: onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  const setFiles = useCallback(
    (files) => {
      setFieldValue("files", files);
    },
    [setFieldValue]
  );

  return (
    <form onSubmit={handleSubmit} noValidate encType="multipart/form-data">
      {!report && <FormSectionTitle>Verslagen</FormSectionTitle>}

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormField
            label="Titel"
            name="title"
            value={values.title}
            error={errors.title}
            onInput={handleChange}
            required
            isDisabled={isSubmitting}
          />
        </Grid>

        <Grid item xs={12}>
          <FormField
            label="Beschrijving"
            name="description"
            value={values.description}
            error={errors.description}
            onInput={handleChange}
            multiline
            required
            isDisabled={isSubmitting}
          />
        </Grid>

        {!report && (
          <Grid item xs={12}>
            <FormSectionTitle>Bijlage</FormSectionTitle>

            <span>
              Het is mogelijk om op een later moment meerdere bestanden te
              uploaden. Het formulier neemt namelijk maar 6 bestanden per keer
              aan.
            </span>
            <FormFile
              label="Label"
              onInput={setFiles}
              extensions=".doc,.docx,.xlsx,.pdf,.png,.jpg"
              disabled={isSubmitting}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <SpaceBetweenBox>
            <Button disabled={isSubmitting} onClick={onCancel}>
              Annuleren
            </Button>

            <LoadingButton disabled={isSubmitting} type="submit">
              Opslaan
            </LoadingButton>
          </SpaceBetweenBox>
        </Grid>
      </Grid>
    </form>
  );
};

export default ReportForm;
