import { toast } from "react-toastify";
import { apiErrorsToObject } from "../application/helpers";
import { request } from "./requests";

export const postEmployee = async (organisationId, data) => {
  const [response, error] = await request({
    method: "POST",
    url: `/api/v1/organisations/${organisationId}/employees`,
    data,
  });

  if (error) {
    const errors = apiErrorsToObject(error?.response?.data?.errors ?? {});

    return [null, errors];
  }

  toast.success("Medewerker toegevoegd.");
  const employee = response?.data?.employee;

  return [employee, null];
};

export const getEmployee = async (id) => {
  const [response, error] = await request({
    method: "GET",
    url: `/api/v1/employees/${id}`,
  });

  if (!error) return response?.data?.employee;
};

export const patchEmployee = async (id, data) => {
  const [response, error] = await request({
    method: "patch",
    url: `/api/v1/employees/${id}`,
    data,
  });

  if (error) {
    const errors = apiErrorsToObject(error?.response?.data?.errors ?? {});

    return [null, errors];
  }

  toast.success("Medewerker bijgewerkt.");
  const employee = response?.data?.employee;

  return [employee, null];
};
