import { useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import SideMenuLayout from "../../ui/menus/SideMenuLayout";
import {
  Edit as EditIcon,
  FileCopy as FileCopyIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import EmployeeEditModal from "./EmployeeEditModal";
import FileUploadModal from "../shared/files/FileUploadModal";
import DeletionModal from "../../ui/modals/DeletionModal";
import SpeedDial from "../../ui/SpeedDial";
import useEmployee from "../../../hooks/useEmployee";
import SectionTitle from "../../Typography/SectionTitle";

const EmployeeRouter = ({ routes }) => {
  const { path: basePath, url } = useRouteMatch();

  const { employee, deleteEmployee } = useEmployee();

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDialOpen, setIsDialOpen] = useState(false);
  const [isUploadOpen, setIsUploadOpen] = useState(false);

  const actions = [
    {
      icon: <EditIcon />,
      tooltipTitle: "Bijwerken",
      onClick: () => setIsEditOpen(true),
    },
    {
      icon: <FileCopyIcon />,
      tooltipTitle: "Bestanden uploaden",
      onClick: () => setIsUploadOpen(true),
    },
    {
      icon: <CloseIcon />,
      tooltipTitle: "Verwijderen",
      onClick: () => setIsDeleteOpen(true),
    },
  ];

  const menu = [
    { label: "Persoonlijke gegevens", to: `${url}` },
    { label: "Bestanden", to: `${url}/bestanden` },
  ];

  return (
    <>
      <SectionTitle>{employee.name}</SectionTitle>
      <SideMenuLayout items={menu}>
        <Switch>
          {routes.map(({ path, component }, index) => (
            <Route
              component={component}
              path={`${basePath}/${path}`}
              exact
              key={index}
            />
          ))}
        </Switch>

        <EmployeeEditModal
          isOpen={isEditOpen}
          onClose={() => setIsEditOpen(false)}
        />

        <FileUploadModal
          isOpen={isUploadOpen}
          onClose={() => setIsUploadOpen(false)}
        />

        <DeletionModal
          isOpen={isDeleteOpen}
          onClose={() => setIsDeleteOpen(false)}
          deleteHandler={deleteEmployee}
        />

        <SpeedDial
          ariaLabel="Medewerker acties"
          actions={actions}
          isDialOpen={isDialOpen}
          setIsDialOpen={setIsDialOpen}
        />
      </SideMenuLayout>
    </>
  );
};

export default EmployeeRouter;
