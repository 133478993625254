import { TextField } from "@material-ui/core";

const FormField = ({
  type = "text",
  value,
  name,
  label,
  error,
  touched = true,
  onInput,
  onBlur,
  isDisabled = false,
  required = false,
  multiline = false,
  inputLabelProps,
}) => {
  let minLines = 1;
  let maxLines = 4;

  return (
    <TextField
      type={type}
      value={value}
      name={name}
      label={label}
      helperText={touched && error}
      error={touched && !!error}
      onInput={onInput}
      onBlur={onBlur}
      disabled={isDisabled}
      fullWidth
      color="primary"
      variant="outlined"
      required={required}
      multiline={multiline}
      minRows={multiline ? maxLines : minLines}
      maxRows={multiline ? maxLines : minLines}
      InputLabelProps={inputLabelProps}
    />
  );
};

export default FormField;
