import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { request } from "../api/requests";

const DataFetcher = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUpdates = async () => {
      const [response, error] = await request({
        method: "GET",
        url: "/api/v1/appupdates?limit=3&page=1",
      });

      if (error) {
        toast.error("Sommige data kon niet worden opgehaald.");
      } else {
        dispatch({ type: "SET_UPDATES", payload: response.data.items });
      }
    };

    fetchUpdates();
  }, [dispatch]);

  return children;
};

export default DataFetcher;
