import { createAvatar } from "@dicebear/avatars";
import * as avatarStyle from "@dicebear/avatars-identicon-sprites";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getUser } from "../store/user/selectors";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  avatar: {
    width: 50,
    height: 50,
  },
}));

const Avatar = ({ className }) => {
  const user = useSelector((state) => getUser(state));

  const avatar = useMemo(
    () =>
      createAvatar(avatarStyle, {
        seed: user.email,
      }),
    [user]
  );

  const classes = useStyles();

  return (
    <div
      className={clsx(classes.avatar, className)}
      dangerouslySetInnerHTML={{ __html: avatar }}
    />
  );
};

export default Avatar;
