import { Button, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import FormField from "../../forms/FormField";
import SpaceBetweenBox from "../../ui/SpaceBetweenBox";
import LoadingButton from "../../ui/buttons/LoadingButton";
import { request } from "../../../api/requests";
import { toast } from "react-toastify";
import {
  requiredValidationMessage,
  stringValidationMessage,
} from "../../../application/yup/validation";

const validationSchema = yup.object().shape({
  currentPassword: yup
    .string(stringValidationMessage)
    .required(requiredValidationMessage),
  password: yup
    .string(stringValidationMessage)
    .required(requiredValidationMessage),
  confirmPassword: yup
    .string(stringValidationMessage)
    .required(requiredValidationMessage)
    .oneOf([yup.ref("password")], "Wachtwoorden komen niet overeen met elkaar"),
});

const PasswordTab = () => {
  const submitHandler = async (values, { setErrors, resetForm }) => {
    const [, error] = await request({
      method: "PATCH",
      url: `/api/v1/me/password`,
      data: values,
    });

    if (error) {
      setErrors(error?.response?.data?.errors ?? {});
    } else {
      toast.success("Wachtwoord bijgewerkt");

      resetForm();
    }
  };

  const initialValues = {
    currentPassword: "",
    password: "",
    confirmPassword: "",
  };

  const {
    values,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    onSubmit: submitHandler,
    initialValues,
    validationSchema,
  });

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormField
            type="password"
            name="currentPassword"
            label="Huidig wachtwoord"
            value={values.currentPassword}
            error={errors.currentPassword}
            onInput={handleChange}
            isDisabled={isSubmitting}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormField
            type="password"
            name="password"
            label="Nieuwe wachtwoord"
            value={values.password}
            error={errors.password}
            onInput={handleChange}
            isDisabled={isSubmitting}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormField
            type="password"
            name="confirmPassword"
            label="Bevestig nieuwe wachtwoord"
            value={values.confirmPassword}
            error={errors.confirmPassword}
            onInput={handleChange}
            isDisabled={isSubmitting}
          />
        </Grid>

        <Grid item xs={12}>
          <SpaceBetweenBox>
            <Button onClick={resetForm} disabled={isSubmitting}>
              Resetten
            </Button>

            <LoadingButton type="submit" disabled={isSubmitting}>
              Opslaan
            </LoadingButton>
          </SpaceBetweenBox>
        </Grid>
      </Grid>
    </form>
  );
};

export default PasswordTab;
