import { useSelector } from "react-redux";
import { Fragment, useRef, useState } from "react";
import EmployeeCreateModal from "../../components/pages/employees/EmployeeCreateModal";
import { Add as AddIcon } from "@material-ui/icons";
import FloatingActionButton from "../../components/ui/FloatingActionButton";
import { useRouteMatch } from "react-router";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import DataTable from "../../components/datagrid";

const Employees = () => {
  const user = useSelector((state) => state.auth.user);

  const { url } = useRouteMatch();

  const ref = useRef({ current: {} });

  const [isCreateOpen, setIsCreateOpen] = useState(false);

  const columns = [
    {
      field: "name",
      headerName: "Naam",
      flex: 1,
    },
    {
      field: "email",
      headerName: "E-mail",
      flex: 1,
    },
    {
      field: "phoneNumber",
      headerName: "Telefoon",
      flex: 1,
    },
    {
      field: "comments",
      headerName: "Opmerkingen",
      flex: 1,
      hide: true,
    },
    {
      field: "view",
      headerName: " ",
      renderCell: ({ row }) => (
        <Button component={Link} to={`${url}/${row.id}`} variant="outlined">
          Bekijken
        </Button>
      ),
    },
  ];

  const createdHandler = () => {
    ref?.current?.refetch();
  };

  return (
    <Fragment><h2>Medewerkers</h2>
      <DataTable
        columns={columns}
        apiUrl={`/api/v1/organisations/${user.organisation.id}/employees`}
        hasRemoved
        tableRef={ref}
      />

      <EmployeeCreateModal
        isOpen={isCreateOpen}
        onClose={() => setIsCreateOpen(false)}
        onCreated={createdHandler}
      />

      <FloatingActionButton
        icon={<AddIcon />}
        onClick={() => setIsCreateOpen(!isCreateOpen)}
      />
    </Fragment>
  );
};

export default Employees;
