import { patchProcedure } from "../../../../api/students/procedures";
import useProcedure from "../../../../hooks/useProcedure";
import SectionTitle from "../../../Typography/SectionTitle";
import Modal from "../../../ui/modals/Modal";
import ProcedureForm from "./ProcedureForm";

const ProcedureEditModal = () => {
  const { procedure, setProcedure, isEditOpen, setEditOpen } = useProcedure();

  const submitHandler = async (values, { setErrors }) => {
    const errors = await patchProcedure(procedure.id, values);

    if (errors) {
      setErrors(errors);
    } else {
      setProcedure({
        ...procedure,
        ...values,
      });
    }
  };

  return (
    <Modal isOpen={isEditOpen} onClose={() => setEditOpen(false)}>
      <SectionTitle>{procedure?.title} bijwerken</SectionTitle>

      <ProcedureForm
        onSubmit={submitHandler}
        procedure={procedure}
        onCancel={() => setEditOpen(false)}
      />
    </Modal>
  );
};

export default ProcedureEditModal;
