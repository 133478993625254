import { createContext, useCallback, useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { toast } from "react-toastify";
import { request } from "../api/requests";
import PageSuspense from "../components/ui/loaders/PageSuspense";
import useFetch from "../hooks/useFetch";
import useMounted from "../hooks/useMounted";
import { deleteUser } from "../api/users";

export const EmployeeContext = createContext();

const EmployeeProvider = ({ children }) => {
  /*** Get the employee id from the route. */
  const {
    params: { id },
  } = useRouteMatch();

  /**
   * Using the useMounted hook to check
   * if component is mounted before setting state.
   */
  const isMounted = useMounted();

  /** The api uri needed for all requests in context. */
  const apiUri = `/api/v1/employees/${id}`;

  /** Fetch the employee from the api. */
  const { response } = useFetch(apiUri);

  /** Initialize the employee state. */
  const [employee, setEmployee] = useState(null);

  /** Run hook on mount or after one or more dependencies changes. */
  useEffect(() => {
    if (isMounted.current) setEmployee(response?.data?.employee);
  }, [isMounted, response]);

  /** Function to update the employee data. */
  const updateEmployee = useCallback(
    async (data, callback) => {
      const [response, error] = await request({
        method: "PATCH",
        url: apiUri,
        data,
      });

      if (isMounted.current && !error) {
        setEmployee((employee) => ({ ...employee, ...data }));

        toast.success("Medewerker bijgewerkt.");
      }

      if (callback instanceof Function) callback(response, error);
    },
    [apiUri, isMounted]
  );

  /** Delete the employee from the server and redirect. */
  const deleteEmployee = async () =>
    await deleteUser(id, "Verwijder gebruiker.", "/medewerkers");

  /** Construct the state to pass to the context. */
  const state = {
    employee,
    updateEmployee,
    deleteEmployee,
  };

  return (
    <EmployeeContext.Provider value={state}>
      <PageSuspense isLoading={!employee}>{children}</PageSuspense>
    </EmployeeContext.Provider>
  );
};

export default EmployeeProvider;
