import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import Card from "../../../ui/cards/Card";

const useStyles = makeStyles({
  card: {
    height: "fit-content",
    marginBottom: "16px",
    "& .MuiCardContent-root": {
      padding: "16px",
    },
  },
});

const ReportDescriptionCard = ({ description }) => {
  const classes = useStyles();

  return (
    <Card styles={clsx(classes.card)}>
      <Typography dangerouslySetInnerHTML={{ __html: description }} />
    </Card>
  );
};

export default ReportDescriptionCard;
