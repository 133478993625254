import { useState } from "react";
import ConfirmationModal from "./ConfirmationModal";
import LoadingButton from "../buttons/LoadingButton";
import { Button } from "@material-ui/core";
import useMounted from "../../../hooks/useMounted";

const DeletionModal = ({ isOpen, onClose, deleteHandler, message }) => {
  const isMounted = useMounted();

  const [isDeleting, setIsDeleting] = useState();

  const onDelete = async () => {
    if (isMounted.current) setIsDeleting(true);

    await deleteHandler();

    if (isMounted.current) setIsDeleting(false);
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={!isDeleting ? onClose : null}
      onConfirm={onDelete}
      message={message}
      confirmButton={
        <LoadingButton disabled={isDeleting}>Verwijderen</LoadingButton>
      }
      cancelButton={<Button disabled={isDeleting}>Annuleren</Button>}
    />
  );
};

export default DeletionModal;
