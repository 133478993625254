import { Timeline } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import ProcedureStepOne from "./ProcedureStepOne";
import ProcedureStepTwo from "./ProcedureStepTwo";
import ProcedureStepThree from "./ProcedureStepThree";
import ProcedureStepFour from "./ProcedureStepFour";
import ProcedureStepFive from "./ProcedureStepFive";
import ProcedureStepSix from "./ProcedureStepSix";
import ProcedureStepVolunteer from "./ProcedureStepVolunteer";
import useProcedure from "../../../../hooks/useProcedure";

const useTimelineStyles = makeStyles(() => ({
  timeline: {
    "& .MuiTimelineItem-alignLeft::before": {
      content: "none",
    },
  },
}));

const ProcedureTimeLine = () => {
  const aboveMd = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const classes = useTimelineStyles();
  const { procedure } = useProcedure();

  return (
    <Timeline
      className={clsx(classes.timeline)}
      align={aboveMd ? "alternate" : "left"}
    >
      {procedure && (
        <>
          <ProcedureStepOne />

          <ProcedureStepVolunteer />

          <ProcedureStepTwo />

          <ProcedureStepThree />

          <ProcedureStepFour />

          <ProcedureStepFive />

          <ProcedureStepSix />
        </>
      )}
    </Timeline>
  );
};

export default ProcedureTimeLine;
