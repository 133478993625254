import Modal from "../../ui/modals/Modal";
import StudentForm from "./StudentForm";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { getUser } from "../../../store/user/selectors";
import { postStudent } from "../../../api/students/index";

const StudentCreateModal = ({ isOpen, onClose, onCreated }) => {
  const history = useHistory();
  const user = useSelector((state) => getUser(state));

  const submitHandler = async (values, { setErrors }) => {
    const [student, errors] = await postStudent(user.organisation.id, values);

    if (errors) {
      setErrors(errors);
    } else {
      if (values.redirectToEntity) {
        history.push(`/cursisten/${student.id}`);
      } else {
        if (typeof onCreated == "function") onCreated();
        onClose();
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <StudentForm onSubmit={submitHandler} onCancel={onClose} />
    </Modal>
  );
};

export default StudentCreateModal;
