import { Grid } from "@material-ui/core";
import SideMenu from "./SideMenu";

const SideMenuLayout = ({ items, children }) => (
  <Grid container spacing={3}>
    <Grid item xs={12} lg={3} xl={2}>
      <SideMenu items={items} />
    </Grid>

    <Grid item xs={12} lg={9} xl={10}>
      {children}
    </Grid>
  </Grid>
);

export default SideMenuLayout;
