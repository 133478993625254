import { Formik, Form } from "formik";
import {
  Button,
  Box,
  Typography,
  Link as MuiLink,
  Grid,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "../../../api/auth";
import { store } from "../../../store";
import * as yup from "yup";
import { Link, useHistory } from "react-router-dom";
import FormField from "../../forms/FormField";
import LoadingSpinner from "../../ui/loaders/LoadingSpinner";
import { toast } from "react-toastify";
import { MAX_LENGTH } from "../../../application/constants";
import {
  emailValidationMessage,
  maxLengthValidationMessage,
  maxLengthTwoValidationMessage,
  requiredValidationMessage,
  stringValidationMessage,
} from "../../../application/yup/validation";

const validationSchema = yup.object().shape({
  email: yup
    .string(stringValidationMessage)
    .max(MAX_LENGTH, maxLengthValidationMessage)
    .email(emailValidationMessage)
    .required(requiredValidationMessage),
  password: yup
    .string(stringValidationMessage)
    .max(MAX_LENGTH, maxLengthValidationMessage)
    .required(requiredValidationMessage),
  twofactor: yup
    .string(stringValidationMessage)
    .max(6, maxLengthTwoValidationMessage)
  // .required(requiredValidationMessage),
});

const initialValues = { email: "", password: "", twofactor: "", formCheck: false, formTwo: false };

const LoginForm = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={async (values, { setErrors }) => {
        const token = store.getState().auth.twofactor;
        if (values.twofactor != "" && values.twofactor.length == 6) {

          const [response, error] = await signIn(values.email, values.password, values.twofactor);
          if (!error) {
            if (values.twofactor == "000000") {
              toast.error("Je hebt nog geen token aangemaakt, klik op 'RESET TWEESTAPSVERIFICATIE' om een token in te stellen");
              dispatch({ type: "SET_TOKEN", payload: response.data.token });
              history.push("/account");
            }
            else {
              dispatch({ type: "SET_TOKEN", payload: response.data.token });
              history.push("/");
            }
              
            

            
          }
          else {
            toast.error("Je gebruikersnaam, wachtwoord, of tweestapsverificatie code is incorrect. We zeggen niet welke van de drie incorrect is om zo ons systeem veilig te houden.");
          }
          setErrors(error.response?.data?.errors ?? {});
        }
        else {
          if (values.email != "" && values.password != "") {
            toast.error("Je gebruikersnaam, wachtwoord, of tweestapsverificatie code is incorrect. We zeggen niet welke van de drie incorrect is om zo ons systeem veilig te houden.");

            // const [responseStage1, error] = await signIn(values.email, values.password, 1, "", "");
            // if (!error) {
            //   dispatch({ type: "TWOFACTOR", payload: responseStage1.data.twofactorId });
            // }
            // else {

            // }


          }
        }

      }}
    >
      {({ values, errors, isSubmitting, handleChange }) => (
        <Form noValidate={true}>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField
                type="email"
                name="email"
                onInput={handleChange}
                label="Uw e-mail adres"
                value={values.email}
                error={errors.email}
                isDisabled={values.formCheck}
                required={true}
                is
              />
            </Grid>

            <Grid item xs={12}>
              <FormField
                type="password"
                name="password"
                onInput={handleChange}
                label="Wachtwoord"
                value={values.password}
                error={errors.password}
                isDisabled={values.formCheck}
                required={true}
              />

              <Typography variant="subtitle2"><br />
                {/* Wachtwoord vergeten?  */}
                <MuiLink component={Link} to="/auth/wachtwoord-vergeten">
                  Uw wachtwoord vergeten?
                </MuiLink>
                {/* voor een nieuw wachtwoord */}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" alignItems="center" gridGap="1rem">
                {/* <Button
                  type="submit"
                  disabled={values.formCheck}
                  variant="contained"
                >
                  Bevestigen
                </Button> */}

                {isSubmitting}
              </Box>

            </Grid>

            <Grid item xs={12}>
              <Box display="flex" alignItems="center" gridGap="1rem">

              </Box>

            </Grid>
            <Grid item xs={12}>
              <FormField
                type="text"
                name="twofactor"
                onInput={handleChange}
                label="Tweestapsverificatie code"
                value={values.twofactor}
                error={errors.twofactor}
                isDisabled={values.formTwo}
                required={true}
                maxLength={6}
              />

            </Grid>


            <Grid item xs={12}>
              <Box display="flex" alignItems="center" gridGap="1rem">
                <Button
                  type="submit"
                  disabled={values.formTwo}
                  variant="contained"
                >
                  Aanmelden
                </Button>

                {isSubmitting && <LoadingSpinner size={25} />}
              </Box>
              {/* <Box display="flex" alignItems="center" gridGap="1rem">
                <Button
                  type="submit"
                  disabled={values.formTwo}
                  variant="contained"
                  color=""
                >
                  Annuleren
                </Button>

                {isSubmitting && <LoadingSpinner size={25} />}
              </Box> */}

            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
