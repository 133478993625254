import { Fragment } from "react";
import { Cell, Tooltip, Pie, PieChart } from "recharts";
import { GetAppointmentName } from "../../../../application/appointmentTypes";
import LegendList from "../../../ui/lists/LegendList";
import LegendListItem from "../../../ui/lists/LegendListItem";

const colors = ["#003f5c", "#bc5090", "#ffa600"];

const AppointmentsChart = ({ appointments }) => {
  return (
    <Fragment>
      <LegendList>
        {appointments.map((appointment, index) => (
          <LegendListItem
            key={`appointment-legend-${index}`}
            color={colors[index]}
          >
            {GetAppointmentName(appointment.key)}
          </LegendListItem>
        ))}
      </LegendList>

      <PieChart width={300} height={300}>
        <Pie
          data={appointments}
          dataKey="count"
          nameKey="status"
          cx="50%"
          cy="50%"
          outerRadius="80%"
          tooltipType=""
        >
          {appointments.map((appointment, index) => (
            <Cell
              key={`appointment-type-${appointment.index}`}
              fill={colors[index]}
            />
          ))}
        </Pie>

        <Tooltip
          label="AA"
          formatter={(value, key) => `${GetAppointmentName(key)}: ${value}`}
        />
      </PieChart>
    </Fragment>
  );
};

export default AppointmentsChart;
