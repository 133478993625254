import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DataGrid from "../../components/datagrid";
import { Fragment, useRef, useState } from "react";
import OrganisationCreateModal from "../../components/pages/organisation/OrganisationCreateModal";
import { Link } from "react-router-dom";
import FloatingActionButton from "../../components/ui/FloatingActionButton";

const columns = [
  { field: "name", headerName: "Organisatie", flex: 1 },
  { field: "address", headerName: "Adres", flex: 1 },
  {
    field: "view",
    headerName: " ",
    renderCell: ({ row }) => (
      <Button
        component={Link}
        to={`/organisaties/${row.id}`}
        variant="outlined"
      >
        Bekijken
      </Button>
    ),
  },
];

const Organisations = () => {
  const ref = useRef({ current: {} });

  const [isCreateOpen, setIsCreateOpen] = useState(false);

  const createdHandler = () => ref.current.refetch();

  return (
    <Fragment>
      <DataGrid
        columns={columns}
        apiUrl="/api/v1/organisations"
        tableRef={ref}
      />

      <OrganisationCreateModal
        isOpen={isCreateOpen}
        onClose={() => setIsCreateOpen(false)}
        onCreated={createdHandler}
      />

      <FloatingActionButton
        onClick={() => setIsCreateOpen(true)}
        aria-label="Organisatie aanmaken"
        icon={<AddIcon />}
      />
    </Fragment>
  );
};

export default Organisations;
