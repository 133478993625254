import { request } from "../../../api/requests";
import { apiErrorsToObject } from "../../../application/helpers";

export const patchAppointment = async (procedureId, appointmentId) => {
  const [, error] = await request({
    method: "PATCH",
    url: `/api/v1/procedures/${procedureId}/appointments/${appointmentId}`,
  });

  if (error) return apiErrorsToObject(error?.response?.data?.errors ?? {});

  return null;
};
