import Modal from "../../../ui/modals/Modal";
import { useFormik } from "formik";
import FormFile from "../../../forms/FormFile";
import SectionTitle from "../../../Typography/SectionTitle";
import { objectToFormData } from "../../../../application/helpers";
import { toast } from "react-toastify";
import { Button, Grid } from "@material-ui/core";
import SpaceBetweenBox from "../../../ui/SpaceBetweenBox";
import useFiles from "../../../../hooks/useFiles";

const FileUploadModal = ({ isOpen, onClose }) => {
  const { uploadFiles } = useFiles();

  const submitHandler = async (values, { setErrors }) => {
    const formData = objectToFormData(values);

    uploadFiles(formData, (_, error) => {
      if (error) {
        setErrors(error?.response?.data?.errors ?? []);
      } else {
        onClose();
        toast.success("Bestanden zijn toegevoegd.");
      }
    });
  };

  const { errors, isSubmitting, handleSubmit, setFieldValue } = useFormik({
    onSubmit: submitHandler,
    initialValues: {
      files: [],
    },
    enableReinitialize: true,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <SectionTitle>Bestanden uploaden</SectionTitle>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormFile
              onInput={(files) => setFieldValue("files", files)}
              error={errors.files}
              extensions=".doc,.docx,.xlsx,.pdf,.png,.jpg"
              amount={6}
              disabled={isSubmitting}
            />
          </Grid>

          <Grid item xs={12}>
            <SpaceBetweenBox>
              <Button onClick={onClose} disabled={isSubmitting}>
                Annuleren
              </Button>

              <Button type="submit" disabled={isSubmitting}>
                Uploaden
              </Button>
            </SpaceBetweenBox>
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
};

export default FileUploadModal;
