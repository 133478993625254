import useNotes from "../../../../hooks/useNotes";
import SectionTitle from "../../../Typography/SectionTitle";
import Modal from "../../../ui/modals/Modal";
import NoteForm from "./NoteForm";

const NoteCreateModal = ({ isOpen, onClose }) => {
  const { createNote } = useNotes();

  const submitHandler = async (values, { setErrors }) => {
    await createNote(values, (_, error) => {
      if (error) setErrors(error?.response?.data?.errors);
      else onClose();
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <SectionTitle>Notitie toevoegen</SectionTitle>

      <NoteForm onSubmit={submitHandler} onCancel={onClose} />
    </Modal>
  );
};

export default NoteCreateModal;
