import { Button, ListItem, Typography } from "@material-ui/core";
import moment from "moment";
import SpaceBetweenBox from "../../../../ui/SpaceBetweenBox";
import { GetAppointmentName } from "../../../../../application/appointmentTypes";

const AppointmentListItem = ({ appointment, onCancel }) => {
  return (
    <ListItem>
      <SpaceBetweenBox>
        <div>
          <Typography variant="subtitle2">
            {GetAppointmentName(appointment.type)}
          </Typography>
          <Typography>
            Datum: {moment(appointment.startAt).format("DD-MM-yyyy HH:mm")}
          </Typography>
          <Typography>Reden: {appointment.reason}</Typography>
          <Typography>Locatie: {appointment.location}</Typography>
        </div>
        <div>
          <Button
            variant="outlined"
            disabled={appointment.cancelled || Date.now() > appointment.startAt}
            onClick={() => onCancel(appointment.id)}
          >
            {appointment.cancelled ? "Geannuleerd" : "Annuleren"}
          </Button>
        </div>
      </SpaceBetweenBox>
    </ListItem>
  );
};

export default AppointmentListItem;
