import { Grid, Typography } from "@material-ui/core";
import { useCallback } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  fetchOrganisationAppointmentsData,
  fetchOrganisationStudentsData,
  fetchOrganisationVolunteerData,
} from "../../../../api/organisations";
import { getUser } from "../../../../store/user/selectors";
import SectionTitle from "../../../Typography/SectionTitle";
import Card from "../../../ui/cards/Card";
import LoadingSpinner from "../../../ui/loaders/LoadingSpinner";
import AppointmentsChart from "./AppointmentsChart";
import StudentsByStatusChart from "./StudentsByStatusChart";

const EmployeeDashboard = () => {
  const user = useSelector((state) => getUser(state));

  const mounted = useRef(true);

  const [data, setData] = useState(null);

  const fetch = useCallback(async () => {
    const organisationId = user?.organisation?.id;

    if (!organisationId) return;

    let appointmentsData = fetchOrganisationAppointmentsData(organisationId);
    let studentsData = fetchOrganisationStudentsData(organisationId);
    let volunteersData = fetchOrganisationVolunteerData(organisationId);

    appointmentsData = await appointmentsData;
    studentsData = await studentsData;
    volunteersData = await volunteersData;

    if (mounted.current)
      setData({ ...appointmentsData, ...studentsData, ...volunteersData });
  }, [user]);

  useEffect(() => {
    mounted.current = true;

    fetch();

    return () => {
      mounted.current = false;
    };
  }, [fetch]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={8}>
        <Card>
          <SectionTitle>Aantal studenten onderverdeeld in status</SectionTitle>

          {data?.studentsByStatus ? (
            <StudentsByStatusChart studentsByStatus={data?.studentsByStatus} />
          ) : (
            <LoadingSpinner />
          )}
        </Card>
      </Grid>
      <Grid item xs={12} md={6} lg={2}>
        <Card>
          <SectionTitle>Aantal taalvragers (cursisten)</SectionTitle>

          {data?.amountOfStudents !== undefined ? (
            <Typography variant="h3">{data?.amountOfStudents}</Typography>
          ) : (
            <LoadingSpinner />
          )}
        </Card>
      </Grid>

      <Grid item xs={12} md={6} lg={3}>
        <Card>
          <SectionTitle>Aantal vrijwilligers</SectionTitle>

          {data?.amountOfVolunteers !== undefined ? (
            <Typography variant="h3">{data?.amountOfVolunteers}</Typography>
          ) : (
            <LoadingSpinner />
          )}
        </Card>
      </Grid>
      <Grid item md={12} lg={4}>
        <Card>
          <SectionTitle>Opkomende afspraken</SectionTitle>

          {data?.appointments ? (
            <AppointmentsChart appointments={data?.appointments} />
          ) : (
            <LoadingSpinner />
          )}
        </Card>
      </Grid>

      <Grid item md={12} lg={3}>
        <Card>
          <SectionTitle>Afgeronde afspraken</SectionTitle>

          {data?.completedAppointments ? (
            <AppointmentsChart appointments={data?.completedAppointments} />
          ) : (
            <LoadingSpinner />
          )}
        </Card>
      </Grid>

      

      
    </Grid>
  );
};

export default EmployeeDashboard;
