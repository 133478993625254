import { IconButton } from "@material-ui/core";
import { useDispatch } from "react-redux";
import Brightness7Icon from "@material-ui/icons/Brightness7";

const DarkModeButton = () => {
  const dispatch = useDispatch();

  return (
    <IconButton onClick={() => dispatch({ type: "TOGGLE_DARKMODE" })}>
      <Brightness7Icon />
    </IconButton>
  );
};

export default DarkModeButton;
